
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import SideBar from "@/components/layout/SideBar.vue";
import InnerFooter from "@/components/layout/InnerFooter.vue";
import TopBar from "@/components/layout/TopBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import BudgetCateogryFiller from "@/components/budget/BudgetCategoryFiller.vue";
import DistributionChart  from "@/components/budget/DistributionChart.vue";
import DistributionSummary  from "@/components/budget/DistributionSummary.vue";
import TransactionSummary from "@/components/budget/TransactionSummary.vue";
import GeneralSummary from "@/components/budget/GeneralSummary.vue";
import Shepherd from 'shepherd.js';
import UserBudget from "@/models/Budget/UserBudget";
import EnrollmentCategoryModal from "@/components/budget/EnrollmentCategoryModal.vue";
import NewBudgetModal from "@/components/budget/NewBudgetModal.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import TransactionTable from "@/components/dashboard/TransactionTable.vue";
import DesktopSideColumn from "@/components/budget/DesktopSideColumn.vue"
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import Swal from 'sweetalert2';

@Component({
    components: {
        LoadingButton,
        SideBar,
        TopBar,
        InnerFooter,
        BudgetCateogryFiller,
        DistributionChart,
        DistributionSummary,
        TransactionSummary,
        GeneralSummary,
        EnrollmentCategoryModal,
        NewBudgetModal,
        TopBarAssets,
        NewTransactionModal,
        TransactionTable,
        DesktopSideColumn,
        BudgetMonthSelection,
        ConfirmTooltip
    }
})
export default class Budget extends Vue {
    tourStarted = false;
    tour!: Shepherd.Tour;
    unsubscribeToActionEvent!: () => void;

    @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
    @Getter("Budget/getBudgetCategoryCount") budgetCategoryCount!: number;
    @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
    @Getter("Budget/getSkipTutorial") skipTutorial!: boolean;
    @Getter("Budget/getBudgetTypeTab") activeTab! : number;
    @Getter("Budget/getOlderBudget") olderBudget!: UserBudget;
    @Getter("Budget/getNewerBudget") newerBudget! : UserBudget;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("IdentityManagement/getUser") user!: UserModel;
    @Getter("Budget/getLastVisitedSubTab") subTab!: number;
    @Getter("IdentityManagement/getCanMakeTransactions") canMakeTransactions!: boolean;
    @Getter("IdentityManagement/getCanAddCategories") canAddCategory!: boolean;
    loadInitialTour = false;
    desktopSideTab = 0

    @Watch("budgetIsLoading")
    budgetLoadingChange(value: any) {
      if (this.skipTutorial !== undefined && this.skipTutorial !== null) {
        if (!this.skipTutorial && !this.loadInitialTour) {
          // this.activateTutorial();
        }
      }
    }

    get transactionTypeText() {
      if (this.activeTab == 0) return 'gasto'
      return 'ingreso'
    }

    openModal() {
        // if (this.activeUserBudgetIsDisabled) {
          if (false) {
            Swal.fire({
                titleText: 'Aviso',
                text: 'No puedes agregar más categorias a un presupuesto archivado.',
                icon: 'warning',
                confirmButtonText: 'De acuerdo'
            })
            return;
        }
        this.$store.dispatch("Budget/showNewCategoryModal");
    }

    openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();

      const activeTab = this.activeTab == 1 ? 'profile-tab' : 'home-tab';
      const el = document.getElementById(activeTab);
      el?.click();
    }

    created() {
      this.$store.dispatch("Budget/getBudgetConfig", this.user.id);
      this.$store.dispatch("Budget/getSkipTutorial");
      this.$store.dispatch("Asset/assetCatalogLoaded");
      this.$store.dispatch("Budget/loadUserBudgets");
    }
    //
    mounted() {
        let script = document.createElement('script')
        let url = process.env.BASE_URL + "js/ui/budget.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);
        //
        //Listen for ActionEvents
        this.unsubscribeToActionEvent = this.$store.subscribeAction(
            (action, state) => {
                if (action.type == "Budget/activateTutorial")
                    this.activateTutorial();
            }
        );
    }

    deleteActiveBudget() {
      this.$store.dispatch("Budget/deleteActiveBudget", this.user.id);
    }

    changeDesktopSideTab(tab: number) {
      this.desktopSideTab = tab;
    }

    callModal() {      
      this.$store.dispatch("Budget/showNewBudgetModal");
    }

    closeTour() {
      this.tour.complete();
      this.$store.dispatch('Budget/setSkipTutorial');
    }

    beforeDestroy() {
      this.unsubscribeToActionEvent();
    } 

    setupTourSteps() {
        //On tour completed
        (Shepherd as any).once("complete", () => {
            this.onTourClosed();
        });
        (Shepherd as any).once("cancel", () => {
            this.onTourClosed();
        });
        //
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                // canClickTarget: false,
                modalOverlayOpeningRadius: 15,
                cancelIcon: {
                    enabled: true
                },
                classes: 'popup-blue'
                // classes: 'popup-transparent',
            }
        });
        //
        const that = this;
        this.tour.addStep({
            id: 'TourIntro',
            title: "Bienvenido a Caudall",
            text: 'Te damos la bienvenida a Caudall, permíteme mostrarte lo que puedes hacer dentro de la plataforma',
            classes: 'step0',
            buttons: [
                {
                    text: 'Omitir',
                    secondary: true,
                    action() {
                      return that.closeTour();
                    }
                },
                {
                    text: 'Comenzar',
                    action: () => {
                        this.tour.next();
                    }   
                }
            ]
        });
        this.tour.addStep({
            id: 'ingresos',
            title: "Registra tus ingresos",
            text: 'Aquí puedes registrar tus ingresos y ganancias.',
            canClickTarget: false,
            scrollTo: true,
            attachTo: {
                element: '.p-category-table-container:first-child',
                on: 'bottom'
            },
            classes: 'step1',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.tour.next();
                    }   
                }
            ]
        });
        //
        this.tour.addStep({
            id: 'tooltips',
            title: "Iconos de ayuda",
            text: 'Si tienes alguna duda respecto a un término, utiliza los tooltips de ayuda para saber su significado.',
            canClickTarget: true,
            scrollTo: true,
            modalOverlayOpeningPadding: 4,
            modalOverlayOpeningRadius: 10,
            attachTo: {
                element: '.help-circle',
                on: 'bottom'
            },
            classes: 'step2',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.tour.next();
                    }   
                }
            ]
        });
        //
        var step3 = this.tour.addStep({
            id: 'newcategory',
            scrollTo: true,
            title: "Agregar categorías",
            text: 'Haciendo click aquí puedes agregar nuevas categorías a tu presupuesto',
            canClickTarget: false,
            attachTo: {
                element: 'button.new-category',
                on: 'top'
            },
            classes: 'step3',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.$store.dispatch("Budget/showNewCategoryModal")
                        step3.hide()
                        //
                        setTimeout(() => {
                            Vue.nextTick(() => {
                                this.tour.next();
                            })
                        }, 800)
                    }
                }
            ]
        });
            //
        this.tour.addStep({
            id: 'cateogry-list',
            scrollTo: true,
            title: "Agregar categorías",
            text: 'Éste es el listado de categorías disponibles',
            canClickTarget: false,
            attachTo: {
                element: '.mymodal',
                on: 'top'
            },
            classes: 'step4',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: () => {
                        this.$store.dispatch("Budget/hideNewCategoryModal")
                        this.tour.back()
                    }
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.$store.dispatch("Budget/hideNewCategoryModal")
                        //
                        this.tour.next();

                    }
                }
            ]
        });
            //
        let step4 = this.tour.addStep({
            id: 'transacition-summary',
            scrollTo: true,
            title: "Historial de transacciones",
            canClickTarget: false,
            text: 'Aquí puedes visualizar las últimas transacciones que reflejan la ejecución de tu presupuesto.',
            attachTo: {
                element: '.transaction-summary',
                on: 'left'
            },
            classes: 'step5',
            modalOverlayOpeningPadding: 10,
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: () => {
                        this.$store.dispatch("Budget/showNewCategoryModal")
                        step4.hide();
                        //
                        setTimeout(() => {
                            this.tour.back();
                        }, 800)
                    }
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        //
                        this.tour.next();

                    }
                }
            ]
        });
            //
        this.tour.addStep({
            id: 'chart',
            scrollTo: true,
            title: "Visualiza la distribución de tu presupuesto",
            canClickTarget: true,
            text: 'Aquí tienes un gráfico que te indica cómo está distribuido tu presupuesto.',
            attachTo: {
                element: '.chart-container',
                on: 'left'
            },
            classes: 'step6',
            when: {
                show:() => {
                    this.$store.dispatch("Budget/setDummyDataToChartSummary");
                }
            },
            modalOverlayOpeningPadding: 10,
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Comenzar',
                    action: () => {
                        //
                        this.tour.complete();

                    }
                }
            ]
        });
    }

    setupTourStepsMobile() {
        //On tour completed
        (Shepherd as any).once("complete", () => {
            this.onTourClosed();
        });
        (Shepherd as any).once("cancel", () => {
            this.onTourClosed();
        });
        //
        this.tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
                // canClickTarget: false,
                modalOverlayOpeningRadius: 15,
                cancelIcon: {
                    enabled: true
                },
                classes: 'popup-blue'
                // classes: 'popup-transparent',
            }
        });
        //
        const that = this;
        this.tour.addStep({
            id: 'TourIntro',
            title: "Bienvenido a Caudall",
            text: 'Te damos la bienvenida a Caudall, permíteme mostrarte lo que puedes hacer dentro de la plataforma',
            classes: 'step0',
            buttons: [
                {
                    text: 'Omitir',
                    secondary: true,
                    action() {
                      return that.closeTour();
                    } 
                },
                {
                    text: 'Comenzar',
                    action: () => {
                        //If we are in mobile change to Budget Tab
                        this.activeTab = 1;
                        this.tour.next();
                    }   
                }
            ]
        });
        this.tour.addStep({
            id: 'ingresos',
            title: "Registra tus ingresos",
            text: 'Aquí puedes registrar tus ingresos y ganancias.',
            canClickTarget: false,
            scrollTo: true,
            attachTo: {
                element: '.p-category-table-container:first-child',
                on: 'top'
            },
            classes: 'step1',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.tour.next();
                    }   
                }
            ]
        });
        //
        var step3 = this.tour.addStep({
            id: 'newcategory',
            scrollTo: true,
            title: "Agregar categorías",
            text: 'Haciendo click aquí puedes agregar nuevas categorías a tu presupuesto',
            canClickTarget: false,
            attachTo: {
                element: 'button.new-category',
                on: 'top'
            },
            classes: 'step3',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: this.tour.back
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.$store.dispatch("Budget/showNewCategoryModal")
                        step3.hide()
                        //
                        setTimeout(() => {
                            Vue.nextTick(() => {
                                this.tour.next();
                            })
                        }, 800)
                    }
                }
            ]
        });
            //
        this.tour.addStep({
            id: 'cateogry-list',
            scrollTo: true,
            title: "Agregar categorías",
            text: 'Éste es el listado de categorías disponibles',
            canClickTarget: false,
            attachTo: {
                element: '.mymodal',
                on: 'top'
            },
            classes: 'step4',
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: () => {
                        this.$store.dispatch("Budget/hideNewCategoryModal")
                        this.tour.back()
                    }
                },
                {
                    text: 'Siguiente',
                    action: () => {
                        this.$store.dispatch("Budget/hideNewCategoryModal")
                        //
                        this.tour.next();

                    }
                }
            ]
        });
        //
       let chartStep = this.tour.addStep({
            id: 'chart',
            scrollTo: true,
            title: "Visualiza la distribución de tu presupuesto",
            canClickTarget: true,
            text: 'Aquí tienes un gráfico que te indica cómo está distribuido tu presupuesto.',
            attachTo: {
                element: '.chart-container',
                on: 'top'
            },
            classes: 'step6',
            when: {
                show:() => {
                    this.activeTab = 0;
                    this.$store.dispatch("Budget/setDummyDataToChartSummary");
                }
            },
            modalOverlayOpeningPadding: 10,
            buttons: [
                {
                    text: 'Anterior',
                    secondary: true,
                    action: () => {
                        chartStep.hide();
                        this.activeTab = 1;
                        this.$store.dispatch("Budget/showNewCategoryModal")
                        setTimeout(() => {
                            Vue.nextTick(() => {
                                this.tour.back();
                            })
                        }, 800)
                     } 
                },
                {
                    text: 'Comenzar',
                    action: () => {
                        //
                        this.tour.complete();

                    }
                }
            ]
        });
    }
    
    
    onTourClosed() {
        this.checkIfHasEnrollmentCategories();
        this.$store.dispatch("Budget/removeDummyDataToChartSummary");
    }
    checkIfHasEnrollmentCategories() {
        this.$store.dispatch("Budget/showEnrollmentCategoriesModal");
    }

    activateTutorial() {
        if (this.isMobile)
            this.setupTourStepsMobile();
        else
            this.setupTourSteps();
        this.tourStarted = false;
        this.loadInitialTour = true;
        Vue.nextTick(() => {
            this.tour.start();
        })
    }

    changeActiveBudget(userBudget: UserBudget) {
      if (userBudget) {
        this.$store.dispatch("Budget/budgetIsLoading");
        this.$store.dispatch("Budget/changeActiveBudget", userBudget);
      }
    }

    changeActiveTab(tabNumber: number) {
      this.$store.dispatch("Budget/setBudgetTypeTab", tabNumber);
      this.$root.$emit('storeLastVisitedTab', tabNumber);
    }
}
