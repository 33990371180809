
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  BudgetCategory,
  BudgetSubCategory,
  CategoryType,
} from "@/models/Budget/BudgetCategory";
import Chart from "chart.js";
import { Currency } from '@/models/Common/Common'
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import EmptyStateDashboard from "../helpers/emptyStateDashboard.vue";
import Multiselect from "vue-multiselect";
import { CurrencyCode } from "@/components/assetsLiabilities/mixins";
import UserBudget from "@/models/Budget/UserBudget";

enum TransactionTableType {
  Dashboard,
  Expense,
  Income    
}

@Component({
  components: {
    EmptyStateDashboard,
    Multiselect
  },
})
export default class TransactionTable extends Mixins(CurrencyCode) {
  budgetService = new BudgetService();
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Dashboard/getDashboardTransactions") dashboardTransactions!: BudgetTransaction[];
  @Getter("Budget/getBudgetCategories") activeBudgetCategories!: BudgetCategory[];
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/getBudgetTransactions") budgetTransactions!: BudgetTransaction[];
  @Getter("Budget/getActiveBudget") activeuserBudget!: UserBudget;
  @Prop() category!: BudgetCategory;
  @Prop({ default: '' }) tableType!: string;
  @Getter("Dashboard/isDashboardLoaded") isLoaded!: boolean;
  defaultTableType = TransactionTableType.Dashboard;
  transactionTableType = TransactionTableType
  // selectedTableType = this.defaultTableType;
  
  money = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false
  }
  transactionAmount = 0

  get budgetTransactionTable() {
    return this.selectedTableType !== this.transactionTableType.Dashboard
  }
  
  // Filter properties
  minimumAmount = 0;
  maximumAmount = 0;
  fromDate = '';
  toDate = '';
  selectedCategory = {} as BudgetCategory;
  activeFilter = false;
  filteredTransactions = [] as BudgetTransaction[];
  filterText = ""
  isLoading = false;
  initialLoad = false

  get toggleLoading() {
    if (this.selectedTableType == TransactionTableType.Dashboard) {
      return this.isLoading || !this.isLoaded;
    }
    return false
  }
  
  get selectedTableType() {
    if (this.tableType == 'E') {
      return TransactionTableType.Expense
    } else if (this.tableType == 'I') {
      return TransactionTableType.Income
    } else {
      return TransactionTableType.Dashboard
    }
  }

  currencyFormatByView(currency: any) {
    return currency.code.toUpperCase();
  }

  get filteredBudgetTransaction() {
    let filter = this.selectedTableType == TransactionTableType.Expense ? 'G' : 'I';
    return this.budgetTransactions.filter(t => t.type.toLowerCase() == filter.toLowerCase() || t.type.toLowerCase() == 'p');
  }

  @Watch("activeuserBudget")
  LoadActiveBudgetTransactions(budget: UserBudget) {
    if (budget && budget.date) {
      this.$store.dispatch("Budget/getBudgetTransactions", {userId: this.user.id, budgetDate: budget.date})
    }
  } 

  get transactions() {
    if (this.selectedTableType == TransactionTableType.Dashboard) {
      return this.dashboardTransactions
    } 
    return this.filteredBudgetTransaction
  }

  @Watch("transactions")
  updateFilteredTransactionsAfterDelete(transactions: BudgetTransaction[]) {
    if (!this.initialLoad) {
      this.initialLoad = true;
    }

    if (this.activeFilter) {
      this.filteredTransactions = this.applyTransactionFilter();
    }
  }

  get subcategoryColorMapping() {
    if (this.activeBudgetCategories) {
      return this.activeBudgetCategories.map(cat => {
        return {
          color: cat.color || cat.categoryColor,
          subCategoryIds: cat.subcategories?.map(subCat => subCat.uuid),
          category: cat.alias || cat.category,
          categoryId: cat.uuid
        }
      })
    }
    return []
  }

  applyFilter() {
    if (!this.showAmountError) {
      this.activeFilter = true;
      this.filteredTransactions = this.applyTransactionFilter();
      ($('#filterDropdown') as any).removeClass('show');
    } 
  }

  get getBudgetLastDate() {
    return new Date();
  }

  closeFilter() {
    this.clearFilters();
    ($('#filterDropdown') as any).removeClass('show');
  }

  created() {
    $('.dropdown-menu').on('click', function(e) {
      e.stopPropagation();
    });

  }

  get transactionsToDisplay(): BudgetTransaction[] {
    if (this.activeFilter) {
      return this.filteredTransactions;
    }
    return this.filteredTransactionsByCurrency;
  }

  applyTransactionFilter() {
    let transactionsToFilter = this.filteredTransactionsByCurrency.map(t => {return {...t}})
    let filterText = '';
    let todayDate = new Date().toISOString().split('T')[0];
    let tzoffset = (new Date()).getTimezoneOffset() * 60000;

    if (this.fromDate) {
      let fromDateSplit = this.fromDate.split('-') as any
      transactionsToFilter = transactionsToFilter.filter(t => {
        return new Date(t.date).getTime() + tzoffset >= new Date(fromDateSplit[0], fromDateSplit[1] - 1, fromDateSplit[2]).getTime()
      })
      filterText = `Desde: ${this.fromDate}`
    }

    if (this.toDate) {
      if (this.toDate != todayDate) {
        let toDateSplit = this.toDate.split('-') as any
        transactionsToFilter = transactionsToFilter.filter(t => {
          return new Date(t.date).getTime() + tzoffset <= new Date(toDateSplit[0], toDateSplit[1] - 1, toDateSplit[2], 23, 59, 59).getTime()
        })
      }
      filterText = filterText == '' ? `Hasta: ${this.toDate}` : filterText + `;  Hasta: ${this.toDate}`
    }

    if (this.minimumAmount > 0) {
      transactionsToFilter = transactionsToFilter.filter(t => t.amt >= this.minimumAmount)
      filterText = filterText == '' ? `Mínimo: ${this.minimumAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}` : filterText + `;  Mínimo: ${this.minimumAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}`
    }

    if (this.maximumAmount > 0) {
      transactionsToFilter = transactionsToFilter.filter(t => t.amt <= this.maximumAmount)
      filterText = filterText == '' ? `Máximo: ${this.maximumAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}` : filterText + `;  Máximo: ${this.maximumAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}`
    }

    if (this.selectedCategory && this.selectedCategory.subcategories) {
      const subCategoryIds = this.selectedCategory.subcategories.map(subCat => subCat.uuid);

      if (subCategoryIds.length) {
        transactionsToFilter = transactionsToFilter.filter(t => subCategoryIds.includes((t.sub_category as any).uuid));
        filterText = filterText == '' ? `Categoría: ${this.selectedCategory.alias}` : filterText + `; Categoría: ${this.selectedCategory.alias}`
      }
    }
    this.filterText = filterText;
    return transactionsToFilter

  }

  clearFilters() {
    this.minimumAmount = 0;
    this.maximumAmount = 0;
    this.fromDate = '';
    this.toDate = '';
    this.selectedCategory = {} as BudgetCategory;
    this.activeFilter = false;
  }

  get showAmountError() {
    if (this.maximumAmount > 0 && this.minimumAmount > 0) {
      return this.minimumAmount > this.maximumAmount;
    }
    return false;
  }

  get disableToDateFilter() {
    return this.fromDate == null;
  }

  toBudget() {
    this.$router.push('/budget')
  }

  @Watch("minimumAmount")
  handleNegativeInMinAmount(value: any) {
    if (value < 0) {
      this.minimumAmount = Math.abs(value);
      const el = document.getElementsByClassName('v-money-min')[0] as HTMLInputElement;
      el.value = this.minimumAmount.toString();
    }
  }

  @Watch("maximumAmount")
  handleNegativeInMaxAmount(value: any) {
    if (value < 0) {
      this.maximumAmount = Math.abs(value);
      const el = document.getElementsByClassName('v-money-max')[0] as HTMLInputElement;
      el.value = this.maximumAmount.toString();
    }
  }

  // TODO: Move to store
  deleteTransaction(transaction: BudgetTransaction) {
    this.isLoading = true;
    console.log(this.$route);
    this.isLoading = false;

    this.$store.dispatch("Budget/removeTransaction", transaction.uuid)
        .then(() => {
          if (this.$route.name && this.$route.name.toLowerCase() == 'dashboard') {
            this.$store.dispatch("Dashboard/setGeneralBalance")
            this.$store.dispatch("Dashboard/setPatrimony")
          }
        })
        .catch()
        .finally(() => {
          this.isLoading = false;
        })
  }

  get yAxisCurrencyLabel() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.short_name
    }
    return ''
  }

  getCategoryPropertyFromSubcategory(subcategoryId: any, properyToMatch: string, defaultValue: string) {
    if (!subcategoryId) return defaultValue;
    const match = this.subcategoryColorMapping.filter(s =>  s.subCategoryIds?.includes(subcategoryId.uuid));

    if (match.length) {
      return (match[0] as any)[properyToMatch];
    }

    return defaultValue
  }

  dayDifference(isoDate: string) {
    const unformattedDate = new Date(isoDate);
    const transactionDate = new Date(unformattedDate.getTime() + (unformattedDate.getTimezoneOffset() * 60000));
    const today = new Date();
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const formattedToday = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
    const formattedTransactionDate = transactionDate.toISOString().split('T')[0];

    if (formattedToday == formattedTransactionDate) {
      return 'Hoy'
    }
    const diffTime = Math.abs((transactionDate.getTime() + tzoffset) - today.getTime());
    return 'Hace ' + (Math.floor(diffTime / (1000 * 60 * 60 * 24))) + ' día(s)'
  }

  formatAmount(amount: number) {
    if (typeof amount == "string") amount = parseFloat(amount)
    return amount.toLocaleString('en-US', {minimumFractionDigits: 2});
  }

  get filteredTransactionsByCurrency() {
    // if (this.defaultCurrency && this.defaultCurrency.code && this.transactions) {
    //   return this.transactions.filter(t => t.moneda.toLowerCase() == this.defaultCurrency.code.toLowerCase())
    // }
    return this.transactions;
  }

  openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();
    }
}
