
import { Component, Vue } from "vue-property-decorator";
import EnrollmentService from "@/services/EnrollmentService";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import { Plan as PlanModel } from "@/models/Plans"
import { Auth } from "aws-amplify";


@Component({
    components: {
        LoadingButton
    }
})
export default class SideBar extends Vue {
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("IdentityManagement/getHasAccessToDashboard") hasAccessToDashboard!: boolean;
    @Getter("IdentityManagement/getHasAccessToBudget") hasAccessToBudget!: boolean;
    @Getter("IdentityManagement/getCanMakeTransactions") canMakeTransactions!: boolean;
    @Getter("Asset/getPlans") plans!: PlanModel[];
    @Getter("IdentityManagement/getUserPlan") userPlan!: string;
    //
    get routeName() {
      return this.$route.name?.toLowerCase();
    }

    get freePlan() {
      if (this.plans) {
        let freePlan = this.plans.find(p => p.name.toLowerCase() == 'gratis')
        if (freePlan) return freePlan.uuid
      }
      return ''
    }

    get isFreePlan() {
      return this.userPlan == this.freePlan
    }

    get targetOfMobile() {
      return this.isMobile ? "_self" : "_blank";
    }

    openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();
    }

    mounted() {
        let script = document.createElement('script')
        let url = process.env.BASE_URL + "js/ui/sidebar.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);
        this.$store.dispatch("Asset/getAllPlans");
    }

    async logOut() {
      this.$store.dispatch("IdentityManagement/logOutUser")
      this.$store.dispatch('Asset/clearData');
      this.$store.dispatch('Budget/clearData');
      this.$store.dispatch('Dashboard/clearData');
      await Auth.signOut();

      Vue.nextTick(() => {
        this.$router.push("/login");
      })
  }

    showProfileModal() {
      if (this.isMobile)
        $('#sidebar').removeClass('active');
      this.$store.dispatch("Budget/showProfileModal");
    }

    showTutorial() {
      if (this.isMobile)
        $('#sidebar').removeClass('active');
      this.$store.dispatch("Budget/activateTutorial");
    }

    showAssetsLiabilities() {
      this.$router.push('/assetsLiabilities')
    }

    showPlans() {
      this.$router.push('/plans')
    }

    showAccountSummary() {
      this.$router.push('/account')
    }

    showDashboard() {
      this.$router.push('/dashboard');
    }

    showHelpDesk() {
      this.$router.push({ name: 'Help-Desk' });
    }

    showBudget() {
      this.$router.push('/');
    }

    isActiveRoute(route: string) {
      return window.location.href.toLowerCase().includes(route.toLowerCase()) 
    }
}
