
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from 'vuex-class';
import UserModel from "@/models/IdentityManagement/UserModel";
import CategoryEntry from "@/components/budget/CategoryEntry.vue";
import BudgetService from "@/services/BudgetService";
import {BudgetCategory, BudgetSubCategory, CategoryType} from "@/models/Budget/BudgetCategory";
import NewCategoryPopup from "@/components/budget/NewCategoryPopup.vue";
import { generateGUID } from "@/utils/HelperMethods";
import EnrollmentCategoryModalTrigger from "@/components/budget/EnrollmentCategoryModalTrigger.vue";
import UserBudget from "@/models/Budget/UserBudget";
import NewBudgetModal from "@/components/budget/NewBudgetModal.vue";
import MorphinIconButton from "@/components/buttons/MorphinIconButton.vue";
import draggable from 'vuedraggable';
import { VueHammer } from 'vue2-hammer';
import BudgetCategoryEmptyState from "@/components/budget/BudgetCategoryEmptyState.vue"
Vue.use(VueHammer);

@Component({
    components: {
        CategoryEntry,
        NewCategoryPopup,
        EnrollmentCategoryModalTrigger,
        NewBudgetModal,
        draggable,
        MorphinIconButton,
        BudgetCategoryEmptyState
    }
})
export default class BudgetCateogryFiller extends Vue {
    budgetService: BudgetService = new BudgetService();
    @Getter("IdentityManagement/getUser") user!: UserModel;
    @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
    @Getter("Budget/getBudgetTotalIncomePresupuestado") totalIncome!: number;
    @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
    @Getter("Budget/getInvalidTransactionState") disableAddTransaction!: boolean;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("Budget/getBudgetTypeTab") activeTab! : number;
    @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
    budgetLoaded: boolean = false;
    categorySearchTerm = "";
    editIsActive = false;
    isDragging = false;
    
    get disableDraggable() {
        return !this.editIsActive;
    }

    @Watch("budgetIsLoading")
    budgetLoadingChange(value: boolean) {
      if (!value) {
        setTimeout(() => this.budgetLoaded = true, 500);
      }
    }

    openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();
    }

    get categoryTypeFilter() {
      if (this.activeTab == 0) {
        return 'G'
      }
      return 'I'
    }

    get categoryName() {
      return this.categorySearchTerm?.toLowerCase() || "";
    }

    get displayableCategories() {
        let categoryName = this.categorySearchTerm?.toLowerCase() || "";
        let filteredCategory = this.filteredCategoriesByType.filter(cat => cat.alias.toLowerCase().startsWith(categoryName) && (cat.type as any).toUpperCase() == this.categoryTypeFilter);


        if (filteredCategory.length) return filteredCategory;

        let filteredCategoryBySubCategory = this.filteredCategoriesByType.filter(cat => cat.subcategories?.some((s: BudgetSubCategory) => s.alias?.toLowerCase().startsWith(categoryName) || s.sub_category.toLowerCase().startsWith(categoryName)))

        return filteredCategoryBySubCategory.map(cat => {
          return {
            ...cat,
            subcategorias: cat.subcategories?.filter((s: BudgetSubCategory) => s.alias?.toLowerCase().startsWith(categoryName) || s.sub_category.toLowerCase().startsWith(categoryName))
          }
        })
    }
    set displayableCategories(value: BudgetCategory[]) {
        this.$store.dispatch("Budget/changeBudgetCategoriesOrder", value)
        let userId = this.user.id;
        let budgetDate = this.activeBudget.date;
        this
            .budgetService
            .ChangeBudgetCategoryOrder(userId, budgetDate, value)
            .then((res) => {
                if (res.data.statusCode == 500) {
                    this.$toast.open({type: "error", message:"Lo lamento, ocurrió un error guardando los cambios en el presupuesto.", position: "top-right"})
                }
            })
            .catch((err) => {
                console.log(err);
                this.$toast.open({type: "error", message:"Lo lamento, ocurrió un error guardando los cambios en el presupuesto.", position: "top-right"})
            })
    }

    get filteredCategoriesByType() {
      return this.orderedCategories.filter(c => (c.type as any).toUpperCase() == this.categoryTypeFilter)
    }

    get orderedCategories() {
        //First order by Income
        let copy = [...this.budgetCategories]
        let result = copy
            .sort((a, b) => {
                if (a.type == b.type)
                    return 0;
                
                if (typeof a.type == 'object' ? a.type.code.toUpperCase() == CategoryType.Income : a.type?.toUpperCase() == CategoryType.Income)
                    return -1
                else 
                    return 1;
        });

        return result;
    }

    // get totalIncome() {
    //     if (this.budgetCategories.length == 0)
    //         return 0;
    //     var total = this.budgetCategories
    //         .filter(c => c.type == CategoryType.Income)
    //         .map(c => c.subcategorias)
    //         .flat()
    //         .map((a,b) => a.montoPresupuestado)
    //         .reduce((a, b) => a + b);

    //     return total;
    // }

    created(){
        this.$store.dispatch("Budget/budgetIsLoading");
        this.budgetService.GetUserBudgets().then(() => {});
        // this.budgetService.GetConfigurations(this.user.id)        
    }

    onNewCategoryAdded(category: BudgetCategory) {
        this.$store.dispatch("Budget/addNewCategory", category);
        Vue.nextTick(() => {
            //if this category is expense category scroll to last category
            //If not scroll to last income category
            let categoryClass = category.type == CategoryType.Expense 
                                    ? ".expense"
                                    : ".income";
            let nodeList =  document.querySelectorAll(".main-category-filler .categories-container > .p-category-table-container"+categoryClass);
            //Scroll to last category
            if (nodeList.length > 0)
                nodeList[nodeList.length - 1].scrollIntoView(); //Scroll to last
        })
    }

    onCategoryRemoved(category: BudgetCategory) {
        this.budgetLoaded = false;
        this.$store.dispatch("Budget/removeCategory", category.uuid)
        .then(() => this.budgetLoaded = true)
    }

    startAddTransactionAnimation(event: Event) {
        this.$store.dispatch("Budget/startAddTransactionAnimation")
    }

    stopAddTransactionAnimation() {
        this.$store.dispatch("Budget/stopAddTransactionAnimation")
    }

    onEditClicked() {
        this.editIsActive = !this.editIsActive;
    }

    onDragStart() {
        this.isDragging = true;
    }

    onDragStop() {
        this.isDragging = false;
    }

    onLongClickCategory() {
        //if is not mobile, ignore event
        if (!this.isMobile)
            return;
        
        //if isDragging ignore
        if (this.isDragging)
            return;

        this.editIsActive = !this.editIsActive;
    }
    cancelEditing() {
        this.editIsActive = false;
    }

}
