
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  BudgetCategory,
  BudgetSubCategory,
  CategoryType,
} from "@/models/Budget/BudgetCategory";
import Chart from "chart.js";
import { Currency } from '@/models/Common/Common'
@Component({
  components: {},
})
export default class DistributionChart extends Vue {
  @Getter("Budget/getBudgetTotalIncomePresupuestado") totalIncome!: number;
  @Getter("Budget/getBudgetTotalExpenseEjecutado") totalExpense!: number;
  @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Budget/getChangeId") changeId!: string; 
  @Getter("Budget/getShowDummyDataInChart") showDummyData!: boolean;
  @Getter("Layout/isMobileView") isMobile!: boolean;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Asset/getDefaultCurrencyCodeForDisplay") defaultCurrencyCode!: string;
  @Getter("Budget/getBudgetTypeTab") activeTab! : number;
  @Getter("Budget/getCategoryCatalog") categories!: BudgetCategory[];
  
  doughnutChart: any = null;
  chartTypes = ChartType;
  // selectedChartType: ChartType = ChartType.Gastos;
  showInsufficientIncome = false;
  chartTypesSelectOptions = [{ 
                        title: "Presupuestado", 
                        value: ChartType.Presupuestado 
                      }, { 
                        title: "Ejecutado", 
                        value: ChartType.Ejecutado 
                      }]
  defaultChartType = ChartType.Gastos

  get getDefaultCurrency() {
      if (this.defaultCurrency && this.defaultCurrency.code) {
        return this.defaultCurrency.code.toUpperCase();
      }

      return '';
    }

  get getExpenseCategoriesFromCatalog() {
    return this.categories.filter(c => c.type == CategoryType.Expense)
  }

  get getIncomeCategoriesFromCatalog() {
    return this.categories.filter(c => c.type == CategoryType.Income)
  }

  get selectedChartType() {
    if (this.activeTab == 0) {
      return ChartType.Gastos;
    } 
    return ChartType.Ingresos
  }

  get ExpenseCategoriesExcluded() {
    let s = 0;
    let activeCategories = this.getExpenseCategories.map(c => c.category);
    let excludedCategories = this.getExpenseCategoriesFromCatalog.filter(c => activeCategories.indexOf(c.uuid) == -1);
    return excludedCategories.map((c: any) => {
      return {
          category: c.name,
          executed: s.toLocaleString('en-US', {minimumFractionDigits: 2}),
          planned: s.toLocaleString('en-US', {minimumFractionDigits: 2}),
          color: c.color,
          excluded: true
        }
    })
  }

  get chartDetailedSummary() {
    let executedList = [] as number[];
    let plannedList = [] as number[];

    if (this.selectedChartType == ChartType.Gastos) {
      executedList = this.getExpenseCategoriesTotalEjecutado();
      plannedList = this.getExpenseCategoriesTotalPresupuestado()
      let activeCategories = this.getExpenseCategories.map((c, i:number) => {
        let executedNum = executedList[i];
        let plannedNum = plannedList[i]

        return {
          category: c.alias || c.category,
          executed: executedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
          planned: plannedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
          color: c.categoryColor,
          excluded: false
        }
      })
      return activeCategories.concat(this.ExpenseCategoriesExcluded);
    } else {
      executedList = this.getIncomeCategoriesTotalEjecutado();
      plannedList = this.getIncomeCategoriesTotalPresupuestado();
      return this.getIncomeCategories.map((c, i:number) => {
        let executedNum = executedList[i];
        let plannedNum = plannedList[i]

        return {
          category: c.alias || c.category,
          executed: executedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
          planned: plannedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
          color: c.color
        }
      })
    }
  }


  get chartTotalFormatted() {
    return 0
      // let value = this.selectedChartType == ChartType.Presupuestado
      //       ? this.totalIncome
      //       : this.totalExpense;
      // if (this.showDummyData) {
      //   value = 7500;
      // }
      // return value.toLocaleString("en-US", {
      //           minimumFractionDigits: 2,
      //           maximumFractionDigits: 2,
      //       });
  }

  get getExpenseCategories() {
    return this.budgetCategories
                .filter(x => x.type == CategoryType.Expense);
  }

  get getIncomeCategories() {
    return this.budgetCategories
                .filter(x => x.type == CategoryType.Income);
  }

  get selectedCategoryByType() {
    if (this.selectedChartType == ChartType.Gastos) {
      return this.getExpenseCategories
    }
    return this.getIncomeCategories
  }

  get getExpenseCategoriesNames() {
    return this.getExpenseCategories
                .map((x) => x.alias);
  }

  get getIncomeCategoriesNames() {
    return this.getIncomeCategories
                .map((x) => x.alias);
  }

  get selectedCategoryTypeNames() {
    if (this.selectedCategoryByType.length == 0) return ['Categoría 1', 'Categoría 2', 'Categoría 3', 'Categoría 4']
    if (this.selectedChartType == ChartType.Gastos) {
      return this.getExpenseCategoriesNames
    }
    return this.getIncomeCategoriesNames
  }

  get getExpenseCategoriesColors() {
    return this.getExpenseCategories.map((x) => x.categoryColor);
  }

  get getIncomeCategoriesColors() {
    return this.getIncomeCategories.map((x) => x.categoryColor);
  }

  get selectedCategoryTypeColors() {
    if (this.selectedChartType == ChartType.Gastos) {
      return this.getExpenseCategoriesColors
    }
    return this.getIncomeCategoriesColors
  }

  get pendingIncomeToAsign(): number {
    let categories = this.getExpenseCategoriesTotalPresupuestado();
    let totalPresupuestado = 0;
    if (categories.length) {
      totalPresupuestado = categories.reduce((a,b) => a + b);
    }

    let pending = this.totalIncome - totalPresupuestado;
    return pending;
  }

  get pendingIncomeToExpense(): number {
    let categoriesTotals = this.getIncomeCategoriesTotalEjecutado();
    let totalIncome = 0
    if (categoriesTotals.length) {
      totalIncome = categoriesTotals.reduce((a,b) => a + b);
    }

    let expenseCategoriesTotals = this.getExpenseCategoriesTotalEjecutado();
    let totalExpended = 0
    if (expenseCategoriesTotals.length) {
      totalExpended = expenseCategoriesTotals.reduce((a,b) => a + b);
    }

    let pending = totalIncome - totalExpended
    return pending;
  }

  get pendingIncomeToExpenseFormatted(): string {
    return this.pendingIncomeToExpense.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get pendingIncomeToAsignFormatted(): string {
    return this.pendingIncomeToAsign.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get pendingIncomeToAsignIsNegative(): boolean {
    return this.pendingIncomeToAsign < 0;
  }

  get pendingIncomeToExpenseIsNegative(): boolean {
    return this.pendingIncomeToExpense < 0;
  }

  get pendingIncomeToAsignIsGreaterThan0(): boolean {
    return this.pendingIncomeToAsign > 0;
  }

  get pendingIncomeToAsignAbsFormatted(): string {
    let value = Math.abs(this.pendingIncomeToAsign);
    return value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  getExpenseCategoriesTotalPresupuestado() {
    let categoriesTotal = this.getExpenseCategories.map((x) => {
      let list = x.subcategories?.map((y) => { 
                    // let result = (y.budgeted * (+y.rate)) - (y.executed * (+y.rate));
                    let result = (y.budgeted * (+y.rate));

                    if (result >= 0) {
                      return result
                    }
                    return 0
                  });
      if (!list || !list.length)
        return 0;

      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getExpenseCategoriesTotalEjecutado() {
    let categoriesTotal = this.getExpenseCategories.map((x) => {
      let list = x.subcategories?.map((y) => typeof y.executed == 'string' ? parseFloat(y.executed) * (+y.rate): y.executed * (+y.rate));
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getIncomeCategoriesTotalEjecutado() {
    let categoriesTotal = this.getIncomeCategories.map((x) => {
      let list = x.subcategories?.map((y) => typeof y.executed == 'string' ? parseFloat(y.executed) * (+y.rate): y.executed * (+y.rate));
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getIncomeCategoriesTotalPresupuestado() {
    let categoriesTotal = this.getIncomeCategories.map((x) => {
      let list = x.subcategories?.map((y) => { 
                    // let result = (y.budgeted * (+y.rate)) - (y.executed * (+y.rate));
                    let result = (y.budgeted * (+y.rate));

                    if (result >= 0) {
                      return result
                    }
                    return 0
                  });
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  @Watch("budgetIsLoading")
  budgetLoadingChange(isLoading: boolean) {
    if (!isLoading && !this.doughnutChart) {
      this.initChart();
    }
  }

  @Watch("showDummyData")
  onShowDummyDataChange(showDummy: boolean) {
    if (showDummy) {
      this.setDummyData();
    } else {
      this.updateChart();
    }
  }

  @Watch("changeId")
  onBudgetChange(newValue: string) {
    this.updateChart();
  }

  @Watch("selectedChartType")
  onSelectChartTypeChange(chartType: ChartType) {
    this.updateChart();
  }

  initChart() {
    var canvas = document.getElementById("myChart") as HTMLCanvasElement;
    var ctx = canvas?.getContext("2d");
    if (ctx) ctx.globalCompositeOperation = "destination-over";
    let executed = this.getIncomeCategoriesTotalEjecutado()
    this.doughnutChart = new Chart(ctx, {
      type: "bar",
      data: {
        datasets: [
          {
            data: this.getExpenseCategoriesTotalEjecutado(),
            backgroundColor: this.getExpenseCategoriesColors, //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          },
          {
            data: this.getExpenseCategoriesTotalPresupuestado().map((a, i) => a - executed[i]),
            backgroundColor: '#F2F2F2', //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [...this.getExpenseCategoriesNames, "Sin asignar"],
      },
      options: {
        // title: {
        //     display: true,
        //     text: 'Progreso por categoría',
        //     fontColor: '#10569B',
        // },
        cutoutPercentage: 75,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "rgba(0, 0, 0)",
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              var label = data.labels[tooltipItem.index] || "";
              let value = 0
              if (tooltipItem.datasetIndex == 0) {
                value = data.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index];
              } else {
                value = data.datasets[0]?.data[tooltipItem.index] + data.datasets[1]?.data[tooltipItem.index];
              }
              if (label) {
                label += ": ";
              }
              label += value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return label;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            maxBarThickness: 8,
            gridLines: {
              color: '#FFFFFF',
            },
            ticks: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              borderDash: [4,4],
              color: '#8AACCE72'
            },
            stacked: true,
            ticks: {
                  min: 0,
                  beginAtZero: true,
                  precision: 0,
                  callback: function(value: number, index: any, values: any) {
                      if (value < 1000) {
                        return value
                      }
                      return (value / 1000) + 'K' 
                  }
                }
          }] 
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    });
  }

  updateChart() {
    if (this.doughnutChart) {
      this.doughnutChart.data.datasets = this.setLiveOrDummyData();
      this.doughnutChart.data.labels = [...this.selectedCategoryTypeNames];
      this.doughnutChart.update();
    }
  }

  setLiveOrDummyData() {
    if (this.selectedCategoryByType.length == 0) {
      return [
          {
            data: [2500, 5000, 7000, 2500],
            backgroundColor: ['#8AACCE','#8AACCE','#8AACCE','#8AACCE'], //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          },
          {
            data: [3000, 12000, 1000, 2500],
            backgroundColor: '#f2f2f2cc', //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          }
      ]
    }

      let executedAmount = [] as number[];
      let plannedAmount = [];

      if (this.selectedChartType == ChartType.Gastos) {
        executedAmount = this.getExpenseCategoriesTotalEjecutado();
        plannedAmount = this.getExpenseCategoriesTotalPresupuestado()
      } else {
        executedAmount = this.getIncomeCategoriesTotalEjecutado()
        plannedAmount = this.getIncomeCategoriesTotalPresupuestado()

      }
      return [
        {
          data: executedAmount,
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
          borderRadius: 10,
        },
        {
          data: plannedAmount.map((a, i) => a - executedAmount[i]),
          backgroundColor: "rgba(242, 242, 242, 0.7)", //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        },
      ]
  }

  // To delete
  getDataChartPresupuestado() {
    let expenseCategoriesTotalesPresupuestados = this.getExpenseCategoriesTotalPresupuestado();
    let totalPresupuestadoExpenseCategory = expenseCategoriesTotalesPresupuestados.length 
                                          ? expenseCategoriesTotalesPresupuestados.reduce((a, b) => a + b)
                                          : 0;
    let res = this.totalIncome - totalPresupuestadoExpenseCategory;
    //
    this.showInsufficientIncome = res < 0;
    //
    let absRes = res < 0 ? 0 : res;
    let data = [...expenseCategoriesTotalesPresupuestados, absRes];
    return data;
  }

  getDataChartExpenses() {
    this.getExpenseCategoriesTotalPresupuestado();
  }

  getDataChartEjecutado() {
    let incomeCategoriesTotalesEjecutados = this.getIncomeCategoriesTotalEjecutado();
    let totalEjecutadoIncomeCategory = incomeCategoriesTotalesEjecutados.length
                                          ? incomeCategoriesTotalesEjecutados.reduce((a, b) => a + b)
                                          : 0;
    //
    let expenseCategoriesTotalesEjecutadoss = this.getExpenseCategoriesTotalEjecutado();
    let totalEjecutadoExpenseCategory = expenseCategoriesTotalesEjecutadoss.length
                                      ? expenseCategoriesTotalesEjecutadoss.reduce((a, b) => a + b)
                                      : 0;
    //
    let res = totalEjecutadoIncomeCategory - totalEjecutadoExpenseCategory;
    let absRes = res < 0 ? 0 : res;
    let data = [...expenseCategoriesTotalesEjecutadoss, absRes];
    return data;
  }

  setDummyData() {
   let montos = [2000, 1500, 4000, 0];
   let colors = ["#ede596", "#e57c7c", "#73e0dd"];
   let names = ["Servicios", "Alimentación", "Donaciones", "Sin asignar"];
   //
   this.doughnutChart.data.datasets.forEach((dataset: any) => {
      dataset.data = montos;
      dataset.backgroundColor = colors;
    });
    //
    this.doughnutChart.data.labels = names;
    this.doughnutChart.update();
  }
}

enum ChartType {
    Presupuestado,
    Ejecutado,
    Gastos,
    Ingresos
}
