
import { Component, Vue } from "vue-property-decorator";
import LoginModel from "@/models/IdentityManagement/LoginModel";
import LoadingButton from "@/components/LoadingButton.vue";
import IdentityManagementService from "@/services/IdentityManagementService";
import { Getter } from 'vuex-class';
import Inputmask from "inputmask";
import { Auth } from 'aws-amplify';


@Component({
    components: {
        LoadingButton
    }
})
export default class PasswordRecovery2 extends Vue {
    identityManagementService = new IdentityManagementService();
    userToken = "";
    mainPassword = "";
    confirmPassword = "";
    serverMessage = "";
    isLoading = false;
    userEmail: string = "";

    get passwordsMatch(): boolean {
        return this.mainPassword == this.confirmPassword || !this.confirmPassword;
    }
    
    mounted() {
        //Include external Scripts
        let script = document.createElement('script')
        let url = process.env.BASE_URL + "js/loginui.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);
        //
        Inputmask("999999", { clearMaskOnLostFocus: false }).mask(
            document.getElementById("code")
        );
        //
        this.userEmail = this.$route.query.e as string;
        if (!this.userEmail) {
            this.$router.push("/password-recovery");
        }
    }

    async onSubmit() {
        let token = this.userToken;
        this.isLoading = true;
        try {
            // Collect confirmation code and new password, then
            let result = await Auth.forgotPasswordSubmit(this.userEmail, token, this.mainPassword)
            this.$router.push("/login");
        } catch(err) {
            if (err.code == "CodeMismatchException") {
                this.serverMessage = "El código digitado es inválido, por favor intente de nuevo."
            } else {
                console.log("error recovering password", err);

                this.serverMessage = "Lo siento, ha ocurrido un error en la comunicación.";
            }
        } finally {
            this.isLoading = false;
        }
    }

    onPasteOcurred(event: ClipboardEvent) {
      this.userToken = event.clipboardData?.getData("text") ?? "";
    }

}
