
import { Component, Vue, Prop } from "vue-property-decorator";
import VueTippy, { TippyComponent } from "vue-tippy";

@Component({})
export default class ConfirmTooltip extends Vue {
    @Prop({ default: 'Estas seguro?' }) message!: string;
    @Prop() evaluatedValue!: any;
    @Prop({ default: "" }) userClass!: string;
    @Prop({ default: 0 }) marginTop!: number;

    get marginTopComputed() {
      return this.marginTop + 'px'
    }

    emitResult(value: boolean) {
        (this.$refs.tippy as any).tippy().hide();
        if (value){
            this.$emit("onConfirmTrue", this.evaluatedValue);
        }
    }
}
