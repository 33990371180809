
import ProductSummaryCard from "@/components/assetsLiabilities/ProductSummaryCard.vue"
import MobileProductSummaryCard from "@/components/assetsLiabilities/MobileProductSummaryCard.vue"
import AddProductModal from "@/components/assetsLiabilities/AddProductModal.vue"
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Currency } from '@/models/Common/Common'

@Component({
  components: {
    ProductSummaryCard,
    MobileProductSummaryCard,
    AddProductModal,
  }
})
export default class ProductSummary extends Vue {
  @Prop() isDesktopViewport!: boolean;
  @Getter('Asset/getTotalBankAccountBalanceDOP') totalBankAccountDOP! : number;
  @Getter('Asset/getTotalBankAccountBalanceUSD') totalBankAccountUSD! : number;
  @Getter('Asset/getTotalCreditDOP') totalCreditDOP! : number;
  @Getter('Asset/getTotalConsumedDOP') totalConsumedDOP! : number;
  @Getter('Asset/getTotalCreditUSD') totalCreditUSD! : number;
  @Getter('Asset/getTotalConsumedUSD') totalConsumedUSD! : number;
  @Getter('Asset/getTotalLoanBalanceDOP') totalLoanBalanceDOP! : number;
  @Getter('Asset/getTotalLoanBalanceUSD') totalLoanBalanceUSD! : number;
  @Getter('Asset/getTotalLoanCreditDOP') totalLoanAmountDOP! : number;
  @Getter('Asset/getTotalLoanCreditUSD') totalLoanAmountUSD! : number;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Asset/getDefaultCurrencyCodeForDisplay") defaultCurrencyCode!: string;

  get availableCreditCardDOP() {
    return this.totalCreditDOP - this.totalConsumedDOP
  }

  get availableCreditCardUSD() {
    return this.totalCreditUSD - this.totalConsumedUSD
  }

  get showUSD() {
     if (this.defaultCurrency && this.defaultCurrency.code) {
      return this.defaultCurrency.code.toLowerCase() === 'usd';
    }
    return false;
  }

  get totalLoanAvailable() {
    if (this.defaultCurrency && this.defaultCurrency.code) {
      if (this.defaultCurrency.code.toLowerCase() === 'usd') return this.totalLoanBalanceUSD;
      else return this.totalLoanBalanceDOP;
    }
    return 0
  }

  formatNumber(num : number) {
    return num.toLocaleString('en-US', {minimumFractionDigits: 2})
  }
}
