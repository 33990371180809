
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class MorphinIconButton extends Vue {
    @Prop({ default: '' }) className!: string;
    @Prop() icon1!: string;
    @Prop() icon2!: string;
    @Prop() showSecondIcon!: boolean;

    get icon1Full() {
        return this.icon1 + " default";
    }
    get icon2Full() {
        return this.icon2 + " hover";
    }

    get classes() {
        let classes =  this.className;
        if (this.showSecondIcon) {
            classes += " active";
        }

        return classes;
    }

    clicked() {
        this.$emit("click");
    }
}
