import { render, staticRenderFns } from "./NotificationBadge.vue?vue&type=template&id=1deb7f2e&scoped=true&"
import script from "./NotificationBadge.vue?vue&type=script&lang=ts&"
export * from "./NotificationBadge.vue?vue&type=script&lang=ts&"
import style0 from "./NotificationBadge.vue?vue&type=style&index=0&id=1deb7f2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1deb7f2e",
  null
  
)

export default component.exports