
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import SideBar from "@/components/layout/SideBar.vue";
import InnerFooter from "@/components/layout/InnerFooter.vue";
import TopBar from "@/components/layout/TopBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import BudgetCateogryFiller from "@/components/budget/BudgetCategoryFiller.vue";
import DistributionChart  from "@/components/budget/DistributionChart.vue";
import DistributionSummary  from "@/components/budget/DistributionSummary.vue";
import TransactionSummary from "@/components/budget/TransactionSummary.vue";
import GeneralSummary from "@/components/budget/GeneralSummary.vue";
import Shepherd from 'shepherd.js';
import UserBudget from "@/models/Budget/UserBudget";
import EnrollmentCategoryModal from "@/components/budget/EnrollmentCategoryModal.vue";
import NewBudgetModal from "@/components/budget/NewBudgetModal.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import TransactionTable from "@/components/dashboard/TransactionTable.vue";
import DesktopSideColumn from "@/components/budget/DesktopSideColumn.vue"
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import AccountSummaryOption from "@/components/accountSummary/AccountSummaryOptions.vue"
import Plan from "@/components/accountSummary/Plan.vue"
import BenefitsTable from "@/components/accountSummary/BenefitsTable.vue"
import Swal from 'sweetalert2';

@Component({
  components: {
      LoadingButton,
      SideBar,
      TopBar,
      InnerFooter,
      BudgetCateogryFiller,
      DistributionChart,
      DistributionSummary,
      TransactionSummary,
      GeneralSummary,
      EnrollmentCategoryModal,
      NewBudgetModal,
      TopBarAssets,
      NewTransactionModal,
      TransactionTable,
      DesktopSideColumn,
      BudgetMonthSelection,
      ConfirmTooltip,
      AccountSummaryOption,
      Plan,
      BenefitsTable
  }
})
export default class PaymentHistory extends Vue {
    isMonthly = false

    get paymentsHistory() {
      return [
        {
          plan: 'Plan Premium',
          date: 'Mar 2023',
          amount: 'USD 3.16',
          paymentDate: 'Dec 1, 2022',
          status: 'Pendiente'
        },
        {
          plan: 'Plan Premium',
          date: 'Mar 2023',
          amount: 'USD 3.16',
          paymentDate: 'Dec 1, 2022',
          status: 'Pagada'
        }
      ]
    }

    toMyAccount() {
      this.$router.push('/account')
    }
}
