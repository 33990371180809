
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  BudgetCategory,
  BudgetSubCategory,
  CategoryType,
} from "@/models/Budget/BudgetCategory";
import Chart from "chart.js";
import { Currency } from '@/models/Common/Common'
@Component({
  components: {},
})
export default class FinancialHealth extends Vue {
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Dashboard/getFinancialHealthScore") financialHealthScore!: number;
  @Getter("Dashboard/isDashboardLoaded") isLoaded!: boolean;
  doughnutChart: any = null;
  chartText = 'Denial';
  chartTotalFormatted = 100000;
  flipped = false;
  initialLoad = false

  toBudget() {
    this.$router.push('/budget')
  }

  showModal() {
    (this.$refs['IBFModal'] as any).show();
  }

  fillSurvey() {
    window.location.href = "https://www.caudall.com/encuesta";
  }

  hideModal() {
    (this.$refs['IBFModal'] as any).hide();
  }

  @Watch("financialHealthScore")
  loadIBFScoreGraphic(IBFScore: number | null) {
    if (!this.initialLoad) {
      setTimeout(() => {
        this.initialLoad = true;
      }, 1200)
    }
    if (IBFScore && !isNaN(IBFScore)) {
      setTimeout(this.initChart, 1000)
    }
  }

  get formatedIBFData() {
    if (this.financialHealthScore) {
      return [this.financialHealthScore, 100 - this.financialHealthScore, 0]
    }
    return [0,0,0]
  }

  @Watch("budgetIsLoading")
  budgetLoadingChange(isLoading: boolean) {
    if (!isLoading && !this.doughnutChart) {
      this.initChart();
    }
  }

  flipChart() {
    this.flipped = !this.flipped;
  }

  @Watch("flipped")
  loadGraphic(isLoading: boolean) {
    setTimeout(this.initChart, 1000)
  }

  mounted() {
    if (!this.budgetIsLoading) {
      setTimeout(this.initChart, 1000)
    }
  }

  get chartColor() {
    if (this.financialHealthScore >= 80) return '#58C879'
    else if (this.financialHealthScore >= 40) return '#F49604'
    return '#B94748'
  }

  get IBFMessage() {
    if (this.financialHealthScore >= 80) return '¡Excelente!'
    else if (this.financialHealthScore >= 40) return '¡Casi lo logras!'
    return '¡Debes esforzarte más!'
  }

  get colorClass() {
    if (this.financialHealthScore >= 80) return 'font-green-caudall'
    else if (this.financialHealthScore >= 40) return 'font-warning-caudall'
    return 'font-red-caudall'
  }

  initChart() {
    var canvas = document.getElementById("myChart-lg") as HTMLCanvasElement;
    if (canvas == null) return;
    var ctx = canvas?.getContext("2d");
    if (ctx) ctx.globalCompositeOperation = "destination-over";
    this.doughnutChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: this.formatedIBFData,
            backgroundColor: [this.chartColor, '#D4E1D8'],
            weight: 0.4,
            borderColor: "transparent",
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
      },
      options: {
        cutoutPercentage: 90,
        // responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
        elements: {
          center: {
            text: this.financialHealthScore.toFixed(0) + '%',
            color: '#6D8398', // Default is #000000
            fontStyle: 'Arial', // Default is Arial
            sidePadding: 20, // Default is 20 (as a percentage)
            minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 25 // Default is 25 (in px), used for when text wraps
          }
        }
      },
    });
  }
}

enum ChartType {
    Presupuestado,
    Ejecutado
}
