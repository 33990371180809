<template>
  <div class="align-center mt-4 mb-lg-5">
    <img class="mb-2" :src="getImgUrl(icon)" alt="">
    <p class="my-0 fs-8"><b>{{ upperText }}</b></p>
    <p class="fs-8">{{ lowerText }}</p>
    <button @click="clickAction" class="fs-8" style="border: 0; width: 180px; border: 1px solid #0F559B; padding: 2px 1px; border-radius: 20px; color: #0F559B; background-color: white;">+ Añadir {{ buttonText }}</button>
  </div>
</template>

<script>
export default {
  props: ['upper-text', 'lower-text', 'clickAction', 'icon', 'button-text'],
  methods: {
    getImgUrl(icon) {
      return require('@/assets/img/svg/' + icon + '-gray.svg');
    }
  }
}
</script>

<style scoped>
img {
  width: 50px;
}

@media screen and (min-width:992px) {
  img {
    width: 100px;
    margin-top: 5em;
  }
}
</style>