import BaseApiService from "./BaseApiService";
import { UserNotification } from "@/models/Notification/UserNotification";

export default class NotificationService extends BaseApiService {

    public GetNotifications(userId: string) {
        return this.post<{ idUsuario: string }, UserNotification[]>("obtener-notificaciones-usuario-sitioweb", { idUsuario: userId })
    }

    public MarkNotificationAsReaded(userId: string, transactionId: string) {
        return this.post<{ idUsuario: string, 
                            idTransaccion: string }, Object>("marcar-notificacion-usuario-sitioweb", { idUsuario: userId, idTransaccion: transactionId })
    }

}