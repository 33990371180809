<template>
  <b-container fluid class="credit-card-card-container py-3 my-3 mx-3" @click="selectCard" :class="{'selected-card': selected}">
    <transition name="fade">
      <img class="cc-edit-button" @click.stop="editCurrentAccount" src="@/assets/img/svg/edit.svg" alt="" v-if="selected">
    </transition>
    <transition name="fade">
      <ConfirmTooltip
        :message="'¿Estás seguro que deseas borrar esta tarjeta de crédito?'"
        v-if="selected"
        @onConfirmTrue="deleteAccount">
        <span aria-hidden="true">
          <img class="cc-delete-button" src="@/assets/img/trash.png" alt="">
        </span>
      </ConfirmTooltip>
    </transition>  
    <b-row>
      <b-col class="py-0 pr-0 pl-3" cols="3">
        <img :src="logo" style="borderRadius: 10px; width: 40px;" alt="Icon">
      </b-col>
      <b-col class="py-0 pl-0" cols="9">
        <p class="m-0 p-0 fs-8"><b>{{ bankName }}</b></p>
        <p class="m-0 fs-7">{{ formattedCreditCard }}</p>
      </b-col>
    </b-row>
    <b-row v-if="!isDoubleBalance" class="mt-2">
      <b-col style="border-right: 2px solid #e5e5e5;">
        <p class="m-0 fs-7" :style="{color: debtColor}">Consumido</p>
        <p class="m-0 fs-8" :style="{color: debtColor}"><b>{{ currencyCode }}{{ formattedConsume }}</b></p>
      </b-col>
      <b-col class="align-right">
        <p class="m-0 fs-7" :style="{color: availableColor}">Disponible</p>
        <p class="m-0 fs-8" :style="{color: availableColor}"><b>{{ currencyCode }} {{ availableAmt }}</b></p>
      </b-col>
    </b-row>
    <b-row v-if="isDoubleBalance" class="mt-2">
      <b-col style="border-right: 2px solid #e5e5e5;">
        <p class="m-0 fs-7" :style="{color: availableColor}">Disponible</p>
        <p class="m-0 fs-8" :style="{color: availableColor}"><b>{{ isUsingDOP ? 'DOP ' : 'USD '}}{{ isUsingDOP ? availableDOP : availableUSD }}</b></p>
      </b-col>
      <b-col class="align-right">
        <button @click.stop="toggleBalanceCurrency(true)" class="double-balance-bttn-left" :class="{ 'active-double-balance': isUsingDOP }">DOP</button>
        <button @click.stop="toggleBalanceCurrency(false)" class="double-balance-bttn-right" :class="{ 'active-double-balance': !isUsingDOP }">USD</button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col v-if="!isDoubleBalance">
        <b-progress style="height:0.5em;">
          <b-progress-bar style="backgroundColor: #DE5454;" :value="progressBarValues[1]"></b-progress-bar>
          <b-progress-bar style="backgroundColor: #CEE6FF;" :value="progressBarValues[0]"></b-progress-bar>
        </b-progress>
      </b-col>
      <b-col v-if="isDoubleBalance">
        <b-progress style="height:0.5em;">
          <b-progress-bar style="backgroundColor: #DE5454;" :value="progressBarValuesDoubleBalance[0]"></b-progress-bar>
          <b-progress-bar style="backgroundColor: #CEE6FF;" :value="progressBarValuesDoubleBalance[1]"></b-progress-bar>
        </b-progress>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="7">
        <p class="m-0 fs-7">Límite de tarjeta de crédito</p>
        <p v-if="isDoubleBalance" class="m-0 fs-7"><b>{{ isUsingDOP ? 'DOP ' : 'USD '}}{{ isUsingDOP ? limitDOP : limitUSD }}</b></p>
        <p v-if="!isDoubleBalance" class="m-0 fs-7"><b>{{ currencyCode }}{{ this.formatAmount(limit) }}</b></p>
      </b-col>
      <b-col class="pl-0 align-right">
        <p class="m-0 fs-7">Fecha de corte</p>
        <p class="m-0 fs-7"><b>días {{ paymentDay }} de cada mes</b></p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";

export default {
  components: {
    ConfirmTooltip
  },
  data() {
    return {
      debtColor: '#DE5454',
      availableColor: '#0F559B',
      ratioAvailableDOP: 0,
      ratioAvailableUSD: 0,
      isUsingDOP: false
    }
  },
  computed: {
    mappedBalance() {
      if (this.isDoubleBalance) {
        const mappedBalance = this.balance.map(balance => balance);
        return mappedBalance.sort((a, b) => a.currency.localeCompare(b.currency));
      }
      return null
    },
    limitDOP() {
      if (this.isDoubleBalance) {
        let num = this.balance.find(c => c.currency.code.toUpperCase() == 'DOP').limit
        let amount = typeof num == "string" ? parseFloat(num) : num
        return this.formatNumber(amount);
      }
      return '';
    },
    limitUSD() {
      if (this.isDoubleBalance) {
        let num = (this.balance.find(c => c.currency.code.toUpperCase() != 'DOP').limit);
        let amount = typeof num == "string" ? parseFloat(num) : num
        return this.formatNumber(amount);
      }
      return '';
    },
    availableDOP() {
      if (this.isDoubleBalance) {
        let dopBalance = this.balance.find(c => c.currency.code.toUpperCase() == 'DOP')
        return this.formatNumber(dopBalance.limit - dopBalance.updated_used);
      }
      return '';
    },
    availableUSD() {
      if (this.isDoubleBalance) {
        let usdBalance = this.balance.find(c => c.currency.code.toUpperCase() != 'DOP')
        return this.formatNumber(usdBalance.limit - usdBalance.updated_used);
      }
      return '';
    },
    ratioConsumedDOP() {
      if (this.isDoubleBalance) {
        let dopBalance = this.balance.find(c => c.currency.code.toUpperCase() == 'DOP')
        return ((dopBalance.updated_used / dopBalance.limit) * 100);
      }
      return '';
    },
    ratioratioAvailableDOP() {
      if (this.isDoubleBalance) {
        return 100 - this.ratioConsumedDOP;
      }
      return '';
    },
    ratioConsumedUSD() {
      if (this.isDoubleBalance) {
        let usdBalance = this.balance.find(c => c.currency.code.toUpperCase() != 'DOP')
        return ((usdBalance.updated_used / usdBalance.limit) * 100);
      }
      return '';
    },
    ratioratioAvailableUSD() {
      if (this.isDoubleBalance) {
        return 100 - this.ratioConsumedUSD;
      }
      return '';
    },
    availableAmt() {
      return this.formatNumber(parseInt(this.limit) - parseInt(this.consumed));
    },
    currencyCode() {
      return this.denomination;
    },
    formattedCreditCard() {
      return 'XXXX-XXXX-XXXX-' + this.creditCardCode.slice(-4)
    },
    formattedConsume() {
      return this.formatAmount(this.consumed)
    },
    progressBarValues() {
      var ratio = (parseInt(this.consumed) / parseInt(this.limit)) * 100
      var limitPercent = 100 - ratio
      return [limitPercent, ratio]
    },
    progressBarValuesDoubleBalance() {
      if (this.isDoubleBalance && this.isUsingDOP) return [this.ratioConsumedDOP, this.ratioAvailableDOP]
      if (this.isDoubleBalance && !this.isUsingDOP) return [this.ratioConsumedUSD, this.ratioAvailableUSD]
      return [0, 0] 
    },
    paymentDay() {
      if (this.dueDate) {
        return this.dueDate.split('-').pop()
      }
      return ''
    }
  },
  props: ['bank-name', 'denomination', 'credit-card-code', 'consumed', 'limit', 'due-date', 'id', 'balance', 'is-double-balance', 'selected', 'logo', 'dopCode'],
  mounted() {
    this.isUsingDOP = this.denomination.toLowerCase() == 'dop';
  },
  methods: {
    selectCard(e) {
      if (e.target.className !== 'cc-delete-button') {
        this.$emit('toggle-select', this.id);
      }
    },
    toggleBalanceCurrency(value) {
      this.isUsingDOP = value;
      this.$emit('toggle-select', this.id, value ? 'DOP' : 'USD', false)
    },
    formatNumber(num) {
      return num.toLocaleString('en-US', {minimumFractionDigits: 2});
    },
    editCurrentAccount() {
      this.$emit('edit-account', this.id);
    },
    deleteAccount() {
      this.$emit('delete-account', this.id)
    },
    formatAmount(amount) {
      if (typeof amount == "string") amount = parseFloat(amount)

      if (this.defaultCurrency) {
        return ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
      }
      return ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
    }
  }
}
</script>

<style>
.double-balance-bttn-right {
  border-radius: 0 6px 6px 0;
  color: #6D8398; 
  background-color: #F5F7F8; 
  border: 1px solid #6D8398
}
.double-balance-bttn-left {
  border-radius: 6px 0 0 6px;
  color: #6D8398;
  background-color: #F5F7F8; 
  border: 1px solid #6D8398;
}
.active-double-balance {
  color: #0F559B !important;
  background-color: #CEE6FF !important; 
}
</style>

<style>
.credit-card-card-container {
  position: relative;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #CEE6FF;
	border-radius: 12px;
  max-width: 300px;
}
.credit-card-card-container:hover {
  box-shadow: var(--hover-shadow);
}
.cc-edit-button {
  width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -15px;
    right: 0;
    left: calc(86%);
}
.cc-delete-button {
  padding: 8px;
  width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 1px #ced0d3;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background-color: white;
    position: absolute;
    margin: auto;
    top: -11px;
    right: 0;
    left: calc(65%);
}
@media screen and (min-width: 390px) {
  .cc-edit-button {
    left: calc(90%);
  }
}

@media screen and (min-width: 992px) {
  .credit-card-card-container {
    max-width: 335px;
    min-width: 335px;
  }
}
</style>