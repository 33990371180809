
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory } from "@/models/Budget/BudgetCategory";
import CategoryEntry from "@/components/budget/CategoryEntry.vue";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
  components: {
    CategoryEntry,
  },
})
export default class EnrollmentCategoryModal extends Vue {
  budgetService: BudgetService = new BudgetService();
  enrollmentCategories: BudgetCategory[] = [];

  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Budget/getBudgetCategories") BudgetCategories!: BudgetCategory[];
  @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
  @Getter("Budget/getShowEnrollmentCategoriesModal")
  showEnrollmentCategoriesModal!: boolean;
  @Getter("IdentityManagement/getUser") user!: UserModel;

  get showModal() {
    return (
      this.showEnrollmentCategoriesModal && this.enrollmentCategories.length > 0
    );
  }

  @Watch("showModal")
  onShowModalChange(newValue: boolean) {
    // if (newValue) this.$bvModal.show("modalEnrollCateg");
    // else this.$bvModal.hide("modalEnrollCateg");
    if (newValue) ($("#modalEnrollCateg") as any).modal("show");
    else ($("#modalEnrollCateg") as any).modal("hide");
    //
    //Each time modal show or hide update the enrollmentCategories
    //Only with the unfilled categories
    this.updateEnrollmentCategories();
  }

  updateEnrollmentCategories() {
    //Filter the unfilled enrollmentCategory
    let unfilledCategories = this.enrollmentCategories
      .filter(ec => !this.BudgetCategories.some(bc => bc.uuid == ec.uuid));
    
    this.enrollmentCategories = unfilledCategories;
  }

  goNextTabOrClose() {
    let nextElement = document.querySelector(
      "a.nav-item.nav-link.active + a.nav-item.nav-link"
    ) as HTMLAnchorElement;
    if (nextElement) {
      nextElement.scrollIntoView();
      //If the is another tab
      nextElement.click();
    } else {
      this.$store.dispatch("Budget/hideEnrollmentCategoriesModal");
    }
  }

  closeModal() {
    this.$store.dispatch("Budget/hideEnrollmentCategoriesModal");
  }
}
