import { render, staticRenderFns } from "./GeneralSummary.vue?vue&type=template&id=684bca0c&scoped=true&"
import script from "./GeneralSummary.vue?vue&type=script&lang=ts&"
export * from "./GeneralSummary.vue?vue&type=script&lang=ts&"
import style0 from "./GeneralSummary.vue?vue&type=style&index=0&id=684bca0c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684bca0c",
  null
  
)

export default component.exports