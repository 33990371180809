
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, CategoryType } from "@/models/Budget/BudgetCategory";
import UserBudget from "@/models/Budget/UserBudget";
import moment from "moment";
import Swal from 'sweetalert2';

@Component({
})
export default class BudgetMonthSelection extends Vue {
    @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
    @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
    @Getter("Layout/isMobileView") isMobile!: boolean;

    get activeBudgetMonth() {
      if (this.activeBudget) {
        return this.formatDateToMonthAndYear(this.activeBudget.date);
      } else {
        return "Seleccionar";
      }
      
    }

    formatDateToMonthAndYear(isoDate: string) {
      let mDate = moment(isoDate);
      return mDate.format("MMMM YYYY");
    }

    formatDateToMonthAndYearDropdown(isoDate: string) {
      let mDate = moment(isoDate);
      return mDate.format("MMMM, YYYY");
    }

    changeActiveBudget(userBudget: UserBudget) {
      this.$store.dispatch("Budget/setActiveBudget", userBudget.uuid);
    }

    callModal() {      
      this.$store.dispatch("Budget/showNewBudgetModal");
    }

}
