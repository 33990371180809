<template>
  <div id="add-new-transaction-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button style="background-color: #F6F6F6; border-radius: 50%;" class="uk-modal-close-default" type="button" uk-close></button>
      <p class="fs-10 mb-1" style="fontWeight: bold;">Añadir una nueva transacción</p>
      <p class="mb-3" style="fontSize:0.7em; fontWeight: 400; color: #B5B5B5;">Completa el formulario para registrar algún gasto o ingreso</p>
      <ul class="transaction-modal-tab" uk-tab>
        <li><a href="#" id="expense" style="text-transform: none;">Registrar gasto</a></li>
        <li><a href="#" style="text-transform: none;">Registrar ingreso</a></li>
      </ul>
      <ul class="uk-switcher">
        <li>
          <form class="uk-grid">
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">¿En qué gastaste dinero?</label>
              <input class="uk-input" type="text" placeholder="Ej: Pago Spotify, Boleta del cine, Taxi, etc" required>
            </div>
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">Tipo de gasto</label>
              <input class="uk-input" type="text" placeholder="Comida, entretenimiento, etc..." required>
            </div>
            <div class="uk-width-1-1  uk-width-1-2@m uk-form-controls">
              <label class="uk-form-label">Valor del gasto</label>
              <money style="height: 30px; border-radius: 4px; font-size: 0.7em;" class="uk-input align-right" placeholder="0.00" value="" v-bind="money"></money>
            </div>
            <div class="uk-width-1-1 uk-width-1-2@m uk-form-controls">
              <label class="uk-form-label">Fecha del gasto</label>
              <input class="uk-input" type="date" required>
            </div>
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">¿De donde consumiste ese gasto?</label>
              <select class="uk-select bank-account-select" placeholder="Seleccionar tipo">
                <option value="" selected>Tarjeta de crédito, cuenta de banco, etc</option>
                <option value="2">Corrientes</option>
                <option value="3">Empresarial</option>
              </select>
            </div>
            <div class="mt-4 ml-auto">
              <button style="backgroundColor: white; color: #0F559B; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar y añadir otra</button>
              <button class="ml-2" style="backgroundColor: #0F559B; color: white; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar</button>
            </div>
          </form>
        </li>
        <li>
          <form class="uk-grid">
            <div class="uk-width-1-1  uk-width-1-2@m uk-form-controls">
              <label class="uk-form-label">Valor del ingreso</label>
              <money style="height: 30px; border-radius: 4px; font-size: 0.7em;" class="uk-input align-right" placeholder="0.00" value="" v-bind="money"></money>
            </div>
            <div class="uk-width-1-1 uk-width-1-2@m uk-form-controls">
              <label class="uk-form-label">Fecha del ingreso</label>
              <input class="uk-input" type="date" required>
            </div>
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">Tipo de ingreso</label>
              <input class="uk-input" type="text" placeholder="Comida, entretenimiento, etc..." required>
            </div>
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">Descripción o motivo del ingreso</label>
              <input class="uk-input" type="text" placeholder="Ej: Venta carro Veloster 2020" required>
            </div>
            <div class="uk-width-1-1 uk-form-controls">
              <label class="uk-form-label">Destino del ingreso</label>
              <select class="uk-select bank-account-select">
                <option value="" selected>Seleccionar producto (Cuenta de ahorro, cuenta corriente, etc)</option>
                <option value="2">Corrientes</option>
                <option value="3">Empresarial</option>
              </select>
            </div>
            <div class="mt-4 ml-auto">
              <button style="backgroundColor: white; color: #0F559B; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar y añadir otra</button>
              <button class="ml-2" style="backgroundColor: #0F559B; color: white; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar</button>
            </div>
          </form>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  props: ['isExpense']
}
</script>

<style>
.uk-active > #expense {
  border-color: #DE5454;
}
.transaction-modal-tab {
  flex-wrap: nowrap;
}

</style>