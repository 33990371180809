
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserBudget from "@/models/Budget/UserBudget";
import Chart from "chart.js";
import { Currency } from '@/models/Common/Common'
import EmptyStateDashboard from "../helpers/emptyStateDashboard.vue";
import { BehaviourInTime } from "@/models";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
@Component({
  components: {
    EmptyStateDashboard
  },
})
export default class TransactionAggregateChart extends Vue {
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Dashboard/getBehaviourInTime") behaviourInTimeSummary!: BehaviourInTime[];
  @Getter("Dashboard/isDashboardLoaded") isLoaded!: boolean;
  @Getter("IdentityManagement/getUserCreatedDate") userCreatedDate!: Date;
  @Getter("Dashboard/getDashboardTransactions") dashboardTransactions!: BudgetTransaction[];
  initialLoad = false;

  get showEmptyState() {
    if (this.behaviourInTimeSummary) {
      return this.behaviourInTimeSummary.length <= 0
    }
    return false
  }
  
  get monthsToShow() {
    if (this.dashboardTransactions && this.dashboardTransactions.length) {
      let fromDate = this.lastTransactionDate;
      let toDate = new Date();
      
      if (toDate.getMonth() - fromDate.getMonth() == 0) return 1;  
      return toDate.getMonth() - fromDate.getMonth() + (12 * (toDate.getFullYear() - fromDate.getFullYear())) + 1;
    }
    return 1
  }

  get lastTransactionDate() {
    if (this.dashboardTransactions && this.dashboardTransactions.length) {
      let transactionListCopy = this.dashboardTransactions.slice()
      let transactionDate = new Date(transactionListCopy.sort((a, b) => { return new Date(a.date).getTime() - new Date(b.date).getTime() })[0].date)
      return transactionDate
    }
    return new Date()
  }

  @Watch("monthsToShow")
  setMonthsToDisplay(months: number) {
    if (months < 12) this.chartMonthFormatDisplay = months;
  }
  
  barChart: any = null;
  monthsLabel = ['Ene', 'Feb', 'Mar', 'Abr','May','Jun','Jul','Ago','Sep','Oct', 'Nov', 'Dic']
  chartMonthFormatDisplay = 12
  chartMonthFormatDisplayOptions = [3, 6, 12, 24]
  transactionTypeOptions = ['Todos', 'Ingresos', 'Gastos']
  selectedTransactionType = 'todos'

  @Watch("chartMonthFormatDisplay")
  updateChartTitle() {
    Vue.nextTick(() => {
        this.updateChart();
      })
  }

  @Watch("behaviourInTimeSummary")
  loadChart(value: BehaviourInTime[]) {
    if (!this.initialLoad) this.initialLoad = true;
    if (value && value.length) {
      Vue.nextTick(() => {
        this.startChart();
      })
    }
  }

  get yAxisCurrencyLabel() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.short_name
    }
    return ''
  }

  toBudget() {
    this.$router.push('/budget')
  }

  get monthLabelsToDisplay() {
    const sortedSummaryMonths = this.sortedSummary.map(i => parseInt(i.date.split('-')[1]) - 1);
    return sortedSummaryMonths.map(month => this.monthsLabel[month]).slice(-this.chartMonthFormatDisplay);
  }

  get sortedSummary() {
    if (this.behaviourInTimeSummary) {
      let summary = this.behaviourInTimeSummary.map(bh => { return {...bh} });
      return summary.sort(function(a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })
  }
    return []
  }

  get incomeTransactionToDisplay() {
    const incomeTransactions = this.sortedSummary.map(i => i.income);
    return incomeTransactions.slice(-this.chartMonthFormatDisplay);
  }

  get expenseTransactionToDisplay() {
    const expenseTransactions = this.sortedSummary.map(i => i.expenses);
    return expenseTransactions.slice(-this.chartMonthFormatDisplay);
  }

  get summaryToDisplay() {
    const incomeDataset = {
      label: 'Ingresos',
      data: this.incomeTransactionToDisplay,
      backgroundColor: '#B4F8C9',
      borderColor: '#B4F8C9',
      borderWidth: 1
    }
    const expenseDataset = {
      label: 'Gastos',
      data: this.expenseTransactionToDisplay,
      backgroundColor: '#FFD3D3',
      borderColor: '#FFD3D3',
      borderWidth: 1
    }

    if (this.selectedTransactionType.toLowerCase() === 'todos') {
      return [incomeDataset, expenseDataset]
    } else if (this.selectedTransactionType.toLowerCase() === 'ingresos') {
      return [incomeDataset] 
    } else {
      return [expenseDataset]
    }
  }

  mounted() {  
    if (this.monthsToShow >= 12) this.chartMonthFormatDisplay = 12;
    else this.chartMonthFormatDisplay = this.monthsToShow
  }

  updateChart() {
    if (this.barChart) {
      this.barChart.data.datasets = this.summaryToDisplay
      this.barChart.data.labels =  this.monthLabelsToDisplay
      this.barChart.options.title.text = this.chartTitle
      this.barChart.update();
    }
  }

  showTypeSelected(type: string) {
    return type.toLowerCase() === this.selectedTransactionType;
  }

  selectTransactionType(type: string) {
    this.selectedTransactionType = type.toLowerCase();
    // this.barChart.update();
    this.updateChart()
  }

  setChartMonthFormatDisplayOption(type: number) {
    if (this.chartMonthFormatDisplay == type) this.chartMonthFormatDisplay = this.monthsToShow;
    else this.chartMonthFormatDisplay = type;
    this.updateChart();
  }

  get chartTitle() {
    return 'Últimos ' + this.chartMonthFormatDisplay + ' meses';
  }

  startChart() {
    const ctx = (document.getElementById('transactionAggChart') as HTMLCanvasElement).getContext('2d');
    this.barChart = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: this.monthLabelsToDisplay,
          datasets: this.summaryToDisplay
      },
      options: {
          scales: {
              y: {
                  precision: 2,
                  display: true,
                  labelString: this.yAxisCurrencyLabel
              },
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  callback: function(value: number, index: any, values: any) {
                      if (value < 1000) {
                        return value
                      }
                      return (value / 1000) + 'K' 
                  }
                }
              }],
              xAxes: [{
                gridLines: {
                  display: false
                }
              }]
          },
          legend: {
            align: 'start',
            labels: {
              usePointStyle: true
            }
          },
          title: {
            display: true,
            text: this.chartTitle,
            lineHeight: 1
          },
          maintainAspectRatio: false
      },
      plugins: [{
        beforeInit: function(chart: any, options: any) {
          chart.legend.afterFit = function() {
            this.height = this.height + 25;
          };
        }
      }]
    });
  }
}
