
import { Component, Vue, Watch } from "vue-property-decorator";
import RegisterModel from "@/models/IdentityManagement/RegisterModel";
import LoadingButton from "@/components/LoadingButton.vue";
import { Auth } from "aws-amplify";
import IdentityManagementService from "@/services/IdentityManagementService";
// import Inputmask from "inputmask";
// import { extend } from "vee-validate";
// import moment from "moment";

// extend("isValidDate", {
//   validate: (value) => {
//     let onlyNumbersDate = value.match(/\d+/g)?.join('');
//     let momentDate = moment(value, "DD/MM/YYYY");
//     return onlyNumbersDate.length == 8 && momentDate.isValid();
//   },
//   message: "Esta fecha no es válida, por favor introduce una fecha en formato DD/MM/YYYY.",
// });
// extend("dateIsNotGreaterThanToday", {
//   validate: (value) => {
//     let momentDate = moment(value, "DD/MM/YYYY");
//     let now = moment();
//     let pickedDateIsLowerThantNow = now.isAfter(momentDate);
//     return pickedDateIsLowerThantNow;
//   },
//   message: "Lo siento, pero todavía no aceptamos personas del futuro.",
// });

@Component({
  components: {
    LoadingButton,
  },
})
export default class Register extends Vue {
  formModel = new RegisterModel();
  checkConditionsAndTerms = false;
  showTermsError = false;
  isLoading = false;
  serverErrorMessage = "";
  userExists = false;
  identityManagementService = new IdentityManagementService();
  // datePickerDateFormOptions = {
  //   year: "numeric",
  //   month: "long",
  //   day: "2-digit",
  // };

  @Watch("checkConditionsAndTerms")
  onCheckBoxValueChange(newValue: boolean, oldValue: boolean) {
    this.showTermsError = !newValue;
  }

  removeUserExists() {
    if (this.userExists) this.userExists = false
    return;
  }

  mounted() {
    //Add Ui Script
    let script = document.createElement("script");
    let url = process.env.BASE_URL + "js/loginui.js";
    script.setAttribute("src", url);
    document.body.appendChild(script);
    //
    //this.activateMaskOnDatePicker();
  }

  // activateMaskOnDatePicker() {
  //   Inputmask("99/99/9999", 
  //           { 
  //             clearMaskOnLostFocus: true, 
  //           })
  //           .mask(document.getElementById("birthDateInput"));
  // }

  // deactivateMaskOnDatePicker() {
  //   Inputmask.remove(document.getElementById("birthDateInput"));
  // }

  async onSubmit() {
    //Validate checkBox
    if (!this.checkConditionsAndTerms) {
      this.showTermsError = true;
      return;
    }
    this.isLoading = true;
    this.serverErrorMessage = "";

    try {
      let email = this.formModel.correoElectronico as string;
      let birthDate = this.formModel.fechaNacimiento as string;
      let gender = this.formModel.sexo as string;
      let result = await this.signUpUser(email, birthDate, gender);
      //
      if (result.userExists) {
        this.userExists = true;
        return 
      }
      if (result.sendToConfirmation) {
        let userId = result.userId;
        
        this.$store.dispatch("IdentityManagement/pendingEmailConfirmation", {email,userId});
        const storageNombre = this.formModel.nombre ? this.formModel.nombre : '';
        localStorage.setItem('nombre', storageNombre)
        this.$router.push("/confirm-registration");
      } 
      else if (result.sendToLogin) {
        this.$router.push("/login");
      }
      
    } catch (error) {
      if (error.code == "LimitExceededException") {
        this.serverErrorMessage = "Lo siento, has excedido el límite de intentos para esta acción. Intentalo más tarde.";
      }
      console.log("error signing up:", error);
    } finally {
      this.isLoading = false;
    }
  }

  async signUpUser(email: string, 
                  birthDate: string,
                  gender: string): Promise<{sendToConfirmation: boolean, 
                                            userExists: boolean,
                                            sendToLogin: boolean,
                                            userId: string }> {
    var returnValue = {
      sendToLogin: false,
      sendToConfirmation: false,
      userId: "",
      userExists: false
    }
    try {
      const result = await Auth.signUp({
        username: email,
        password: this.formModel.contrasena as string,
        attributes: {
          email: this.formModel.correoElectronico as string, // optional
          name: this.formModel.nombre as string, // optional - E.164 number convention
          // other custom attributes
          // birthdate: birthDate,
          // gender: gender,
        },
      });
      returnValue.sendToConfirmation = !result.userConfirmed;
      returnValue.sendToLogin = result.userConfirmed;
      returnValue.userId = result.userSub;
      //
    } catch(error) {
      if (error.code == "UsernameExistsException"){
        returnValue.userExists = true;
        return returnValue;
      }
      //
      throw error;
    }

    return returnValue;
  }
}
