
import { Component, Vue } from "vue-property-decorator";
import LoadingButton from "@/components/LoadingButton.vue";
import { Getter } from "vuex-class";
import Inputmask from "inputmask";
import { Auth } from "aws-amplify";
import IdentityManagementService from "@/services/IdentityManagementService";

@Component({
  components: {
    LoadingButton,
  },
})
export default class ConfirmRegistration extends Vue {
  identityManagementService = new IdentityManagementService();
  userToken = "";
  isLoading = false;
  @Getter("IdentityManagement/getConfirmationEmail") userEmail!: string;
  @Getter("IdentityManagement/getConfirmationUserId") userId!: string;
  @Getter("IdentityManagement/getConfirmationSubscription") userSubscription!: string;
  serverMessage = "";
  showSuccess = false;
  showGoToLogin = false;

  created() {
    //TODO: If not found in store look for URL Value
    if (!this.userEmail) {
      console.warn("There was not confirmation email in store");
      this.$router.push("/register");
    }
  }

  mounted() {
    Inputmask("999999", { clearMaskOnLostFocus: false, inputmode: "numeric" }).mask(
      document.getElementById("code")
    );
    //
  }

  async onSubmit() {
    let token = this.userToken;
    this.isLoading = true;
    this.serverMessage = "";
    try {
      await Auth.confirmSignUp(this.userEmail, token);      
      this.showSuccess = true;
      setTimeout(() => {
        this.$router.push("/welcome-page");
      }, 3000);

    } catch (error) {
      if (error.code == "CodeMismatchException") {
          this.serverMessage = "El código digitado es inválido, por favor intente de nuevo.";
      } 
      else if (error.code == "NotAuthorizedException" 
              && error.message.indexOf("CONFIRMED") > -1) {
          this.serverMessage = "Este usuario ya se encuentra confirmado, por favor proceda ha iniciar sesión.";
          this.showGoToLogin = true;
      }
      else {
          console.log("error confirming sign up", error);
          this.serverMessage = "Lo siento, ha ocurrido un error en la comunicación.";
      }
    } finally {
      this.isLoading = false;
    }
  }

  onPasteOcurred(event: ClipboardEvent) {
      this.userToken = event.clipboardData?.getData("text") ?? "";
  }
}
