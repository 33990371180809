<template>
  <div id="select-product-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body roboto" id="modal-body">
      <p class="fs-10 mb-1" style="fontWeight: bold;">¿Qué deseas añadir?</p>
      <p class="mb-3" style="fontSize:0.7em; fontWeight: 400; color: #B5B5B5; marginBottom: 0.5em;">Selecciona la opción y completa el formulario</p>
      

      <button :key="i" @click="openModal(product.modalToLoad, product.clearEvent)" class="uk-button uk-flex py-2 mb-2" :style="{backgroundColor: bkgColor}" style="borderRadius: 8px; width: 100%; align-items:center; textTransform: none;" v-for="(product, i) in productList">
        <img style="width: 22px;" class="mr-3" :src="getImgUrl(product.icon)" alt="">
        <p class="mb-0">{{ product.text }}</p>
      </button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bkgColor: '#CEE6FF',
      productList: [
        {
          icon: 'wallet',
          text: 'Añadir cuenta bancaria',
          modalToLoad: 'bank-account-modal',
          clearEvent: 'clearBankAccount'
        },
        {
          icon: 'credit-card',
          text: 'Añadir tarjeta de crédito',
          modalToLoad: 'credit-card-modal',
          clearEvent: 'clearCreditCard'
        },
        {
          icon: 'loan',
          text: 'Añadir préstamo',
          modalToLoad: 'loan-modal',
          clearEvent: 'clearLoan'
        }
      ]
    }
  },
  methods: {
    openModal(modalName, clearEvent) {
      this.$root.$emit(clearEvent);
      UIkit.modal(document.getElementById(modalName)).show();
    },
    getImgUrl(icon) {
      return require('@/assets/img/svg/' + icon + '-dark-blue.svg');
    }
  }

}
</script>
  
<style scoped>
@media screen and (min-width:992px) {
  #modal-body {
    width: 400px;
  }
}
</style>