
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  BudgetCategory,
  BudgetSubCategory,
  CategoryType,
} from "@/models/Budget/BudgetCategory";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import moment from "moment";
import ConfirmDelete from "@/components/tooltips/ConfirmDelete.vue";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
  components: {
    ConfirmDelete
  },
})
export default class TransactionSummary extends Vue {
  budgetService = new BudgetService();
  showAllTransaction = false;
  @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
  @Getter("Budget/getCategoryCatalogIsLoading")
  categoryCatalogIsLoading!: boolean;
  @Getter("Budget/getAllAvailableCategories")
  categoryCatalog!: BudgetCategory[];
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/getBudgetTransactions") transactions!: BudgetTransaction[];
  @Getter("Budget/getActiveBudget") activeuserBudget!: UserBudget;
  @Getter("Budget/getInvalidTransactionState") disableAddTransaction!: boolean;
  isLoading = false;

  get expenseCategories() {
    return this.budgetCategories.filter((x) => x.type == CategoryType.Expense);
  }

  openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();
    }

  get canRenderTransactions() {
    return !this.categoryCatalogIsLoading && this.transactions.length > 0;
  }
  get displayableTransactions(): BudgetTransaction[] {
    if (this.transactions.length == 0) return [];

    let copy = [...this.transactions];
    let sortArray = copy.sort(function (a, b) {
      return +new Date(b.date) - +new Date(a.date);
    });
    if (!this.showAllTransaction) {
      return sortArray.slice(0, 3);
    }
    return sortArray;
  }

  get toggleButtonText() {
    return this.showAllTransaction 
          ? "Ver menos"
          : "Ver todas";
  }

  @Watch("activeuserBudget")
  onActiveUserBudgetChange() {
    this.loadTransactions();
  } 

  mounted() {
  }

  loadTransactions() {
    this.isLoading = true;
    let userId = this.user.id;
    let budgetMonth = this.activeuserBudget.date;

    this.budgetService.GetUserTransactions(userId, budgetMonth)
    .then((x) => {
      if (x.data.statusCode == 200)
        this.$store.dispatch("Budget/budgetTransactionsLoaded", x.data.entity);
      else if (x.data.statusCode == 404) 
        console.log("user does not have transactions");
      else
        console.warn("Error loading transactions");
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

  getCategoryName(subCategoryId: string) {
    let category = this.getCategory(subCategoryId);

    if (!category) return "Undefined Category";

    return category.category;
  }

  getCategoryColor(subCategoryId: string) {
    let category = this.getCategory(subCategoryId);

    if (!category) return "black";

    return category.color;
  }

  getSubCategoryName(subCategoryId: string) {
    let category = this.getCategory(subCategoryId);
    let subCategory = category?.subcategories?.filter(
      (x) => x.uuid == subCategoryId
    )[0];

    if (!category || !subCategory) return "Undenifed";

    if (subCategory.created_from_template) {
        return subCategory.alias;
    }

    return subCategory.alias || subCategory.sub_category;
  }

  getCategory(subCategoryId: string) {
    let category = this.categoryCatalog.filter((c) =>
      c.subcategories?.some((s) => s.uuid == subCategoryId)
    )[0];
    if (!category) {
      category = this.categoryCatalog.filter((c) => c.uuid == subCategoryId)[0];
    }

    return category;
  }

  formatDate(date: string) {
    return moment(date).fromNow();
  }

  toggleView() {
    this.showAllTransaction = !this.showAllTransaction;
  }

  onDelete(transaction: BudgetTransaction) {
    this.$store.dispatch("Budget/removeTransaction", transaction);
    this.budgetService
        .DeleteTransaction(this.user.id, transaction)
        .then((res) => {
          if (res.data.statusCode != 200) {
            //Re add the transaction to the array
            this.$store.dispatch("Budget/newTransactionAdded", transaction);
            this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
          }
        })
        .catch((err) => {
          console.log(err)
          this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
        })
  }
}
