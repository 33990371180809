
import { Component, Vue, Prop } from "vue-property-decorator";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import ConfirmTooltipMobile from "@/components/tooltips/ConfirmTooltipMobile.vue";
import { Getter } from "vuex-class";

@Component({
    components: {
        ConfirmTooltip,
        ConfirmTooltipMobile
    }
})
export default class ConfirmDelete extends Vue {
    @Prop({ default: 'Estas seguro?' }) message!: string;
    @Prop() evaluatedValue!: any;
    @Getter("Layout/isMobileView") isMobile!: boolean;

    onConfirmTrue(value: boolean) {
        this.$emit("onConfirmTrue", value);
    }

    get transactionDetails() {
      const date = this.getStringDate()
      if (this.evaluatedValue && this.evaluatedValue.descripcion && this.evaluatedValue.monto) {
        return this.evaluatedValue.descripcion + ' - ' + this.evaluatedValue.monto.toLocaleString("en-US", {minimumFractionDigits: 2,maximumFractionDigits: 2,}) + ' - ' + date;
      }
      return '';
    }

    getStringDate() {
      if (this.evaluatedValue && this.evaluatedValue.fechaTransaccion) {
        return new Date(this.evaluatedValue.fechaTransaccion).toLocaleDateString();
      }
      return ''
    }

    confirmOnMobile() {
        const confirmationValue = window.confirm(this.message);
        if (confirmationValue)
            this.onConfirmTrue(confirmationValue);
    }
}
