
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Multiselect from "vue-multiselect";
import { extend } from "vee-validate";
import { max } from "vee-validate/dist/rules";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import UserBudget from "@/models/Budget/UserBudget";
import { MonthPicker } from "vue-month-picker";
import moment from "moment";
import NewBudgetRequest from "@/models/Budget/NewBudgetRequest";
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import { generateGUID } from "@/utils/HelperMethods";
import { BudgetTemplate } from "@/models/Budget/BudgetCategory";

@Component({
  components: {
    MonthPicker,
    Multiselect,
  },
})
export default class NewBudgetModal extends Vue {
  @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
  @Getter("Budget/getShowNewBudgetModal") showNewBudgetModal!: boolean;
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter('Budget/getBudgetConfiguration') budgetConfiguration!: BudgetConfiguration;
  @Getter('Budget/getBudgetTemplates') template!: BudgetTemplate[];
  @Getter('IdentityManagement/getUserSubscription') subscription!: string;

  budgetService = new BudgetService();
  model: NewBudgetRequest = { name: "", date: "", subscription: ""}
  isLoading = false;
  selectedSourceBudget = null;
  monthPickerMessage = "";
  defaultMonth = 0;
  defaultYear = 0

  get existingBudgetMonths() {
    let values = this.userBudgets.map((x) => {
      return { key: x.date, label: this.formatDateToMonthAndYear(x.date) };
    });

    return values;
  }
  get minDate() {
      let now = new Date();
      let previousMonth = new Date(now.setMonth(now.getUTCMonth()-1));
      return previousMonth;
  }

  customUserBudget(userBudget: UserBudget) {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Novimebre', 'Diciembre']
    moment.locale()
    return moment(userBudget.date).format("MMMM YYYY")
  }

  get userExceededLimit(): boolean {
      if (this.budgetConfiguration) {
        let maxPresupuestos = this.budgetConfiguration.maxPresupuestos;
        let limitReached = this.userBudgets.length >= maxPresupuestos;
        return limitReached;
      }
      return true;
  }

  get getOlderUserBudgetDateName() {
    let userBudgets = [...this.userBudgets];
    let olderBudget = userBudgets
                      .filter(b => b.is_active)
                      .sort((a,b) => {
                return new Date(a.date).getTime() - new Date(b.date).getTime();
              })[0];

    if (!olderBudget)
      return "";

    let mDate = moment(olderBudget.date);
    return mDate.format("MMMM YYYY");
  }

  @Watch("showNewBudgetModal")
  onShowNewBudgetModalChange(newValue: boolean) {
    if (newValue) {
        this.$store.dispatch("Budget/getBudgetTemplates");
        ($("#newBudgetModal") as any).modal("show");
        this.setDefaultValueToMonthPicker();
        this.clearMonthPickerError();
    } 
    else ($("#newBudgetModal") as any).modal("hide");
    //
  }

  mounted() {
      this.setDefaultValueToMonthPicker();
  }

  setDefaultValueToMonthPicker() {
      let value = this.calculateNextMonth();
      this.defaultYear = value.getUTCFullYear();
      //Este hardcode es necesario para que la libreria pueda actualizar el UI
      this.defaultMonth = 1;
      Vue.nextTick(() => {
          this.defaultMonth = value.getUTCMonth();
      })
      this.model.date = value.toISOString();
  }

  calculateNextMonth() {
      let copy = [...this.existingBudgetMonths];
      let lastBudgetMonth = copy.sort((a,b) => {
          return new Date(b.key).getTime() - new Date(a.key).getTime();
      })[0];
      
      if (!lastBudgetMonth) {
          let now = new Date();
          return now;
      }
      let lastBudgetMonthDate = new Date(lastBudgetMonth.key);
      let lastBudgetMonthDateOffset = new Date(lastBudgetMonthDate.getTime() + lastBudgetMonthDate.getTimezoneOffset() * 60000);
      let nextMonth = new Date(lastBudgetMonthDateOffset.setMonth(lastBudgetMonthDateOffset.getUTCMonth()+1));
      
      return nextMonth;
  }

  onDateChange(value: { from: Date }) {
    this.model.date = value.from.toISOString();
    this.validateMonthPicker();
  }
  formatDateToMonthAndYear(isoDate: string) {
    let mDate = moment(isoDate);
    return mDate.format("MMMM YYYY");
  }

  validateMonthPicker() {
      this.clearMonthPickerError();
      //Validate not empty
      if (!this.model.date) {
          this.monthPickerMessage = "Este campo es requerido.";
          return false;
      }
      
      let selectedMonth = new Date(this.model.date);
      let selectedMonthAlreadyExists = this.existingBudgetMonths.some(x => {
          let existingBudgetDate = new Date(x.key);
          return existingBudgetDate.getUTCMonth() == selectedMonth.getUTCMonth()
                && existingBudgetDate.getUTCFullYear() == selectedMonth.getUTCFullYear();
      });
      //
      if (selectedMonthAlreadyExists) {
          this.monthPickerMessage = "Ya existe un presupuesto para el mes seleccionado.";
          return false;
      }

      return true;
  }

  clearMonthPickerError() {
      this.monthPickerMessage = "";
  }

  async createBudget() {
    let monthPickerIsValid = this.validateMonthPicker();
    console.log("Month picker is valid "+monthPickerIsValid)
    if (!monthPickerIsValid)
        return;
    //
    let formIsValid = await (this.$refs.formValidator as any).validate();
    console.log("form is valid "+monthPickerIsValid);
    if (!formIsValid)
        return;
    //
    const formattedDate = this.model.date.split('T')[0];
    this.model.date = formattedDate
    this.model.name = `Budget ${formattedDate}`
    this.model.subscription = this.subscription;
    if (this.selectedSourceBudget) this.model.created_from =  (this.selectedSourceBudget as any).uuid;
    if (this.template && this.template.length > 0) this.model.budget_template = this.template[0].uuid

    this.isLoading = true;
    this.budgetService
      .CreateUserBudget(this.model)
      .then((res: any) => {
        this.$toast.open({
          type: "success",
          message: "Operación exitosa!",
          position: "top-right",
        });
        //
        this.$store.dispatch("Budget/setActiveBudget", res.data.uuid);
        // this.$store.commit("Budget/SET_LAST_ACTIVE_BUDGET", res.data.uuid);
        this.$store.dispatch("Budget/budgetIsLoading");
        this.budgetService.GetUserBudgets()
            .then((response) => {
            this.$store.dispatch("Budget/budgetLoaded", response.data);
            this.$store.dispatch("Budget/hideNewBudgetModal");
            this.resetForm();
        });
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  closeModal() {
      this.$store.dispatch("Budget/hideNewBudgetModal");
  }

  resetForm() {
    this.model = { name: "", date: "", subscription: ""}
    this.selectedSourceBudget = null;
    (this.$refs.formValidator as any).reset();
  }
}
