
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import NotificationService from "@/services/NotificationService";
import { UserNotification } from "@/models/Notification/UserNotification";
import moment from "moment";

@Component({
  components: {},
})
export default class NotificationBadge extends Vue {
  @Prop({default: false}) showWhiteBell!: boolean; 
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Layout/getUserNotifications") userNotifications!: UserNotification[];
  notificationService = new NotificationService();

  get unreadNotificationsCount() {
    return this.userNotifications.filter(n => !n.leida).length;
  }

  get thereAreNotNotifications() {
    return this.userNotifications.length == 0;
  }

  get isThereNewNotifications() {
    return this.unreadNotificationsCount > 0;
  }

  mounted() {
    if (this.thereAreNotNotifications)
      this.loadNotifications();
  }

  loadNotifications() {
    return;
    this.notificationService
        .GetNotifications(this.user.id)
        .then((res) => {
          if (res.data.statusCode == 200) {
            let userNotifications = res.data.entity;
            this.$store.dispatch("Layout/notificationsLoaded", userNotifications);
          } else {
            this.$toast.open({type: "error", message:"Lo lamento, ocurrió un error obteniendo tus notificaciones.", position: "top-right"})
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({type: "error", message:"Lo lamento, ocurrió un error obteniendo tus notificaciones.", position: "top-right"})
        })
  }

  markAsRead(notification: UserNotification) {
    notification.leida = true;
    this.notificationService
        .MarkNotificationAsReaded(this.user.id, notification.id);
    
  }

  formatDate(date: string) {
    return moment(date).fromNow();
  }
}
