
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { Event } from "@/models/"
import { Getter } from "vuex-class";

@Component({})
export default class UpcomingEvent extends Vue {
  @Prop({ type: Event, default: () => ({})}) event!: Event;
  @Getter("Dashboard/isLoadingEventUrl") isLoadingEventUrl!: boolean;

  openUrl() {
    const el = document.getElementById(this.event.id) as HTMLAnchorElement;
    el.href = this.event.register_url
    el.click()
  }

  get eventHasRegistrationURL() {
    return this.event.register_url !== "" && this.event.register_url !== null
  }
 
  openRegistratonLink() {
    if (this.eventHasRegistrationURL) {
      this.openUrl();
    } else {
      this.$store.dispatch("Dashboard/getEventRegistrationURL", this.event.id);
    }
  }

  showModal() {
    this.$emit('showEventModal', this.event.id);
  }
}
