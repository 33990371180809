
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, CategoryType, CatalogCategory } from "@/models/Budget/BudgetCategory";
import SubCategoryEntry from "@/components/budget/SubCategoryEntry.vue";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import Swal from 'sweetalert2';
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
    components: {
        SubCategoryEntry
    }
})
export default class NewCategoryPopup extends Vue {
    budgetService = new BudgetService();
    @Prop({default: () => []}) skipCategories!: BudgetCategory[];
    @Getter("Budget/getCategoryCatalogByType") categories!: CatalogCategory[];
    @Getter("Budget/getCategoryCatalogBlank") blankCategories!: CatalogCategory[];
    @Getter("Budget/showNewCategoryModal") showNewCategoryModal!: boolean;
    @Getter('IdentityManagement/getUser') user!: UserModel;
    @Getter('Budget/getBudgetConfiguration') budgetConfiguration!: BudgetConfiguration;
    @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
    @Getter("Budget/getActiveBudget") activeUserBudget!: UserBudget;
    @Getter("Budget/getBudgetTypeTab") activeTab! : number;
    animateBlankCategory = false;
    animatedCategoryList: string[] = [];
    //
    get availableCategories() {
        let list = this.categories.filter(c => {
            return !this.skipCategories.some(budgetCategory => budgetCategory.uuid == c.uuid);
        });
        return list;
    }
    get activeUserBudgetIsDisabled() {
        return !this.activeUserBudget.is_active;
    }

    get typeOfBlankCategory() {
      if (this.activeTab == 0) return CategoryType.Expense
      return CategoryType.Income
    }

    get transactionTypeText() {
      if (this.activeTab == 0) return 'gasto'
      return 'ingreso'
    }

    addCategoryToBudget(category: CatalogCategory) {
        let budgetCategory = {} as BudgetCategory;
        let isValid = this.canAddMoreCategories();
        if (!isValid) {
            this.printNoMoreCategoryAlert();
            return;
        }
        //Animate category
        this.animatedCategoryList.push(category.uuid);
        setTimeout(() => {
            let index = this.animatedCategoryList.indexOf(category.uuid);
            this.animatedCategoryList.splice(index, 1);
        }, 800);
        this.closeModal();
        budgetCategory.alias = category.name;
        budgetCategory.category = category.uuid;
        this.$emit("onCategorySelected", budgetCategory);
    }

    animateCategory(category: BudgetCategory) {
        return this.animatedCategoryList.some(x => x == category.uuid);
    }
    addBlankCategoryToBudget() {
        let isValid = this.canAddMoreCategories();
        if (!isValid) {
            this.printNoMoreCategoryAlert();
            return;
        }
        //
        this.animateBlankCategory = true;
        setTimeout(() => {
            this.animateBlankCategory = false;
        }, 800);
        // let blankCategory = new BudgetCategory(categoryId,
        //                                         categoryId,
        //                                         "",
        //                                         this.typeOfBlankCategory,
        //                                         "",
        //                                         [firstSubCategory],
        //                                         getRandomColor(),
        //                                         "",
        //                                         "");

        let category = this.blankCategoryByBudgetType
        let blankCategory = { "alias": "Nueva categoría", "category": (category as any).uuid }  as BudgetCategory
        // this.closeModal();
        this.$emit("onCategorySelected", blankCategory);
    }

    get blankCategoryByBudgetType() {
        if (this.blankCategories) return this.blankCategories.find((b: any) => b.transaction_type == this.typeOfBlankCategory)
        return ''
    }

    openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();

      const activeTab = this.activeTab == 1 ? 'profile-tab' : 'home-tab';
      const el = document.getElementById(activeTab);
      el?.click();
    }
    
    openModal() {
        if (this.activeUserBudgetIsDisabled) {
            Swal.fire({
                titleText: 'Aviso',
                text: 'No puedes agregar más categorias a un presupuesto archivado.',
                icon: 'warning',
                confirmButtonText: 'De acuerdo'
            })
            return;
        }
        this.$store.dispatch("Budget/showNewCategoryModal");
    }
    closeModal() {
        this.$store.dispatch("Budget/hideNewCategoryModal");
    }
    

    canAddMoreCategories(): boolean {
        return true
        let maxCategorias = this.budgetConfiguration.maxCategorias;

        return this.budgetCategories.length < maxCategorias;
    }

    printNoMoreCategoryAlert() {
        Swal.fire({
            titleText: 'Aviso',
            text: 'Por el momento el limite de categorias que puedes agregar es de '+this.budgetConfiguration.maxCategorias,
            icon: 'warning',
            confirmButtonText: 'De acuerdo'
        })
    }
}
