var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.survey)?_c('div',{staticClass:"d-flex justify-content-center survey-container"},[_c('div',{staticClass:"skip-container"},[(_vm.hasAccessToDashboard)?_c('RouterLink',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Dashboard' },"href":"#"}},[_c('img',{attrs:{"src":require("../assets/img/svg/x-button.svg")}}),_vm._v(" Saltar ")]):_c('RouterLink',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'Presupuesto' },"href":"#"}},[_c('img',{attrs:{"src":require("../assets/img/svg/x-button.svg")}}),_vm._v(" Saltar ")])],1),_c('div',{staticClass:"wizard d-flex flex-column justify-content-center"},[_c('ul',{staticClass:"steps hide-on-mobile"},[_vm._m(0),_vm._l((_vm.preguntasEncuesta),function(pregunta,index){return _c('li',{key:index,class:{'step': true, 'step-success': index <= _vm.activeQuestionIndex }},[_vm._m(1,true)])})],2),_c('div',{staticClass:"wizard-content mt-3"},_vm._l((_vm.preguntasEncuesta),function(pregunta,index){return _c('div',{key:index,class:{'wizard-step d-flex flex-column animate__animated animate__faster': true, 
                            'active': index == _vm.activeQuestionIndex,
                            'animate__fadeOutLeft': index == _vm.fadeOutLeftAnimationIndex,
                            'animate__fadeOutRight': index == _vm.fadeOutRightAnimationIndex,
                            'animate__fadeInRight': index == _vm.fadeInRightAnimationIndex,
                            'animate__fadeInLeft': index == _vm.fadeInLeftAnimationIndex }},[_c('h3',{staticClass:"step-title"},[_vm._v(_vm._s(pregunta.pregunta))]),_c('h4',{staticClass:"step-subtitle"},[_vm._v("Elige tanto como sea posible")]),_c('div',{staticClass:"step-content"},[_c('div',{staticClass:"cards-container"},_vm._l((pregunta.opciones),function(opcion,j){return _c('div',{key:j,staticClass:"myflex-col"},[_c('div',{class:{'active': opcion.valor, 'card-button d-flex flex-column justify-content-center align-items-center': true},on:{"click":function($event){return _vm.selectOrUnselectOption(opcion)}}},[(opcion.urlIcono)?_c('img',{attrs:{"src":opcion.urlIcono}}):_c('img',{attrs:{"src":require("../assets/img/svg/borrow.svg")}}),_c('p',[_vm._v(_vm._s(opcion.descripcion))])])])}),0)]),_c('div',{staticClass:"step-footer"},[_c('LoadingButton',{attrs:{"loading":_vm.isLoading,"smallSprinner":true,"className":'btn caudall-rounded-btn blue hover-dark-blue'},nativeOn:{"click":function($event){return _vm.moveNextOrSubmit.apply(null, arguments)}}},[_vm._v("Siguente")]),(index > 0)?_c('button',{staticClass:"btn caudall-rounded-btn text-blue-caudall",on:{"click":_vm.movePrevious}},[_vm._v("Regresar")]):_vm._e()],1)])}),0)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"step step-success ml-0"},[_c('div',{staticClass:"step-content"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-content"},[_c('span',{staticClass:"step-circle"})])
}]

export { render, staticRenderFns }