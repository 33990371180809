
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
  components: {},
})
export default class EnrollmentCategoryModalTrigger extends Vue {
  @Prop({ default: false }) buttonOnly!: boolean;
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
  @Getter("Budget/getEnrollmentCategoriesLoaded") enrollmentCategoriesHasEntries!: boolean;

  get showNeedFillingMessage() {
    //if budget already loaded
    //and there are enrollmentCategories
    return !this.budgetIsLoading 
            && this.enrollmentCategoriesHasEntries
            && this.userBudgets.length == 1;
  }

  callModalShow() {
    this.$store.dispatch("Budget/showEnrollmentCategoriesModal");
  }
}
