
import { Component, Vue } from "vue-property-decorator";
import LoginModel from "@/models/IdentityManagement/LoginModel";
import LoadingButton from "@/components/LoadingButton.vue";
import IdentityManagementService from "@/services/IdentityManagementService";
import { Auth } from 'aws-amplify';

@Component({
    components: {
        LoadingButton
    }
})
export default class PasswordRecovery extends Vue {
    identityManagementService = new IdentityManagementService();
    userEmail = "";
    isLoading = false;
    showNotFoundError = false;
    mounted() {
        
    }

    async onSubmit() {
        let userEmail = this.userEmail;
        this.isLoading = true;
        this.showNotFoundError = false;
        try {
            let result = await Auth.forgotPassword(userEmail);
            console.log("Result ", result);
            this.$router.push({ path: "/password-recovery-2", query: { e: userEmail}});
        } catch(err) {
            console.log("Error on ForgotPassword", err);
        } finally {
            this.isLoading = false;
        }

        // this.identityManagementService.RecoverPassword(userEmail)
        // .then((res) => {
        //     if (res.data.statusCode != 200) {
        //         this.showNotFoundError = true;
        //     } else {
        //         this.$store.dispatch("IdentityManagement/passwordRecoveryStep1", userEmail);
        //         this.$router.push("/password-recovery-2");
        //     }
        // })
        // .catch((err) => {
        //     console.error(err);
        // })
        // .finally(() => {
        //     this.isLoading = false;
        // })
    }

}
