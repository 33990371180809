<template>
  <b-container class="mt-3 roboto" fluid :style="{ backgroundColor: bkgColor}" style="borderRadius: 10px;">
    <b-row class="px-3 py-3">
      <b-col class="p-0" cols="8">
        <p style="fontSize: 0.8em;" :style="{ color: this.textColor }" class="mb-0"><b>{{ this.title }}</b></p>
        <p style="fontSize: 0.8em;" :style="{ color: this.textColor }" class="mb-0">{{ this.subtitle }}</p>
      </b-col>
      <b-col class="p-0" cols="4" >
        <div class="ml-auto excep" :class="this.extraClass" :style="{ backgroundColor: icnColor }" style="borderRadius: 10px; textAlign: center; width: 2.5em; height: 2.5em;">
          <!-- <img src="@/assets/img/svg/wallet.svg" style="width: 20px;"> -->
          <img :src="imgUrl" :class="this.extraClass" class="inside-img" style="width: 20px;">
        </div>
      </b-col>
    </b-row>

    <b-row class="px-3 pt-0 pb-3">
      <b-col class="p-0" cols="7">
        <p style="fontSize: 0.8em;" :style="{ color: icnColor }" class="mb-0"><b>{{ id !== 'loan' ? 'DOP' : '' }} {{ firstAmount }}</b></p>
      </b-col>
      <b-col class="p-0" cols="5" style="textAlign:right;">
        <p style="fontSize: 0.7em;" :style="{ color: this.textColor }" class="ml-auto mb-0">{{ this.textPesos }}</p>
      </b-col>
    </b-row>

    <b-row class="" style="backgroundColor: #eaf4fe;">
      <b-col cols="11" class="mx-auto" style="border-top: 1px solid #80808045;"></b-col>
    </b-row>

    <b-row class="px-3 py-2">
      <b-col class="p-0" cols="7">
        <p style="fontSize: 0.8em;" :style="{ color: icnColor }" class="mb-0"><b>USD {{ secondAmount }}</b></p>
      </b-col>
      <b-col class="p-0" cols="5" >
        <p style="fontSize: 0.7em; textAlign:right;" :style="{ color: this.textColor }" class="ml-auto mb-0">{{ this.textDollars }}</p>
      </b-col>
    </b-row>

    <b-row class="" style="backgroundColor: #eaf4fe;">
      <b-col cols="11" class="mx-auto" style="border-top: 1px solid #80808045;"></b-col>
    </b-row>

    <b-row class="px-3 py-2">
      <b-col class="p-0" cols="10">
        <p style="fontSize: 0.8em;" :style="{ color: this.textColor }" class="mb-0">
          <a :href="this.scrollLink">Ir a detalle</a>
        </p>
      </b-col>
      <b-col class="p-0" cols="2" style="textAlign:right;">
        <a :href="this.scrollLink">
          <img style="width: 1.5em; transform: rotate(90deg); fill: red; color: red" src="@/assets/img/svg/chevron-right.svg" alt="">
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    imgUrl() {
      return require("@/assets/img/svg/" + this.icon + ".svg")
    }
  },
	methods: {
		changeBkgOnHover(e) {
			if (!e.target.classList || e.target.classList[0] != 'dashboard-card-container-unit') {
				return;
			}

			if (e.type === 'mouseover') {
				e.target.style.backgroundColor = this.hoverColor;
			} else {
				e.target.style.backgroundColor = this.bkgColor;
			}
		}
	},
	props: ['id', 'bkg-color', 'icn-color', 'title', 'subtitle',
         'text-dollars', 'text-pesos', 'hover-color','icon','text-color',
        'scroll-link', 'extra-class', 'first-amount', 'second-amount']
}
</script>

<style scoped>
.dashboard-card-container-unit {
  padding: 30px 20px;
  border-radius: 12px;
  width: 25%;
  /* min-width: 200px; */
  margin-left: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.hovered {
	background-color: red;
}
.dashboard-card-container-unit:nth-child(1) {
  margin-left: 0px;
}
.dashboard-card-container-unit:nth-child(1):hover {
  background-color: #0F559B66;
}
.dashboard-card-container-unit-container-icon {
	margin-bottom: 25px;
}
.dashboard-card-container-unit:nth-child(4) > div:nth-child(1) {
  margin-bottom: 5px;
}
.dashboard-card-container-unit-icon {
  width: 40px;
  height: 40px; 
  border-radius:12px; 
}
.excep {
  padding: 0.5rem;
}
.excep.third {
  padding: 0 !important;
}
.dashboard-card-container-unit-desc > p:nth-child(1) {
  font-size: 11px;
  font-weight: 500;
}
.dashboard-card-container-unit-desc > p:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
}
.dashboard-card-container-unit-desc > p:nth-child(3) {
  font-size: 13px;
  font-weight: 400;
}
.dashboard-card-container-unit-desc > p:nth-child(5) {
  font-size: 16px;
  font-weight: 600;
}
.dashboard-card-container-unit-desc > p:nth-child(6) {
  font-size: 12px;
}
.view-detail {
  font-size: 13px; 
  margin: 20px 0 0 0;
}
.view-detail > p {
  margin: 0;
}
.dashboard-card-icon {
  width: 22px;
}
</style>