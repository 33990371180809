
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, BudgetSubCategory, CategoryType } from "@/models/Budget/BudgetCategory";
import DistributionSummaryCategoryEntry from "@/components/budget/DistributionSummaryCategoryEntry.vue";

@Component({
    components: {
        DistributionSummaryCategoryEntry
    }
})
export default class DistributionSummary extends Vue {
    @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
    @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
    
}
