var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"add-new-transaction-modal","uk-modal":""}},[_c('div',{staticClass:"uk-modal-dialog uk-modal-body"},[_c('button',{staticClass:"uk-modal-close-default",staticStyle:{"background-color":"#F6F6F6","border-radius":"50%"},attrs:{"type":"button","uk-close":""}}),_c('p',{staticClass:"fs-10 mb-1",staticStyle:{"fontWeight":"bold"}},[_vm._v("Añadir una nueva transacción")]),_c('p',{staticClass:"mb-3",staticStyle:{"fontSize":"0.7em","fontWeight":"400","color":"#B5B5B5"}},[_vm._v("Completa el formulario para registrar algún gasto o ingreso")]),_vm._m(0),_c('ul',{staticClass:"uk-switcher"},[_c('li',[_c('form',{staticClass:"uk-grid"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"uk-width-1-1 uk-width-1-2@m uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Valor del gasto")]),_c('money',_vm._b({staticClass:"uk-input align-right",staticStyle:{"height":"30px","border-radius":"4px","font-size":"0.7em"},attrs:{"placeholder":"0.00","value":""}},'money',_vm.money,false))],1),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('li',[_c('form',{staticClass:"uk-grid"},[_c('div',{staticClass:"uk-width-1-1 uk-width-1-2@m uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Valor del ingreso")]),_c('money',_vm._b({staticClass:"uk-input align-right",staticStyle:{"height":"30px","border-radius":"4px","font-size":"0.7em"},attrs:{"placeholder":"0.00","value":""}},'money',_vm.money,false))],1),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"transaction-modal-tab",attrs:{"uk-tab":""}},[_c('li',[_c('a',{staticStyle:{"text-transform":"none"},attrs:{"href":"#","id":"expense"}},[_vm._v("Registrar gasto")])]),_c('li',[_c('a',{staticStyle:{"text-transform":"none"},attrs:{"href":"#"}},[_vm._v("Registrar ingreso")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("¿En qué gastaste dinero?")]),_c('input',{staticClass:"uk-input",attrs:{"type":"text","placeholder":"Ej: Pago Spotify, Boleta del cine, Taxi, etc","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Tipo de gasto")]),_c('input',{staticClass:"uk-input",attrs:{"type":"text","placeholder":"Comida, entretenimiento, etc...","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-width-1-2@m uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Fecha del gasto")]),_c('input',{staticClass:"uk-input",attrs:{"type":"date","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("¿De donde consumiste ese gasto?")]),_c('select',{staticClass:"uk-select bank-account-select",attrs:{"placeholder":"Seleccionar tipo"}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Tarjeta de crédito, cuenta de banco, etc")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Corrientes")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Empresarial")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 ml-auto"},[_c('button',{staticStyle:{"backgroundColor":"white","color":"#0F559B","border":"1px solid #0F559B","border-radius":"16px","fontSize":"0.7em","padding":"3px 15px"}},[_vm._v("Guardar y añadir otra")]),_c('button',{staticClass:"ml-2",staticStyle:{"backgroundColor":"#0F559B","color":"white","border":"1px solid #0F559B","border-radius":"16px","fontSize":"0.7em","padding":"3px 15px"}},[_vm._v("Guardar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-width-1-2@m uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Fecha del ingreso")]),_c('input',{staticClass:"uk-input",attrs:{"type":"date","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Tipo de ingreso")]),_c('input',{staticClass:"uk-input",attrs:{"type":"text","placeholder":"Comida, entretenimiento, etc...","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Descripción o motivo del ingreso")]),_c('input',{staticClass:"uk-input",attrs:{"type":"text","placeholder":"Ej: Venta carro Veloster 2020","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uk-width-1-1 uk-form-controls"},[_c('label',{staticClass:"uk-form-label"},[_vm._v("Destino del ingreso")]),_c('select',{staticClass:"uk-select bank-account-select"},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v("Seleccionar producto (Cuenta de ahorro, cuenta corriente, etc)")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Corrientes")]),_c('option',{attrs:{"value":"3"}},[_vm._v("Empresarial")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 ml-auto"},[_c('button',{staticStyle:{"backgroundColor":"white","color":"#0F559B","border":"1px solid #0F559B","border-radius":"16px","fontSize":"0.7em","padding":"3px 15px"}},[_vm._v("Guardar y añadir otra")]),_c('button',{staticClass:"ml-2",staticStyle:{"backgroundColor":"#0F559B","color":"white","border":"1px solid #0F559B","border-radius":"16px","fontSize":"0.7em","padding":"3px 15px"}},[_vm._v("Guardar")])])
}]

export { render, staticRenderFns }