
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import BudgetService from "@/services/BudgetService";
import { Getter } from "vuex-class";
import { BankAccount as BankAccountCatalog } from '@/models/UserCatalog/UserCatalog'
import CarouselArrows from '@/components/helpers/carousel-arrows.vue'
import EmptyState from '@/components/helpers/emptyState.vue'

import BankAccountCard from "@/components/assetsLiabilities/bankAccounts/BankAccountCard.vue";
import UserModel from "@/models/IdentityManagement/UserModel";
import AddNewTransactionModal from "@/components/assetsLiabilities/assetsTransactions/AddNewTransaction.vue";
import { ContainerComponent } from '@/components/assetsLiabilities/mixins/index';
import UIkit from 'uikit';
import { AccountTypeSubList, Bank, Currency } from "@/models";
import { isBigNumber } from "@/utils/HelperMethods";

// type accountTypeFilter = string | AccountTypeSubList
type accountSummaryData = {
  type: string,
  currency: string,
  title: string,
  denom: string,
  id?: string
}

@Component({
  components: {
    BankAccountCard,
    CarouselArrows,
    AddNewTransactionModal,
    EmptyState
  }
})
export default class BankAccount extends Mixins(ContainerComponent) {
  budgetService = new BudgetService();
  @Prop() bankName!: string;
  @Prop() denomination!: string;
  @Prop() accountName!: string; 
  @Prop() accountType!: string; 
  @Prop() remainingBalance!: string;
  @Prop() isDesktopViewport!: boolean;
  @Getter('Asset/getUserBankAccounts') bankAccounts! : BankAccountCatalog[];
  @Getter('Asset/getBankSubTypes') bankAccountSubTypes! : AccountTypeSubList[];
  @Getter('Asset/getBanks') banks! : Bank[];
  @Getter('Asset/getDefaultCurrency') defaultCurrency! : Currency;
  @Getter('Asset/getSelectedAccount') selectedCard! : String;
  @Getter("IdentityManagement/getUser") user!: UserModel;
  loadedBankAccounts = false;
  allSelectedType : AccountTypeSubList = { name: 'all', uuid: "placeholder-uuid" }
  selectedType = this.allSelectedType;
  bankAccountsCardData = [
    {
      type: 'ahorro',
      currency: 'dop',
      title: 'Total en cuentas de ahorros',
      denom: 'Pesos Dominicanos'
    },
    {
      type: 'ahorro',
      currency: 'usd',
      title: 'Total en cuentas de ahorros',
      denom: 'Dólares Americanos'
    },
    {
      type: 'corriente',
      currency: 'dop',
      title: 'Total en cuentas corrientes',
      denom: 'Pesos Dominicanos'
    },
    {
      type: 'corriente',
      currency: 'usd',
      title: 'Total en cuentas corrientes',
      denom: 'Dólares Americanos'
    },
    {
      type: 'nomina',
      currency: 'dop',
      title: 'Total en cuentas nómina',
      denom: 'Pesos Dominicanos'
    },
    {
      type: 'nomina',
      currency: 'usd',
      title: 'Total en cuentas nómina',
      denom: 'Dólares Americanos'
    },
  ] as accountSummaryData[]

  mounted() {
    if (this.bankAccounts) {
      this.loadedBankAccounts = true;
    }
  }

  isBigNumber = isBigNumber

  formatAmount(amount: number | string) {
    if (typeof amount == "string") amount = parseFloat(amount)

    if (this.defaultCurrency) {
      return ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
    }
    return ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
  }

  getBankName(bank: Bank, property: keyof Bank = 'short_name') {
    return bank[property];
  }

  getAccountSubType(subType: AccountTypeSubList) {
    return subType.name;
  }

  selectCard(idCuenta: string) {
    const bankAccount = this.bankAccounts.find((b: any) => b.uuid == idCuenta);
    this.loadTransactions(idCuenta, 'accounts');
    this.selectedAccountCurrency((bankAccount as any).currency.code);
  }

  @Watch("bankAccounts")
  changeCardComponent() {
    this.loadedBankAccounts = true
  }
  deleteSelectedAccount(deleteId: string) {
    this.$store.dispatch('Asset/deleteBankAccount', { userId: this.user.id, bankAccountId: deleteId});
  }
  get filteredBankAccounts() {
      if (this.selectedType.name == 'all') {
        return this.bankAccounts;
      }
      return this.bankAccounts.filter(item => (item.sub_type as any).uuid == this.selectedType.uuid);
    }

  get filteredBankAccountsCardData() {
      if (this.selectedType.name == 'all') {
        return this.bankAccountsCardData;
      }
      return this.bankAccountsCardData.filter(item => item.type.toLowerCase() == this.selectedType.name.toLowerCase());
    }

  filterCardType(text: string) {
      if (text === 'all') this.selectedType = this.allSelectedType;
      else {
        let option = this.bankAccountSubTypes.find(t => t.uuid === text)
        if (option) this.selectedType = option
      }
  }

  getAccountSummary(type: string, currency: string, formatted = true) {
    const subTypeUUID = this.bankAccountSubTypes.find(ba => ba.name.toLowerCase() == type.toLowerCase())?.uuid;
    var flatArray = this.bankAccounts.filter(bankAccount => 
      (bankAccount.sub_type as any).uuid == subTypeUUID && (bankAccount.currency as any).code.toLowerCase() == currency.toLowerCase())
      .map(i => +i.balance)
    
    if (flatArray.length == 0) return '0.00'
    let result = flatArray.reduce((a,  b) => a + b);

    if (formatted) return result.toLocaleString('en-US', {minimumFractionDigits: 2})
    return result
  }
  
  bankAccountsTypeLength(type: string) {
      if (type == 'all') {
        return this.bankAccounts.length;
      }
      return this.bankAccounts.filter(item => (item.sub_type as any).uuid == type).length;
  }
  
  openEditAccountModal(editingId: string) {
    let editingBankAccount = this.bankAccounts.filter(bankAccount => bankAccount.uuid == editingId)[0];
    this.$root.$emit('editBankAccount', editingBankAccount);
    UIkit.modal(document.getElementById('bank-account-modal')).show()
  }

  createNewBankAccount() {
    this.$root.$emit('clearBankAccount');
    UIkit.modal(document.getElementById('bank-account-modal')).show()
  }
  
  hideEditAccountModal() {
    UIkit.modal('bank-account-modal').hide()
  }
}
