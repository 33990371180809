
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import {
  BudgetCategory,
  BudgetSubCategory, CategoryType,
} from "@/models/Budget/BudgetCategory";
import { CurrencyInput, setValue } from "vue-currency-input";
import BudgetTransaction, {
  TransactionType,
} from "@/models/Budget/BudgetTransaction";
import { Getter } from "vuex-class";
import Multiselect from "vue-multiselect";
import { extend } from "vee-validate";
import { max } from "vee-validate/dist/rules";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import { ToastOptions } from "vue-toast-notification";
import UserBudget from "@/models/Budget/UserBudget";
import { Currency, TransactionRedirection } from '@/models/Common/Common';
import { BankAccount, CreditCard, ServiceToPay } from "@/models/UserCatalog/UserCatalog";
import { ExpenseTransactionAccount, AccountType, Bank, AccountTypeSubList, TransactionAccount } from "@/models/Catalog/Catalog";
import UIkit from 'uikit';
import { CurrencyCode } from "@/components/assetsLiabilities/mixins";

type ExpenseFormatAccount = BankAccount | CreditCard;

extend("moreThan0", {
  validate: (value) => {
    return value > 0;
  },
  message: "El valor debe de ser mayor a 0",
});
extend("max", {
  ...max,
  message: "Esta campo excede el limite máximo de {length} caracteres",
});

@Component({
  components: {
    CurrencyInput,
    Multiselect,
  },
})
export default class NewTransactionModal extends Mixins(CurrencyCode) {
  budgetService = new BudgetService();
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/getUserBudgets") userBudgets!: UserBudget[];
  @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("Budget/getIncomeTransactionAccounts") userIncomeAccounts!: BankAccount[];
  @Getter("Budget/getExpenseTransactionAccounts") userExpenseAccounts!: ExpenseFormatAccount[];
  @Getter("Budget/getPaymentTransactionAccounts") userPaymentAccounts!: CreditCard[];
  @Getter("Budget/getResetBudgetTransactionModal") resetModal!: boolean;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Budget/getServiceToPay") serviceToPay!: string;
  @Getter("Asset/getAccountTypes") accountTypes!: AccountType[];
  @Getter("Asset/getDOPCurrency") DOPCurrency!: string;
  @Getter("Asset/getUSDCurrency") USDCurrency!: string;
	@Getter("Asset/getBanks") bankList!: Bank[];
	@Getter("Asset/getBankSubTypes") bankAccountTypes!: AccountTypeSubList[];
	@Getter("Asset/getUserCreditCards") creditCards!: CreditCard[];
  @Prop({ default: 'transactionModal' }) modalIdName!: string;

  incomeModel = { type: TransactionType.Income } as BudgetTransaction;
  expenseModel = { type: TransactionType.Expense } as BudgetTransaction;
  paymentModel = { type: TransactionType.Payment } as BudgetTransaction;
  selectedExpenseOrigin: BudgetSubCategory | null = null;
  selectedIncomeOrigin: BudgetSubCategory | null = null;
  selectedPaymentOrigin: CreditCard | null = null;
  selectedBankAccount: BankAccount | null = null ;
  selectedPaymentAccount: TransactionAccount | null = null ;
  selectedExpenseAccount: TransactionAccount | null = null;
  expenseSubcategoryCurrency = {} as Currency
  incomeSubcategoryCurrency = {} as Currency
  rate = 1
  isLoading = false;
  activeTab = 0;
  datePickerDateFormOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  selectLabel = 'Presiona enter para seleccionar'
  deselectLabel = 'Presiona enter para remover'

  // Provisional
  selectedCreditCard = {} as CreditCard;
  selectedPaymentCurrency = 'DOP';

  get defaultCurrencyOption() {
    if (this.defaultCurrency && this.defaultCurrency.code) {
      return this.defaultCurrency.code.toLowerCase()
    }
    return ''
  }

  get creditCardType() {
    if (this.accountTypes) return this.accountTypes.filter(at => at.code.toLowerCase() == 'tc')[0].uuid
  }

  get bankAccountType() {
    if (this.accountTypes) return this.accountTypes.filter(at => at.code.toLowerCase() == 'cb')[0].uuid
  }

  get expenseTransactionAccounts() {
    if (this.userExpenseAccounts && this.bankList) {
      return this.userExpenseAccounts.map(ac => {
				if (ac.type == this.creditCardType) return this.formatCreditCardExpenseAccount(ac)
				return this.formatBankExpenseAccount(ac)
			})
    }
    return []
  }

  get showRateChange() {
    if (this.activeTab == 0) {
      if (this.expenseModel && this.expenseModel.currency && this.selectedExpenseAccount && this.selectedExpenseAccount.currency) {
        return this.expenseSubcategoryCurrency.code.toUpperCase() != (this.selectedExpenseAccount.currency as any).code.toUpperCase()
      }
    }
    else if (this.activeTab == 1) {
      if (this.incomeModel && this.incomeModel.currency && this.selectedBankAccount && this.selectedBankAccount.currency) {
        return this.incomeSubcategoryCurrency.code.toUpperCase() != (this.selectedBankAccount.currency as any).code.toUpperCase()
      }
    }
    else if (this.activeTab == 2) {
      if (this.selectedPaymentOrigin && (this.selectedPaymentOrigin as any).currency && this.selectedPaymentAccount && this.selectedPaymentAccount.currency) {
        return (this.selectedPaymentOrigin as any).currency.code.toUpperCase() != (this.selectedPaymentAccount.currency as any).code.toUpperCase()
      }
    }
  }

  @Watch("showRateChange")
  resetRateOnChange(value: boolean) {
    if (!value) this.rate = 1;
  }

  @Watch("selectedExpenseOrigin")
  setSubcategoryCurrency(value: BudgetSubCategory) {
    if (value && value.currency) this.expenseSubcategoryCurrency = {...value.currency}
    else this.expenseSubcategoryCurrency = {...this.defaultCurrency}
  }

  @Watch("selectedIncomeOrigin")
  setSubcategoryCurrencyIncome(value: BudgetSubCategory) {
    if (value && value.currency) this.incomeSubcategoryCurrency = {...value.currency}
    else this.incomeSubcategoryCurrency = {...this.defaultCurrency}
  }

	formatCreditCardExpenseAccount(expenseAccount: any) {
		let currency = expenseAccount.balances[0].currency
		let bank = this.bankList.filter(b => b.uuid == expenseAccount.bank.uuid)[0].name
		let dopBalance = expenseAccount.balances.filter((b: any) => b.currency == this.DOPCurrency)
		let usdBalance = expenseAccount.balances.filter((b: any) => b.currency != this.DOPCurrency)

		return {
			account_no: expenseAccount.account_no,
			currency: expenseAccount.multi_currency ? 'Doble' : currency == this.DOPCurrency ? 'DOP' : 'USD',
			currencyId: currency,
			bank: bank,
			bankId: expenseAccount.bank,
			balanceDOP: dopBalance.length ? dopBalance[0].updated_used : 0,
			balanceUSD: usdBalance.length ? usdBalance[0].updated_used : 0,
			description: '',
			type: 'tc',
			uuid: expenseAccount.uuid
		} as ExpenseTransactionAccount
	}

	formatBankExpenseAccount(expenseAccount: any) {
		let bank = this.bankList.filter(b => b.uuid == expenseAccount.bank.uuid)[0].name;

		return {
			account_no: expenseAccount.account_no,
			currency: expenseAccount.currency == this.DOPCurrency ? 'dop' : 'usd',
			currencyId: expenseAccount.currency,
			bank: bank,
			bankId: expenseAccount.bank,
			balanceDOP: expenseAccount.currency == this.DOPCurrency ? expenseAccount.balance : 0,
			balanceUSD: expenseAccount.currency != this.DOPCurrency ? expenseAccount.balance : 0,
			description: expenseAccount.account_no,
			type: 'cb',
			uuid: expenseAccount.uuid
		} as ExpenseTransactionAccount
	}

  openBankAccountModal() {
    this.$root.$emit('clearBankAccount');
    const closeBttn = document.getElementById("new-transaction-close-bttn");
    closeBttn?.click();
    UIkit.modal(document.getElementById('bank-account-modal')).show()
    const to = this.activeTab == 1 ? 'profile-tab' : 'payment-tab';
    this.$store.dispatch("Dashboard/setProductCallFromDashboard", { redirect: true, to: to} as TransactionRedirection );
  }

  openSelectExpenseProduct() {
    const closeBttn = document.getElementById("new-transaction-close-bttn");
    closeBttn?.click();
    ($('#selectProductModal') as any).modal();
  }
  
  @Watch("resetModal")
  resetModalState(value: boolean) {
    if (value === true) {
      this.resetExpenseForm();
      this.resetIncomeForm();
      this.resetPaymentForm();
      this.$store.dispatch("Budget/toggleTransactionModelResetState", false);
      this.$store.dispatch('Budget/setServiceToPay', '');
      this.refreshTransactionAccounts();
    }
  }

  @Watch("serviceToPay")
  selectExpenseSubcategory(service: ServiceToPay) {
    if (service && service.uuid !== '') {
      this.selectedExpenseOrigin = this.expenseSubcategoriesOfSelectedBudget.filter((s: BudgetSubCategory) => s.external_id === service.uuid)[0];
    }
    if (service.amount) {
      let amt = typeof service.amount == 'string' ? parseFloat(service.amount) : service.amount;
      if (amt > 0) {
        setValue((this.$refs.expenseCurrency as any), amt)
      }
    }
    if (service.currency) this.expenseModel.currency = this.currencies.filter(c => c.uuid == service.currency)[0]
  }

  assignServiceExpenseAmount(amount: number) {
    let amt = typeof amount == 'string' ? parseFloat(amount) : amount;
    if (amt > 0) {
      setValue((this.$refs.expenseCurrency as any), amt)
    }
  }

  created() {
    this.refreshTransactionAccounts()
  }

  refreshTransactionAccounts() {
    let transactionTypes = ['i', 'g', 'p'];
    transactionTypes.forEach(t => this.$store.dispatch('Budget/setTransactionAccount', t))
  }

  get filteredIncomeAccounts() {
    return this.userIncomeAccounts
  }

  get filteredExpenseAccounts() {
    return this.userExpenseAccounts;
  }

  get filteredPaymentAccounts() {
    return this.userPaymentAccounts;
  }

  subCategoryLabel(subCategory: BudgetSubCategory) {
      return subCategory.alias;
  }

  get activeBudgetDateFormatted() {
    if (this.activeBudget && this.activeBudget.date) {
      return this.activeBudget.date.substring(0,7)
    }
    return ''
  }

  get userCurrentMonthBudget() {
    if (this.activeTab == 0 && this.expenseModel.date) {
      let date = this.expenseModel.date.substring(0,7);
      return date === this.activeBudgetDateFormatted;
    } else if (this.activeTab == 1 && this.incomeModel.date) {
      let date = this.incomeModel.date.substring(0,7);
      return date === this.activeBudgetDateFormatted;
    } else if (this.activeTab == 2 && this.paymentModel.date) {
      let date = this.paymentModel.date.substring(0,7);
      return date === this.activeBudgetDateFormatted;
    } 
    return true
  }

  setActiveTab(tab: number) {
    this.activeTab = tab;

    if (tab === 0) {
      this.resetIncomeForm(true);
    } else if (tab === 1) {
      this.resetExpenseForm(true);
    } else {
      this.resetPaymentForm(true);
    }

  }

  getBankAccountOptions(bankAccount: TransactionAccount) {
      return `${bankAccount.account_no} Cta. ${bankAccount.currency.code.toUpperCase()}, ${bankAccount.bank.name}`;
  }

  getCreditCardOptions(creditCard: TransactionAccount) {
    return `${creditCard.account_no} Tjta. ${creditCard.currency.code.toUpperCase()}, ${creditCard.bank.name}`;
  }
  
  getExpenseAccountOptions(expenseTransaction: TransactionAccount) {
    return `${expenseTransaction.account_no} ${expenseTransaction.type.code.toLowerCase() == 'cb' ? "Cta." : "Tjta."} ${expenseTransaction.currency.code.toUpperCase()}, ${expenseTransaction.bank.name}`
  }

  get getOlderUserBudgetDate() {
    let fecha = new Date(this.activeBudget?.date) ?? "";
    return new Date(fecha.getFullYear(), fecha.getMonth(), 1);
  }

  get getBudgetLastDate() {
    return new Date();
  }

  get expenseSubcategoriesOfSelectedBudget(): BudgetSubCategory[] {
    if (!this.activeBudget) {
      return [] as BudgetSubCategory[];
    }
    let subcategories = this.activeBudget.categories
          .filter(c => typeof c.type == 'object' ? c.type.code.toUpperCase() == CategoryType.Expense : c.type?.toUpperCase() == CategoryType.Expense)
          .flatMap(x => x.subcategories)
          .filter(sub => typeof sub.budgeted == 'string' ? parseFloat(sub.budgeted) !== 0 : sub.budgeted !== 0)
    return subcategories;
  }

  get incomeSubcategoriesOfSelectedBudget(): BudgetSubCategory[] {
    if (!this.activeBudget) {
      return [] as BudgetSubCategory[];
    }
    let subcategories = this.activeBudget.categories
          .filter(c => typeof c.type == 'object' ? c.type.code.toUpperCase() == CategoryType.Income : c.type?.toUpperCase() == CategoryType.Income)
          .flatMap(x => x.subcategories)
          .filter(sub => typeof sub.budgeted == 'string' ? parseFloat(sub.budgeted) !== 0 : sub.budgeted !== 0)
    return subcategories;
  }

  get remainingIncomeToExpense(): number {
    if (!this.selectedExpenseAccount) {
      return 0;
    }
    return this.selectedExpenseAccount.available_amt
  }

  get dualAccountLimit() {
    if (!this.selectedExpenseAccount || !this.defaultCurrency) {
      return 0;
    }
    return 0
    // if (this.defaultCurrency.code.toLowerCase() === 'dop') return this.selectedExpenseAccount.balanceDOP ? this.selectedExpenseAccount.balanceDOP : 0;
    // else return this.selectedExpenseAccount.balanceUSD ? this.selectedExpenseAccount.balanceUSD : 0;
  }

  get remainingIncomeToExpenseFormatted() {
    let value = 0
    if (this.remainingIncomeToExpense) value = this.remainingIncomeToExpense;
    return value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get remainingIncomeForPaymentFormatted() {
    return this.remainingIncomeForPayment.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get userExceedRemainingIncome() {
    let transactionAmount = (this.expenseModel.amt * this.rate)

    if (this.selectedExpenseAccount && this.selectedExpenseAccount.currency && 
        this.expenseModel && this.expenseModel.currency) {
          if ((this.expenseModel.currency as any).code.toUpperCase() == 'DOP' && 
          this.selectedExpenseAccount.currency.code.toUpperCase() == 'USD') {
            transactionAmount = this.expenseModel.amt / this.rate
          }
        }

    let res = this.remainingIncomeToExpense - transactionAmount;
    return res < 0;
  }

  get remainingIncomeForPayment() {
    if (this.selectedPaymentAccount) {
      return this.selectedPaymentAccount.available_amt
    }
    return 0
  }

  get userExceedRemainingIncomeForPayment() {
    let res = this.remainingIncomeForPayment - this.paymentModel.amt;
    return res < 0;
  }

  get selectCreditCardBalance() {
    // if (this.selectedPaymentOrigin && this.defaultCurrency && this.defaultCurrency.code) {
    //   const currentBalance = this.selectedPaymentOrigin.saldos.filter(s => s.moneda.code.toLowerCase() == this.defaultCurrency.code.toLowerCase())[0];
    //   return currentBalance.consumido
    // }
    return 0
  }

  get paymentExceedCreditCardBalance() {
    if (this.paymentModel.amt && this.selectedPaymentOrigin) {
      return this.paymentModel.amt > this.selectCreditCardBalance
    }
    return false
  }

  getUserBudgetBaseOnDate(date: string) {
    let selectedDate = new Date(date);
    let selectedYear = selectedDate.getUTCFullYear();
    let selectedMonth = selectedDate.getUTCMonth();
    let userBudget = this.userBudgets
            .find(u =>  {
              let budgetDate = new Date(u.date)
              return budgetDate.getUTCMonth() == selectedMonth
                    && budgetDate.getUTCFullYear() == selectedYear;
            });
    return userBudget;
  }

  get isModalCalledFromAssets() {
    return this.$route.name === 'Activos y Pasivos'
  }

  async saveTransaction(closeModal:boolean) {
    switch (this.activeTab) {
      case 0:
        let expenseIsValid = await (this.$refs.expenseValidator as any).validate();
        if (expenseIsValid && !this.userExceedRemainingIncome && this.userCurrentMonthBudget) {
          this.saveExpenseTransaction(closeModal);
        }
        break;
      case 1:
        let incomeIsValid = await (this.$refs.incomeValidator as any).validate();
        if (incomeIsValid && this.userCurrentMonthBudget) {
          this.saveIncomeTransaction(closeModal);
        }
        break;
      case 2:
        let paymentIsValid = await (this.$refs.paymentValidator as any).validate();
        if (paymentIsValid && !this.userExceedRemainingIncomeForPayment && this.userCurrentMonthBudget) {
          this.savePayment(closeModal);
        }
        break;
    }
  }

  async saveExpenseTransaction(closeModal:boolean) {
    this.isLoading = true;
    this.expenseModel.sub_category = this.selectedExpenseOrigin?.uuid ?? "";
    let transaction = {
      date: this.addHourToTransactionDate(this.expenseModel.date),
			description: this.expenseModel.description ? this.expenseModel.description : "Gasto",
			amt: this.expenseModel.amt,
			currency: this.expenseSubcategoryCurrency.uuid,
			from_account: this.selectedExpenseAccount?.uuid,
			to_account: this.selectedExpenseOrigin?.created_from_external ? this.selectedExpenseOrigin.external_id : this.selectedExpenseOrigin?.uuid,
			sub_category: this.selectedExpenseOrigin?.uuid,
      type: 'G',
      rate: this.rate
		} as BudgetTransaction;
    try {
      let response = await this.budgetService.CreateTransactions(transaction);
      if (response.status == 201) {
        this.$store.dispatch("Budget/newTransactionAdded", transaction);
        this.$store.dispatch("Dashboard/updateDashboard",{userId: this.user.id, moneda: this.defaultCurrencyCode});
        this.$store.dispatch('Budget/setServiceToPay', "");
        if (this.isModalCalledFromAssets) this.$store.dispatch('Asset/userCatalogLoad', this.user.id);
        this.$toast.open({type: "success", message:"Operación exitosa!", position: "top-right"})
      }
      else {
        this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
      }
    } catch (error) {
      console.error(error);
        this.$toast.error("Lo lamento, intentalo dentro de un minuto.")
    } finally {
      this.isLoading = false;
      this.resetExpenseForm();
    }
    if (closeModal) {
      const closeBttn = document.getElementById("new-transaction-close-bttn");
      closeBttn?.click();
    }
  }

  async saveIncomeTransaction(closeModal:boolean) {
    this.isLoading = true;
    this.incomeModel.sub_category = this.selectedIncomeOrigin?.uuid ?? "";
		let transaction = {
      date: this.addHourToTransactionDate(this.incomeModel.date),
			description: this.incomeModel.description,
			amt: this.incomeModel.amt,
			currency: this.incomeSubcategoryCurrency.uuid,
			to_account:  this.selectedBankAccount?.uuid,
      from_account: this.selectedIncomeOrigin?.uuid,
			sub_category: this.selectedIncomeOrigin?.uuid,
      type: "I",
      rate: this.rate
		} as BudgetTransaction;
    // transaction = this.addHourToTransactionDate(transaction);
    console.log(transaction);
    try {
      let response = await this.budgetService.CreateTransactions(transaction);
      if (response.status == 201) {
        this.$store.dispatch("Budget/newTransactionAdded", transaction);
        this.$store.dispatch("Dashboard/updateDashboard", {userId: this.user.id, moneda: this.defaultCurrencyCode});
        if (this.isModalCalledFromAssets) this.$store.dispatch('Asset/userCatalogLoad', this.user.id);
        this.$toast.open({type: "success", message:"Operación exitosa!", position: "top-right"});
      }
      else {
        this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
      }
      this.resetIncomeForm();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
    if (closeModal) {
      const closeBttn = document.getElementById("new-transaction-close-bttn");
      closeBttn?.click();
    }
  }

  async savePayment(closeModal:boolean) {
    this.isLoading = true;
    this.paymentModel.sub_category = this.selectedPaymentOrigin?.uuid ?? "";
    let transaction = {
			date: this.addHourToTransactionDate(this.paymentModel.date),
			amt: this.paymentModel.amt,
			currency: (this.selectedPaymentAccount?.currency as any).uuid,
    	from_account: this.selectedPaymentAccount ? this.selectedPaymentAccount.uuid : "",
    	to_account: this.selectedPaymentOrigin?.uuid ?? "",
    	description: `Pago Tarjeta ${this.selectedPaymentOrigin?.account_no ?? ""}`,
      type: "P",
      rate: this.rate
		} as BudgetTransaction;
    try {
      let response = await this.budgetService.CreateTransactions(transaction);
      if (response.status == 201) {
        this.$store.dispatch("Budget/newTransactionAdded", response.data);
        this.$store.dispatch("Dashboard/updateDashboard", {userId: this.user.id, moneda: this.defaultCurrencyCode});
        if (this.isModalCalledFromAssets) this.$store.dispatch('Asset/userCatalogLoad', this.user.id);
        this.$toast.open({type: "success", message:"Operación exitosa!", position: "top-right"});
      }
      else {
        this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
      }
      this.resetPaymentForm()
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
    if (closeModal) {
      const closeBttn = document.getElementById("new-transaction-close-bttn");
      closeBttn?.click();
    }
  }

  addHourToTransactionDate(date: string) {
    let now = new Date(date);
    return now.toISOString().split("T")[0];
  }

  resetExpenseForm(maintainData = false) {
    if (!maintainData) {
      this.expenseModel = { type: TransactionType.Expense } as BudgetTransaction;
      if (this.defaultCurrency) this.expenseModel.currency = {...this.defaultCurrency}
      this.selectedExpenseAccount = null;
      this.selectedExpenseOrigin = null;
      this.selectedBankAccount = null;
      this.rate = 1;
      this.expenseSubcategoryCurrency = {} as Currency
    }
    (this.$refs.expenseValidator as any).reset();
  }

  resetIncomeForm(maintainData = false) {
    if (!maintainData) {
      this.incomeModel = { type: TransactionType.Income } as BudgetTransaction;
      if (this.defaultCurrency) this.incomeModel.currency = {...this.defaultCurrency}
      this.selectedIncomeOrigin = null;
      this.selectedBankAccount = null;
      this.rate = 1;
      this.incomeSubcategoryCurrency = {} as Currency
    }
    (this.$refs.incomeValidator as any).reset();
  }

  resetPaymentForm(maintainData = false) {
    if (!maintainData) {
      this.paymentModel = { type: TransactionType.Payment } as BudgetTransaction;
      this.selectedPaymentOrigin = null;
      this.selectedPaymentAccount = null;
      this.rate = 1;
    }
    (this.$refs.paymentValidator as any).reset();
  }
}
