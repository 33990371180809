
import { Component, Vue, Watch } from "vue-property-decorator";
import EnrollmentService from "@/services/EnrollmentService";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import ChangePasswordModel from "@/models/IdentityManagement/ChangePasswordModel";
import EnrollmentCategoryModalTrigger from "@/components/budget/EnrollmentCategoryModalTrigger.vue";
import { Auth } from "aws-amplify";
import BudgetConfigModal from "@/components/budget/BudgetConfigModal.vue";
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import NotificationBadge from "@/components/budget/notifications/NotificationBadge.vue";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
  components: {
    LoadingButton,
    NewTransactionModal,
    EnrollmentCategoryModalTrigger,
    BudgetConfigModal,
    BudgetMonthSelection,
    NotificationBadge,
    ConfirmTooltip,
  },
})
export default class TopBar extends Vue {
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
  @Getter("Budget/getUserBudgets") userBudget!: UserBudget;
  @Getter("Budget/getInvalidTransactionState") disableAddTransaction!: boolean;
  showAddTransactionAnimation = false;
  unsubscribeToActionEvent!: Function;
  animationInterval!: NodeJS.Timeout;
  //
  get userProfilePicture() {
    return (
      this.user.profilePicture ||
      "https://avatars.dicebear.com/api/initials/" + this.user.nombre + ".svg"
    );
  }

  get activeTransactionModal() {
    return this.$route.name === 'Presupuesto';
  }

  openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();
    }

  mounted() {
    //Listen for ActionEvents
    this.unsubscribeToActionEvent = this.$store.subscribeAction(
      (action, state) => {
        if (action.type == "Budget/startAddTransactionAnimation")
          this.startAddTransactionAnimation();
        if (action.type == "Budget/stopAddTransactionAnimation")
          this.stopAddTransactionAnimation();
      }
    );
  }

  beforeDestroy() {
    //Unsuscriibe from vuex event
    this.unsubscribeToActionEvent();
  }

  startAddTransactionAnimation() {
    this.animationInterval = setInterval(() => {
      this.executeAnimationSet();
    }, 1500);
    this.executeAnimationSet()
  }
  stopAddTransactionAnimation() {
    clearInterval(this.animationInterval);
    this.showAddTransactionAnimation = false;
  }

  executeAnimationSet() {
    this.showAddTransactionAnimation = true;
    //set timeout to recall the animation
    setTimeout(() => {
      this.showAddTransactionAnimation = false;
    }, 1000);
  }

  async logOut() {
    this.$store.dispatch("IdentityManagement/logOutUser")
    this.$store.dispatch('Asset/clearData');
    this.$store.dispatch('Budget/clearData');
    this.$store.dispatch('Dashboard/clearData');
    await Auth.signOut();

    Vue.nextTick(() => {
      this.$router.push("/login");
    })
  }

  deleteActiveBudget() {
    this.$store.dispatch("Budget/budgetIsLoading");
    this.$store.dispatch("Budget/deleteActiveBudget", this.user.id);
  }

  callProfileModal() {
    this.$store.dispatch("Budget/showProfileModal");
  }

  toggleSideBar() {
    $('#sidebar').toggleClass('active');
  }
}
