@<template>
  <div class="card-outer-container">
    <div class="card-inner-container d-flex flex-column justify-content-between roboto p-4" :class="detailClass" :style="{ backgroundColor: bkgColor}" style="">
      <div class="d-flex flex-column justify-content-around">
        <div>
          <div class="icon-card-container d-flex align-items-center align-center icon-border-radius" :style="{ backgroundColor: icnColor }">
            <img :class="detailClass" class="mx-auto icon-card-container-img" :src="imgUrl" alt="">
          </div>
        </div>

        <div class="mt-3 middle-box">
          <p class="card-smaller-text mb-0" :style="{ color: this.textColor }">{{ titlePesos.toUpperCase() }}</p>
          <p :class="detailClass" class="card-bigger-text mb-0" :style="{ color: this.textColor }"><b>{{ showUsd ? 'USD' : 'DOP' }} {{ firstAmount }}</b></p>
          <p class="card-smaller-text mb-0" :style="{ color: this.textColor }">{{ subtitlePesos }}</p>
          <hr :class="detailClass" >
          <p :class="detailClass" class="card-bigger-text mb-0" :style="{ color: this.textColor }"><b>USD {{ secondAmount }}</b></p>
          <p :class="detailClass" class="card-smaller-text mb-0" :style="{ color: this.textColor }">{{ titleDollars }} &nbsp;</p>
        </div> 
      </div>

      <div class="down-box mt-2">
        <a style="scroll-behavior:smooth;":style="{color: textColor}" :href="this.scrollLink">
          Ver detalle
          <img class="ml-1" style="cursor: pointer; transform: rotate(90deg);" src="@/assets/img/svg/chevron-right.svg" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    imgUrl() {
      return require("@/assets/img/svg/" + this.icon + ".svg")
    }
  },
	props: ['bkg-color', 'icn-color', 'title-pesos', 'subtitle-pesos', 'title-dollars',
         'hover-color','icon', 'text-color', 'scroll-link', 'detail-class', 'first-amount', 'second-amount', 'show-usd']
}
</script>

<style scoped>
.card-outer-container {
  width: 50%;
  margin-bottom: 1em;
}
.no-show.fourth {
  visibility: hidden;
}
.flex-container {
    width: 50%;
}
.body {
  margin-top: 1rem;
}
.card-inner-container {
  /* W-214 | H-320 | R-0.67 */
  width: 201px;
  height: 300px;
  margin: 0 auto;
  border-radius: 10px;
}

/* Cards on hover */
.card-inner-container.first:hover {
  background-color: #0F559B66 !important;
}
.card-inner-container.second:hover {
  background-color: #aaeac0 !important;
}
.card-inner-container.third:hover {
  background-color: #f4960070 !important;
}
.card-inner-container.fourth:hover {
  background-color: #6d83984d !important;
}

.flex-card-container {
    width: 150px;
    border-radius: 10px;
    margin: 0 auto;
    padding: 1rem;
}

.icon-card-container {
    width: 35px;
    height: 35px;
}

.icon-card-container-img {
    width: 1.1rem;
}

.card-smaller-text {
    font-size: 0.7rem;
}

.card-bigger-text {
    font-size: 0.8rem;
}
@media screen and (min-width: 1280px) {

    .flex-container {
        height: 260px !important;
        width: 156px !important;
    }
    .card-inner-container {
      height: 260px !important;
      width: 156px !important;
      padding: 1.2em 1em !important;
    }
    .card-inner-container > div > div:nth-child(0) {
      margin-top: 3em !important;
    }
    .middle-box {
      margin-top: 0.4em !important;
    }
    .middle-box > p {
      font-size: 11px !important;
    }
    .down-box > a {
      font-size: 12px !important;
    }
    .mt-2 > img {
      width: 50px !important;
    }
    .down-box {
      margin-top: 0.1em;
    }
    .flex-card-container {
      /* width: 250px; */
    }
    .card-smaller-text {
      font-size: 0.8em;
      margin-top: 5px;
    }
    .card-bigger-text {
      font-size: 0.9em;
      margin-top: 5px;
    }
    .icon-card-container {
      width: 40px;
      height: 40px;
    }
    .icon-card-container-img {
      width: 1.3rem;
    }
    hr.third {
      margin-top: 17px;
    }
    hr.fourth {
      margin-top: 17px;
    }
    p.fourth {
      /* margin-top: 23px; */
    }
    hr.no-show.fourth {
      margin-top: 10px !important
    }
    .fourth.loan-text-excep {
      margin-top: 5px !important;
    }
    .loan-text-excep.down-box {
      margin-top: 10px;
    }
    .icon-card-container-img.third {
      width: 25px !important;
    }
}
@media screen and (min-width: 1366px) {
    .card-inner-container {
      width: 166px !important; 
    }
    .flex-card-container {
      width: 95%;
      max-width: 200px;
    }
    .card-smaller-text {
      /* font-size: 1rem; */
      margin-top: 5px;
    }
    .card-bigger-text {
      /* font-size: 1.1rem; */
      margin-top: 5px;
    }
    hr.first {
      margin-top: 17px;
    }
    hr.third {
      margin-top: 17px;
    }
    hr.fourth {
      /* margin-top: 65px; */
    }
    .view-detail {
      margin-top: 2rem;
    }
}




/* .dashboard-card-container-unit {
  padding: 30px 20px;
  border-radius: 12px;
  width: 40%;
  min-width: 200px;
  max-width: 205px;
  margin: 1em auto 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
} */
/* @media screen and (min-width:1350px) {
  .dashboard-card-container-unit {
    width: 23%;
    margin-right: 10px;
  }
}
.hovered {
	background-color: red;
} */
/* .dashboard-card-container-unit:nth-child(1) {
  margin-left: 0px;
} */
/* .dashboard-card-container-unit:nth-child(1):hover {
  background-color: #0F559B66;
}
.dashboard-card-container-unit-container-icon {
	margin-bottom: 25px;
}
.dashboard-card-container-unit:nth-child(4) > div:nth-child(1) {
  margin-bottom: 5px;
}
.dashboard-card-container-unit-icon {
  width: 40px;
  height: 40px; 
  border-radius:12px; 
}
.dashboard-card-container-unit-desc > p:nth-child(1) {
  font-size: 11px;
  font-weight: 500;
}
.dashboard-card-container-unit-desc > p:nth-child(2) {
  font-size: 16px;
  font-weight: 600;
}
.dashboard-card-container-unit-desc > p:nth-child(3) {
  font-size: 13px;
  font-weight: 400;
}
.dashboard-card-container-unit-desc > p:nth-child(5) {
  font-size: 16px;
  font-weight: 600;
}
.dashboard-card-container-unit-desc > p:nth-child(6) {
  font-size: 12px;
}
.view-detail {
  font-size: 13px; 
  margin: 20px 0 0 0;
}
.view-detail > p {
  margin: 0;
} */
/* .dashboard-card-icon {
  width: 22px;
} */
</style>