
import { Component, Vue } from "vue-property-decorator";
import LoginModel from "@/models/IdentityManagement/LoginModel";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import { Auth } from "aws-amplify";
import { Getter } from "vuex-class";
import { CognitoUser } from "amazon-cognito-identity-js";
import { email } from "node_modules/vee-validate/dist/rules";
import IdentityManagementService from "@/services/IdentityManagementService";
import * as Sentry from "@sentry/browser";
import { Subscription } from "@/models";

@Component({
  components: {
    LoadingButton,
  },
})
export default class Login extends Vue {
  identityManagementService = new IdentityManagementService();
  formModel = new LoginModel();
  isLoading = false;
  showIncorrectPasswordError = false;
  @Getter("IdentityManagement/getHasAccessToDashboard") hasAccessToDashboard!: boolean;
  async mounted() {
    let script = document.createElement("script");
    let url = process.env.BASE_URL + "js/loginui.js";
    script.setAttribute("src", url);
    document.body.appendChild(script);
    let userIsLoggedIn: boolean = await this.$store.dispatch(
      "IdentityManagement/checkIfUserIsLoggedIn"
    );
  }

  async onSubmit() {
    this.isLoading = true;
    this.showIncorrectPasswordError = false;
    let emailCopy = this.formModel.correoElectronico as string;
    let pass = this.formModel.contrasena as string;
    try {
      //Clean cognitoCookie
      Auth.signOut();
      //
      const cognitoUserResponse: CognitoUser = await Auth.signIn(
        emailCopy,
        pass
      );
      let userId = cognitoUserResponse.getUsername();
    
      if (userId) {
        let response: Subscription[] = await this.$store.dispatch("IdentityManagement/userSubcription");
        
        if (response && response[0].subscription) {
          if (response[0].subscription.poll_was_filled) {
            if (this.hasAccessToDashboard) this.$router.push("/dashboard");
            else this.$router.push("/budget");
          }
          else this.$router.push("/enrollment");
        }
      }
    } catch (error) {
      Auth.signOut();

      switch(error.code) {
        case "NotAuthorizedException":
          this.showIncorrectPasswordError = true;
          break;
        case "NetworkError":
          alert("Lo sentimos, tenemos problemas para conectarnos a nuestros servidores. Por favor revisa tu conexión a internet y vuelvelo a intentar.")
          break;
        case "UserNotConfirmedException":
          //Resend user the confirmation
          await this.resendConfirmation(emailCopy);
          break;
        default:
          console.error(error);
          var x = JSON.stringify(error);
          console.log(x);
          Sentry.captureException(error);
          Sentry.captureMessage("Unknown error occurred on login for email: "+emailCopy);
          alert("Lo sentimos, ocurrió un error inesperado intentalo más tarde.")
          break;

      }
    } finally {
      this.isLoading = false;
    }
  }

  async resendConfirmation(email: string) {
    await Auth.resendSignUp(email);
    this.$store.dispatch("IdentityManagement/pendingEmailConfirmation", {
      email
    });
    this.$router.push("/confirm-registration");
  }
}
