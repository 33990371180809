
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, BudgetSubCategory, CategoryType } from "@/models/Budget/BudgetCategory";
import SubCategoryEntry from "@/components/budget/SubCategoryEntry.vue";
import BudgetService from "@/services/BudgetService";
import UserModel from "@/models/IdentityManagement/UserModel";
import { generateGUID } from "@/utils/HelperMethods";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import Swal from 'sweetalert2';
import UserBudget from "@/models/Budget/UserBudget";

@Component({
    components: {
        SubCategoryEntry,
        ConfirmTooltip
    }
})
export default class CategoryEntry extends Vue {
    @Prop() category!: BudgetCategory;
    @Prop() smallEntry!: boolean;
    @Prop({default: true}) allowEditAlias!: boolean;
    @Prop({default: true}) workWithStateData!: boolean;
    @Prop({default: true}) showDeleteCategory!: boolean;
    @Prop({default: false}) canDragCategory!: boolean;
    budgetService = new BudgetService();
    editCategoryName = false;
    nameInputInvalid = false;
    @Getter('IdentityManagement/getUser') user!: UserModel;
    @Getter('Budget/getCategoryCatalog') categoryCatalog!: BudgetCategory[];
    @Getter('Budget/getBudgetConfiguration') budgetConfiguration!: BudgetConfiguration;
    @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("Asset/getDefaultCurrencyCode") defaultCurrencyCode!: string;
    showSubcategories = false;

    get thisCateogryIsIncome() {
        return this.category.type == CategoryType.Income;
    }

    get getCategoryName() {
        if (this.category.alias == undefined || this.category.alias == '' || this.category.category == null) {
          return this.category.category;
        }
        return this.category.alias;
    }

    get availableSubcategories() {
        //Se usa el IdOriginal para buscar las subcategorias de la categoria de origen 
        let catalogCategory = this.categoryCatalog.find(c => c.uuid == this.category.originalId);
        // return catalogCategory?.subcategorias;
        let notUsedSubcategories = catalogCategory?.subcategories?.filter(s => !this.category.subcategories?.some(ss => ss.uuid == s.uuid));
        return notUsedSubcategories;
    }

    get categoryClass() {
        return this.category.type == CategoryType.Income
                ? "income"
                : "expense"
    }

    get activeUserBudgetIsDisabled() {
        return false;
        if (this.activeBudget) {
          return !this.activeBudget.is_active;
        }
        return true;
    }

    get totalMontoPresupuestado() {
        let montos = this.category.subcategories?.map(x => {
            if (typeof x.budgeted == 'string') return parseFloat(x.budgeted) * (+x.rate)
            return x.budgeted * (+x.rate);
        });
        let monto = 0;
        if (montos && montos.length != 0)
            monto = montos.reduce((a, b) => a+b);

        return monto
    }

    get totalMontoPresupuestadoFormatted() {
        return this.totalMontoPresupuestado.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
    get totalMontoEjecutado() {
        let montos = this.category.subcategories?.map(x => {
            if (!x.executed) return 0
            if (typeof x.executed == 'string') return parseFloat(x.executed) * (+x.rate)
            return x.executed * (+x.rate);
        });
        let monto = 0;
        if (montos && montos.length != 0)
            monto = montos.reduce((a, b) => a+b);

        return monto;
    }
    get totalMontoEjecutadoFormatted() {
        return this.totalMontoEjecutado.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get totalMontoEjecutadoPerc() {
      let executed = this.totalMontoEjecutado;
      let planned = this.totalMontoPresupuestado;

      if (planned <= 0) {
        return 0;
      }
      return Math.floor((executed/planned)*100);
    }

    get colsPanOfFirstColumn(): number {
         if (this.isMobile) {
             return 2;
         } else {
            if (this.smallEntry)
                return 1;
            else
                return 2;
         }
    }

    get canToggleCategoryInMobile(): boolean {
        return this.isMobile && !this.smallEntry;
    }

    mounted() {
        if (!this.category.category && !this.category.alias)
            this.editCategoryName = true;
        if (this.canToggleCategoryInMobile)
            this.showSubcategories = false;
    }
    addNewSubCategory(evt: Event) {
        evt.stopPropagation();
        let isValid = this.canAddMoreSubCategories();
        if (!isValid){
            this.printNoMoreSubCategoryAlert();
            return;
        }
        let subCategory = {alias: "Nueva subcategoría", executed: 0, budgeted: 0, category: this.category.uuid} as BudgetSubCategory;
        this.$store.dispatch("Budget/addNewSubCategory", { payload: subCategory })
    }

    saveSubCategoryChange(subCategory: BudgetSubCategory) {   
        this.$store.dispatch("Budget/emitPresupuestoChange");
        let budgetDate = this.activeBudget.date;
        this.budgetService
            .SaveSubCategoryChanges(this.user.id, budgetDate, this.category, subCategory)
            .then(response => {
                if (response.data.statusCode != 200) {
                    //TODO: Implement logic when Request fail
                     this.$toast.open({type: "error", message:"Lo lamento, intentalo dentro de un minuto.", position: "top-right"})
                    console.log(response.data)
                }
                //
            })
            .catch((err) => {
                console.error(err);
            })
    }

    editAlias(evt: Event) {
        evt.stopPropagation();

        if (!this.allowEditAlias || this.activeUserBudgetIsDisabled)
            return; //prevent edition
            
        let input = (this.$refs.categoryNameInputMob as HTMLInputElement);
        input.value = this.category.alias ? this.category.alias : '';
        input = (this.$refs.categoryNameInputDesktop as HTMLInputElement);
        input.value = this.category.alias ? this.category.alias : '';
        this.editCategoryName = true;
        Vue.nextTick(() => {
            input.focus();
        })
    }

    onCategoryNameInputBlur(event: Event){
        //
        let value = (event.target as HTMLInputElement).value;
        if (!value) {
            event.preventDefault();
            this.nameInputInvalid = true;
            return;
        } else{
            this.nameInputInvalid = false;
        }
        this.editCategoryName = false;
        this.category.alias = value;
        this.$store.dispatch("Budget/changeCategoryAlias", this.category);
    }

    onSubCategoryRemoved(subCategory: BudgetSubCategory) {
        this.$store.dispatch("Budget/removeSubCategory", subCategory)       
    }

    onConfirmCategoryDelete(category: BudgetCategory) {
        this.$emit("onCategoryRemoved", category);
    }

    canAddMoreSubCategories(): boolean {
        return true;
        let maxSubcategories = this.budgetConfiguration.maxSubcategorias;
        if (this.category && this.category.subcategories) return this.category.subcategories?.length < maxSubcategories;
        return false
    }

    printNoMoreSubCategoryAlert() {
        Swal.fire({
            titleText: 'Aviso',
            text: 'Por el momento el límite de entradas por categoria que puedes agregar es de '+this.budgetConfiguration.maxSubcategorias,
            icon: 'warning',
            confirmButtonText: 'De acuerdo'
        })
    }

    toggleExpand() {            
        this.showSubcategories = !this.showSubcategories;
    }

    toggleExpandMobile() {
      this.toggleExpand();
    }

    dragstart_handler(ev: Event) {
        console.log("drag event");
        ev.preventDefault();

    }
}
