
import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import NextPayment from '@/components/dashboard/NextPayment.vue'
import { DueService } from "@/models";
import EmptyStateDashboard from "../helpers/emptyStateDashboard.vue";
import UIkit from 'uikit';

@Component({
  components: {
    NextPayment,
    EmptyStateDashboard
  }
})
export default class UpcomingPayments extends Vue {
  @Getter("Dashboard/getDueServices") services!: DueService[]
  @Getter("Dashboard/isDashboardLoaded") isLoaded!: boolean;
  initialLoad = false

  @Watch("services") 
  toggleInitialLoad(value: DueService[]) {
    if (!this.initialLoad) this.initialLoad = true;
  }

  events = [
    {
      name: 'WWDC 1',
      date: '1/12/2023',
      time: '7:00 p.m.',
      place: 'Zoom'
    },
    {
      name: 'WWDC 2',
      date: '2/12/2023',
      time: '7:00 p.m.',
      place: 'Zoom'
    },
    {
      name: 'WWDC 3',
      date: '3/12/2023',
      time: '7:00 p.m.',
      place: 'Zoom'
    },
    {
      name: 'WWDC 4',
      date: '7/12/2023',
      time: '7:00 p.m.',
      place: 'Zoom'
    }
  ]

  createNewService() {
    this.$root.$emit('clearService');
    UIkit.modal(document.getElementById('add-new-service-modal')).show();
  }
}
