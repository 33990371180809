
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, BudgetSubCategory, BudgetSubCategoryEdit, CategoryType } from "@/models/Budget/BudgetCategory";
import { CurrencyInput, setValue }  from 'vue-currency-input';
import ConfirmDelete from "@/components/tooltips/ConfirmDelete.vue";
import Multiselect from "vue-multiselect";
import AutocompleteInput from "@/components/autocomplete/autocompleteInput.vue";
import UserModel from "@/models/IdentityManagement/UserModel";
import UserBudget from "@/models/Budget/UserBudget";

@Component({
    components: {
        CurrencyInput,
        ConfirmDelete,
        Multiselect,
        AutocompleteInput
    }
})
export default class SubCategoryEntry extends Vue {
    @Prop() categoryId!: string;
    @Prop() categoryType!: CategoryType;
    @Prop() subCategory!: BudgetSubCategory;
    @Prop({default: false}) smallEntry!: boolean;
    @Prop({default: true}) workWithStateData!: boolean;
    //Esto es para el dropdown que permite cambiar de subcategoria
    @Prop() availableSubcategories!: BudgetSubCategory[];
    editSubCategoryName = false;
    arrayInput: HTMLInputElement[] = [];
    focusNameInput = false;
    focusNameInputDesktop = false
    @Getter("IdentityManagement/getUser") user!: UserModel;
    @Getter("Budget/getActiveBudget") activeUserBudget!: UserBudget;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("Asset/getDefaultCurrencyCodeForDisplay") defaultCurrencyCode!: String;
    canTriggerValueChange = false;
    budgeted = 0;

    get subcategoryName() {
      return this.subCategory.alias || this.subCategory.sub_category; 
    }

    get displayCurrency() {
      let currency = this.defaultCurrencyCode;
      if (this.subCategory && (this.subCategory as any).currency) currency = (this.subCategory as any).currency.code;
      return currency
    }

    get subCategoryVariation() {
        let res = 0;
        if (this.categoryType == CategoryType.Income) {
            res = this.subCategory.executed - this.subCategory.budgeted;
        } else {
            res = this.subCategory.budgeted - this.subCategory.executed;
        }
         
        return res == 0 ? "-" : res;
    }
    get selectionableSubcategories() {
        //This is because cannot use spread operator in props
        var copyArray = this.availableSubcategories?.map(x => x) ?? [];
        let returnArray = [...copyArray];
        if (this.subCategory.sub_category) {
            //If this subcategory exists (has name) added to list
            returnArray.push(this.subCategory);
        }
        return returnArray;
    }

    get formattedExecutedAmount() {
      return this.displayCurrency + ' ' + this.subCategory.executed.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get formattedAvailableAmount() {
      let result = this.subCategory.budgeted - this.subCategory.executed;
      return result.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }

    get subCategoryMontoPresupuesto() {
        return this.subCategory.budgeted;
    }

    get activeUserBudgetIsDisabled() {
        return false;
        return !this.activeUserBudget.is_active;
    }

    @Watch("subCategoryMontoPresupuesto")
    onMontoPresupuestadoValueUpdated(newValue: number) {
        //Esto se usa para actualizar el componenete de Currency input cuando el valor presupuestado sea
        //Modificado desde el store (Por ejemplo, cuando en enrollment category agregamos 2 subcategorias
        //de la misma categoria, a agregar la segunda el input no se actualiza porque ya se hizo mounted con la primera subcategory)
        setValue(this.arrayInput[1], newValue);
    }

    onChange(value: number | null) {
      if (!value) {
        value = 0;
        setValue(this.arrayInput[1], value);
        setValue(this.arrayInput[2], value);
      }
      this.budgeted = value;
    }

    @Watch("budgeted")
    updateValue(newValue: number) {
        if (this.canTriggerValueChange) this.onMontoPresupuestadoChange(newValue);
    }

    mounted() {
        if (!this.subCategory.alias) this.editSubCategoryName = true;
        
        this.arrayInput = [
            (this.$refs.subCategoryNameInput as HTMLInputElement),
            ((this.$refs.input2 as any).$el as HTMLInputElement),
            ((this.$refs.input3 as any).$el as HTMLInputElement),
        ];

        //set Input Values
        setValue(this.arrayInput[1], this.subCategory.budgeted);
        setValue(this.arrayInput[2], this.subCategory.budgeted);
    }

    editName(desktop=false) {
        if (this.activeUserBudgetIsDisabled)
            return;

        this.editSubCategoryName = true;
        //If is subcategory name edit, always focus
        if (desktop) {
          this.focusNameInputDesktop = true;
        } else {
          this.focusNameInput = true;
        }
    }

    onSubCategoryChange(selectedSubCategory: BudgetSubCategory){
        this.replaceCurrentSubCategory(selectedSubCategory);
    }

    replaceCurrentSubCategory(selectedSubCategory: BudgetSubCategory) {
        if (this.workWithStateData) {
            this.$store.dispatch("Budget/replaceSubCategory", {
                userId: this.user.id, 
                categoryId: this.categoryId,
                previousSubCategoryId: this.subCategory.uuid,
                newSubCategoryId: selectedSubCategory.uuid,
                newSubCategoryName: selectedSubCategory.sub_category
            })
        } else {
            this.subCategory.uuid = selectedSubCategory.uuid
            this.subCategory.sub_category = selectedSubCategory.sub_category;
        }
        this.editSubCategoryName = false;
         //
        Vue.nextTick(() => {
            this.emitChange();
        })
    }
    onUnkownSubCategoryCreated(name: string) {
        if (isNaN(parseFloat(this.subCategory.uuid)) || isNaN(+this.subCategory.uuid)) {
          const subcategoryEdit = { 
            ...this.subCategory,
                uuid: this.subCategory.uuid,
                alias: name,
                categoryId: this.categoryId
            } as BudgetSubCategoryEdit
            this.$store.dispatch("Budget/changeSubCategoryMontoPresupuestado", subcategoryEdit)
            .then(() => this.editSubCategoryName = false)  
        } else {
          let subCategoryToAdd = { alias: name, executed: 0, budgeted: 0, category: this.categoryId } as BudgetSubCategory
          this.$store.dispatch("Budget/addNewSubCategory", { payload: subCategoryToAdd, index: this.subCategory.uuid })
          .then(() => this.editSubCategoryName = false)
        }
    }

    allowValueChange() {
        this.canTriggerValueChange = true
    }

    onMontoPresupuestadoChange(value: number) {
        if (isNaN(parseFloat(this.subCategory.uuid)) || isNaN(+this.subCategory.uuid)) {
            const subcategoryEdit = {
              ...this.subCategory,
              uuid: this.subCategory.uuid,
              budgeted: value,
              categoryId: this.categoryId
          } as BudgetSubCategory
          this.$store.dispatch("Budget/changeSubCategoryMontoPresupuestado", subcategoryEdit)
          .then(res => {
            if (res.result == 'error') {
              const originalValue = this.subCategory.budgeted.toString()
              setValue(this.arrayInput[1], parseInt(originalValue))
              setValue(this.arrayInput[2], parseInt(originalValue))
            }
          })
        } else {
          let subCategoryToAdd = {
            alias: this.subCategory.alias, executed: 0, budgeted: value, category: this.categoryId
          } as BudgetSubCategory
          this.$store.dispatch("Budget/addNewSubCategory", { payload: subCategoryToAdd, index: this.subCategory.uuid })
          .then(res => {
            if (res.result == 'error') {
              const originalValue = this.subCategory.budgeted.toString()
              setValue(this.arrayInput[1], parseInt(originalValue))
              setValue(this.arrayInput[2], parseInt(originalValue))
            }
          })
        }
    }

    emitChange() {
        this.$emit("onSubCategoryChanged", this.subCategory);
    }

    inputEnterHit(event: Event) {
        (event.target as HTMLInputElement).blur();
    }
    
    onDelete(subCategory: BudgetSubCategory) {
        this.$emit("onSubCategoryRemoved", subCategory);
    }
    addNewUnknownSubcategory(value: string) {
        console.log(value);
    }

    onNameInputChange(value: string) {
        if (!value)
            return this.selectionableSubcategories;
        //
        let filterResult = this.selectionableSubcategories
            .filter(sc => sc.sub_category.toLowerCase().startsWith(value.toLowerCase()));
        return filterResult;
    }

    getResultValue(subcategory: BudgetSubCategory) {
        if (subcategory.created_from_template && subcategory.alias) {
            return subcategory.alias;
        }
        console.log('subcategory.subcategoria');
        return subcategory.sub_category;
    }

    get subCategoryLabel() {
        return this.subCategory.alias
    }
}
