
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import SideBar from "@/components/layout/SideBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import "vue-select/dist/vue-select.css";

@Component({
    components: {
        SideBar,
        TopBarAssets,
        NewTransactionModal,
    }
})
export default class HelpDesk extends Vue {
    isDesktopViewport = false;
    isMobileViewport = true;

    mounted() {
        this.$store.dispatch("Asset/assetCatalogLoaded");
        this.$store.dispatch("Budget/loadUserBudgets");

        let script = document.createElement('script')
        let url = "//js.hsforms.net/forms/embed/v2.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if ((window as any).hbspt) {
                (window as any).hbspt.forms.create({
                    region: "na1",
                    portalId: "22178301",
                    formId: "63be5231-a6b0-4333-ab3b-445e03b80b32",
                    target: "#hubSpotForm"
                });
            }
        })
        
        window.addEventListener('message', event => {
            if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
                setTimeout(() => this.$router.go(0), 2000)
            }
        });
    }

}
