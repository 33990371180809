<template>
  <div id="credit-line-modal" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button style="background-color: #F6F6F6; border-radius: 50%;" class="uk-modal-close-default" type="button" uk-close></button>
      <p style="fontSize:1em; fontWeight: bold; marginBottom: 0.5em;">Añadir línea de crédito préstamo</p>
      <p style="fontSize:0.7em; fontWeight: 400; color: #B5B5B5; marginBottom: 0.5em;"></p>
      <form class="uk-flex uk-flex-wrap uk-flex-between" id="bank-account-form">
        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Banco</label>
          <select class="uk-select bank-account-select fs-7" placeholder="Seleccionar banco">
            <option value="null" selected>Seleccionar banco</option>
            <option value="1">BHD León</option>
            <option value="2">Popular</option>
            <option value="3">Banco de Reservas</option>
          </select>
        </div>
        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Tasa de interés</label>
          <div class="uk-inline">
            <span class="uk-form-icon fs-7">%</span>
            <input class="uk-input fs-7" type="text" style="height: 30px; border-radius: 4px; textAlign: right;" placeholder="0.00%" required>
          </div>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Moneda</label>
          <select class="uk-select bank-account-select fs-7" placeholder="Seleccionar tipo">
            <option value="null" selected>Seleccionar</option>
            <option value="1">Pesos</option>
            <option value="2">Dólares</option>
          </select>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Límite de la línea</label>
          <div class="uk-inline">
            <span class="uk-form-icon fs-7">RD$</span>
            <input class="uk-input fs-7" type="text" style="height: 30px; border-radius: 4px; textAlign: right; " placeholder="0.00" required>
          </div>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Tiempo del préstamo</label>
          <select class="uk-select bank-account-select fs-7">
            <option value="null" selected>Elegir plazo</option>
            <option value="1">6 meses</option>
            <option value="1">12 meses</option>
            <option value="1">18 meses</option>
          </select>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Valor abonado</label>
          <div class="uk-inline">
            <span class="uk-form-icon fs-7">RD$</span>
            <input class="uk-input fs-7" type="text" style="height: 30px; border-radius: 4px; textAlign: right;" placeholder="0.00" required>
          </div>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Valor cuota</label>
          <div class="uk-inline">
            <span class="uk-form-icon fs-7">RD$</span>
            <input class="uk-input fs-7" type="text" style="height: 30px; border-radius: 4px; textAlign: right;" placeholder="0.00" required>
          </div>
        </div>

        <div class="uk-width-1-2 uk-form-controls">
          <label class="uk-form-label">Día de pago</label>
          <div class="uk-inline">
            <span class="uk-form-icon" uk-icon="icon: calendar"></span>
            <input class="uk-input fs-7" type="text" style="height: 30px; border-radius: 4px; textAlign: right;" placeholder="Seleccionar" required>
          </div>
          <!-- <input class="uk-input" type="text" style="textAlign: right;" placeholder="0" required> -->
          
        </div>
        <div class="mt-4 ml-auto">
          <button style="backgroundColor: white; color: #0F559B; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar y añadir otra</button>
          <button class="ml-2" style="backgroundColor: #0F559B; color: white; border: 1px solid #0F559B; border-radius: 16px; fontSize: 0.7em; padding: 3px 15px;">Guardar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>