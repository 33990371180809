<template>
  <div class="ml-4 mt-4 ml-md-0 mt-md-2">
    <div style="position: relative;" @click="selectCard" :class="{'selected-card': selected }" class="d-flex flex-column justify-content-between bank-account-card-container p-2 mb-3 mb-lg-0 mr-4 p-lg-3">
      <transition name="fade">
        <img v-if="selected" style="position: absolute;" class="edit-button" @click.stop="editCurrentAccount" src="@/assets/img/svg/edit.svg" alt="">
      </transition>
      <transition name="fade">
        <ConfirmTooltip
            v-if="selected"
            :message="'¿Estás seguro que deseas borrar esta cuenta de banco?'"
            @onConfirmTrue="deleteAccount">
            <span aria-hidden="true">
              <img class="cb-delete-button" src="@/assets/img/trash.png" alt="">
            </span>
        </ConfirmTooltip>
      </transition>
      <div class="d-flex align-items-center">
        <div>
          <img class="bank-card-image" :src="logo" alt="Icon">
        </div>
        <div class="ml-2">
          <p class="mb-0 fs-8"><b>{{ this.bankName }}</b></p>
          <p class="mb-0 fs-8">Cuenta {{ this.denomination }}</p>
        </div>
      </div>
      <div class="bottom-card-part d-flex justify-content-between align-items-end">
        <div>
          <p class="mb-0 fs-7"><b>No. de cuenta</b></p>
          <p class="mb-0 fs-7">{{ this.accountName }}</p>
          <p class="mb-0 fs-7">{{ this.accountType }}</p>
        </div>
        <div class="align-right">
          <p class="mb-0 fs-8">Balance disponible</p>
          <p class="mb-0 fs-9"><b>{{ currencyCode.toUpperCase() }} {{ commaBalance }}</b></p>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="selected" class="d-none uk-flex mr-4 mt-3 justify-content-between">
        <button @click="openTransactionModal(false)" style="width: 45%;" class="fs-8 p-3 add-income">+ Añadir ingreso</button>
        <button @click="openTransactionModal()" style="width: 45%;" class="fs-8 p-3 add-expense">+ Añadir gasto</button>
      </div>
    </transition>
    <AddNewTransactionModal/>
  </div>
</template>

<script>
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import AddNewTransactionModal from "@/components/assetsLiabilities/assetsTransactions/AddNewTransaction.vue"

export default {
  components: {
    ConfirmTooltip,
    AddNewTransactionModal
  },
  data() {
    return {
      openOptions: false
    }
  },
  props: ['bank-name', 'denomination', 'account-name', 'account-type', 'remaining-balance', 'id', 'selected', 'logo', 'currency-code'],
  computed: {
    commaBalance() {
      return this.remainingBalance.toLocaleString('en-US', {minimumFractionDigits: 2})
    }
  },
  methods: {
    selectCard(e) {
      if (e.target.className !== 'cb-delete-button') {
        this.$emit('toggle-select', this.id);
      }
    },
    openTransactionModal() {
      UIkit.modal(document.getElementById('add-new-transaction-modal')).show()
    },
    editCurrentAccount() {
      this.$emit('edit-account', this.id);
    },
    deleteAccount() {
      this.$emit('delete-account', this.id)
    }
  }
}
</script>

<style>
.bank-account-card-container:hover {
  box-shadow: 2px 1px 10px 1px #728190;
}
.cb-delete-button {
  padding: 8px;
  width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 1px #ced0d3;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background-color: white;
    position: absolute;
    margin: auto;
    top: -13px;
    right: 0;
    left: calc(63%);
}
.add-income {
  border: 2px dashed #0F559B;
  background-color: white;
  color: #0F559B;
  border-radius: 15px;
  cursor: pointer;
}
.add-income:active,
.add-income:hover {
  border: 2px dashed white;
  background-color: #0F559B;
  color:  white;
}
.add-expense {
  border: 2px dashed #BF6D6D;
  color: #BF6D6D;
  background-color: white;
  border-radius: 15px;
  cursor: pointer;
}
.add-expense:hover,
.add-expense:active {
  border: 2px dashed white;
  color: white;
  background-color: #BF6D6D;
  border-radius: 15px;
}
.bank-card-image {
  width: 35px; 
  border-radius: 5px;
}
.bank-account-card-container {
  	box-shadow: 0px 3px 6px #00000029;
	  border: 1px solid #CEE6FF;
    border-radius: 8px;
    max-width: 310px;
    min-width: 250px;
}
.bank-account-card-container:hover {
  box-shadow: var(--hover-shadow);
}
.bottom-card-part {
  margin-top: 3rem;
}
@media screen and (min-width:992px) {
  .bank-account-card-container {
    min-width: 305px;

  }
  .bottom-card-part {
    margin-top: 4rem;
  }
  .bank-card-image {
    width: 50px;
  }
}
.bank-account-card-header {
	display: flex;
}
.bank-account-card-header-text {
	margin-left: 10px;
}
.bank-name {
	margin-bottom: 0px;
  font-size: 1em;
  font-weight: 600;
}
.bank-account-card-content {
	display: flex;
	justify-content: space-between;
}
.account-denom {
  font-size: 0.8em;
}

.edit-button {
  /* width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -15px;
    right: 0;
    left: calc(92%); */
}
.delete-button {
  width: 40px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -15px;
    right: 0;
    left: calc(72%);
}
.bg-delete-container {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 21.6px;
  border: none;
  background: transparent;
  position: absolute;
  margin: auto;
  top: -13px;
  right: 0;
  left: calc(72%);
  padding-right: 4px;
  padding-top: 2px;
  box-shadow: 0px 3px 6px #00000029;
}
</style>

<style scoped>
#trash-icon-delete {
  width: 22px;
}
</style>