
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import EnrollmentCategoryModalTrigger from "@/components/budget/EnrollmentCategoryModalTrigger.vue";
import { Auth } from "aws-amplify";
import BudgetConfigModal from "@/components/budget/BudgetConfigModal.vue";
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import NotificationBadge from "@/components/budget/notifications/NotificationBadge.vue";

@Component({
  components: {
    LoadingButton,
    EnrollmentCategoryModalTrigger,
    BudgetConfigModal,
    BudgetMonthSelection,
    NotificationBadge
  },
})
export default class TopBarAssets extends Vue {
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Budget/getShowProfileModal") showProfileModal!: boolean;
  @Prop({ default: 'Productos Financieros' }) title!: string;
  @Prop({ default: 'Transacciona, administra y gestiona tus productos.' }) subtitle!: string;
  //
  showAddTransactionAnimation = false;
  unsubscribeToActionEvent!: Function;
  animationInterval!: NodeJS.Timeout;
  //
  get userProfilePicture() {
    return (
      this.user.profilePicture ||
      "https://avatars.dicebear.com/api/initials/" + this.user.nombre + ".svg"
    );
  }

  get showDefaultHeader() {
    return this.title.toLowerCase() !== 'Historial de Pagos'.toLowerCase()
  }

  get userName() {
    return this.user.nombre || 'Usuario';
  }

  mounted() {
    //Listen for ActionEvents
    this.unsubscribeToActionEvent = this.$store.subscribeAction(
      (action, state) => {
        if (action.type == "Budget/startAddTransactionAnimation")
          this.startAddTransactionAnimation();
        if (action.type == "Budget/stopAddTransactionAnimation")
          this.stopAddTransactionAnimation();
      }
    );
  }

  get routeIconMap() {
    return [
      {
        route: 'dashboard',
        icon: 'graph-up'
      },
      {
        route: 'presupuesto',
        icon: 'budget-view-icon'
      },
      {
        route: 'assetsLiabilities',
        icon: 'bank-assets-blue'
      },
      {
        route: 'cuenta',
        icon: 'user-icon'
      },
      {
        route: 'planes',
        icon: 'plans-icon'
      },
    ]
  }

  get getViewImg() {
    let icon = 'graph-up';
    let match = this.routeIconMap.find(i => i.route === this.$route.name?.toLowerCase())

    if (match) {
      icon = match.icon
    }
    return require('@/assets/img/svg/' + icon + '.svg');
  }

  beforeDestroy() {
    //Unsuscriibe from vuex event
    this.unsubscribeToActionEvent();
  }

  startAddTransactionAnimation() {
    this.animationInterval = setInterval(() => {
      this.executeAnimationSet();
    }, 1500);
    this.executeAnimationSet()
  }
  stopAddTransactionAnimation() {
    clearInterval(this.animationInterval);
    this.showAddTransactionAnimation = false;
  }

  executeAnimationSet() {
    this.showAddTransactionAnimation = true;
    //set timeout to recall the animation
    setTimeout(() => {
      this.showAddTransactionAnimation = false;
    }, 1000);
  }

  async logOut() {
    this.$store.dispatch("IdentityManagement/logOutUser")
    this.$store.dispatch('Asset/clearData');
    this.$store.dispatch('Budget/clearData');
    this.$store.dispatch('Dashboard/clearData');
    await Auth.signOut();

    Vue.nextTick(() => {
      this.$router.push("/login");
    })
  }

  
  callProfileModal() {
    this.$store.dispatch("Budget/showProfileModal");
  }

  toggleSideBar() {
    $('#sidebar').toggleClass('active');
  }
}
