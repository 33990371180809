
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import UIkit from 'uikit';
import { isBigNumber } from "@/utils/HelperMethods";

@Component({
  components: {
    NewTransactionModal
  }
})
export default class PatrimonyCard extends Vue {
  @Prop() title!: string;
  @Prop() subtitle!: string;
  @Prop() titleColor!: string;
  @Prop() text!: string;
  @Prop() amount!: number;
  @Prop({ default: 'DOP' }) currencyCode!: string;
  @Prop() currencyText!: string;
  @Prop() iconBkgColor!: string;
  @Prop() footerText!: string;
  @Prop() quantity!: number;
  modalId = 'IBFModal'

  get titleColorProperty() {
    if (this.title && this.title.toLowerCase() === 'disponible') {
      return 'font-green-caudall'  
    }
    return 'font-gray-caudall'
  }

  isBigNumber = isBigNumber

  hideLiabilityModal() {
    (this.$refs['liabilityModal'] as any).hide();
  }

  get displayAsDisabled() {
    return this.quantity && this.quantity == 0
  }

  get dynamicCardClasses() {
    const showBttn = this.showAddBttn ? '' : 'order-lg-12'
    const showAsDisabled = this.displayAsDisabled ? 'disabled-card' : ''

    return showBttn + ' ' + showAsDisabled
  }

  getImgUrl(icon: string) {
      return require('@/assets/img/svg/' + icon + '-dark-blue.svg');
  }

  showHelperModal() {
    this.$emit('helperModal', this.title)
  }

  createNewCreditCard() {
    this.$bvModal.hide(this.modalId);
    this.$root.$emit('clearCreditCard');
    UIkit.modal(document.getElementById('credit-card-modal')).show();
  }

  showLiabilityModal() {
    (this.$refs['liabilityModal'] as any).show();
  }

  openProductModal() {
    if (this.title && this.title.toLowerCase() === 'activo') {
      this.createNewBankAccount();
      return;
    }
    this.showLiabilityModal()
  }

  createNewBankAccount() {
    this.$bvModal.hide(this.modalId);
    this.$root.$emit('clearBankAccount');
    UIkit.modal(document.getElementById('bank-account-modal')).show()
  }

  createNewLoan() {
    this.$bvModal.hide(this.modalId);
    this.$root.$emit('clearLoan');
    UIkit.modal(document.getElementById('loan-modal')).show();
  }

  get showAddBttn() {
    if (this.title) {
      return this.title.toLowerCase() !== 'balance total';
    }
    return false;
  }

  get transactionBttnColor() {
    if (this.title) {
      return this.title.toLowerCase() === 'activo' ? '#409BFA': '#F49604' 
    }
    return 'red'
  }

  get formattedAmount() {
    let amount = 0;
    if (typeof this.amount == 'number') {
      amount = this.amount
    }
    return amount.toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  get iconName() {
    if (this.title.toLowerCase() === 'activo') return 'dashboard-asset'
    else if (this.title.toLowerCase() === 'pasivo') return 'dashboard-liability'

    return 'wallet-dark-blue'
  }

  get iconColorClass() {
    if (this.title.toLowerCase() === 'activo') return 'filter-asset'
    else if (this.title.toLowerCase() === 'pasivo') return 'filter-liability'

    return ''
  }

  imgUrl() {
    return require("@/assets/img/svg/" + this.iconName + ".svg")
  }
}
