import BaseApiService from "./BaseApiService";
import BaseServiceResponse from "@/models/BaseServiceResponse";
import SurveyServiceResponse from "@/models/Enrollment/SurveyServiceResponse";
import { SurveyModel, PreguntasModel, OpcionesModel } from "@/models/Enrollment/SurveyServiceResponse";
import AppSettings from "@/AppSettings";
import store from "@/store";

export default class EnrollmentService extends BaseApiService {

    public GetEncuesta() {
        this.apiUrl = AppSettings.COGNITO_URL;
        return this.post<Object, SurveyServiceResponse>("obtener-encuesta-sitioweb", {})
    }

    public SaveUserSurveySelection(userId: string, survey: SurveyModel) {
        this.apiUrl = AppSettings.API_URL;
        const subscription = store.getters['IdentityManagement/getUserSubscription'];
        return this.post<{idUsuario: string, encuesta: SurveyModel, subscription: string}, Object>(`security/me/save_poll/`, 
                                        {
                                            idUsuario: userId,
                                            encuesta: survey,
                                            subscription: subscription
                                        })
    }
}