
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from 'vuex-class';
import UserBudget from "@/models/Budget/UserBudget";
import writeXlsxFile, { Schema } from 'write-excel-file';
import { BudgetSubCategory, CategoryType } from "@/models/Budget/BudgetCategory";
import Budget from "@/views/Budget.vue";

@Component({
  components: {},
})
export default class ExportButton extends Vue {
    @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;

    excelSchema = [
        {
            column: "Concepto",
            value: (record: ExcelRecords) => record.subcategoryName,
            width: 20,
            wrap: true
          },
        {
            column: 'Categoria',
            value: (record: ExcelRecords) => record.categoryName,
            width: 20,
            wrap: true
        },
        {
            column: 'Presupuestado',
            value: (record: ExcelRecords) => record.presupuestado,
            width: 13,
            type: "tring"
        },
        {
            column: 'Ejecutado',
            value: (record: ExcelRecords) => record.ejecutado,
        },
        {
            column: 'Variacion',
            value: (record: ExcelRecords) => record.variacion,
        }
    ]
  
  mounted() {
    
  }

  async exportBudget() {
      let records: ExcelRecords[] = this.getRecords();
      //comentando esta parte...
    //   await writeXlsxFile(records, {
    //       schema: this.excelSchema,
    //       fileName: "Budget.xlsx"
    //   });

  }

  getRecords() {
      let categories = this.activeBudget?.categories || [];
      let records: ExcelRecords[] = [];

      return records;
  }

  getSubCategoryVariation(subCategory: BudgetSubCategory, categoryType: CategoryType) {
        let res = 0;
        if (categoryType == CategoryType.Income) {
            res = subCategory.executed - subCategory.budgeted;
        } else {
            res = subCategory.budgeted - subCategory.executed;
        }
         
        return res;
    }
}

interface ExcelRecords {
    categoryName: string,
    subcategoryName: string,
    presupuestado: number,
    ejecutado: number,
    variacion: number,
}
