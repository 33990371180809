
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DistributionChartDesktop from "./DistributionChartDesktop.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    DistributionChartDesktop
  }
})
export default class DesktopSideColumn extends Vue {
  @Getter("Budget/getBudgetTypeTab") budgetTypeTab! : number;
  @Getter("Budget/getLastVisitedSubTab") activeTab! : number;
  // activeTab = 0

  changeActiveTab(tab: number) {
    this.$store.commit('Budget/SET_LAST_VISITED_SUBTAB', tab);
    this.$root.$emit('storeLastVisitedSubTab', tab);
    // this.activeTab = tab;
  }

  get distributionChartSubType() {
    if (this.activeTab == 1) return 'C'
    else if (this.activeTab == 0) return 'P'
    else return 'A'
  }
}
