
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class LoadingButton extends Vue {
    @Prop({ default: 'btn btn-info' }) className!: string;
    @Prop({ default: false }) loading!: boolean;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: false }) smallSprinner!: boolean;
}
