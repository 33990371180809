
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import ChangePasswordModel from "@/models/IdentityManagement/ChangePasswordModel";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Auth } from "aws-amplify";
import Inputmask from "inputmask";
import Multiselect from "vue-multiselect";
import validateDominicanId from 'validacion-cedula-dominicana';
import { extend } from "vee-validate";
import moment from "moment";
import { Currency } from '@/models/Common/Common'


extend("isCedula", {
  validate: (value) => {
    let onlyNumbers = value.match(/\d+/g)?.join('');
    return validateDominicanId(onlyNumbers);
  },
  message: "Esta no es una cédula válida.",
});

extend("isValidPhone", {
  validate: (value) => {
    let onlyNumbersPhone = value.match(/\d+/g)?.join('');
    return onlyNumbersPhone.length == 11 || onlyNumbersPhone.length == 0;
  },
  message: "Este número de teléfono no es válido.",
});
extend("isValidDate", {
  validate: (value) => {
    let onlyNumbersDate = value.match(/\d+/g)?.join('');
    let momentDate = moment(value, "DD/MM/YYYY");
    return onlyNumbersDate.length == 8 && momentDate.isValid();
  },
  message: "Esta fecha no es válida, por favor introduce una fecha en formato DD/MM/YYYY.",
});
extend("dateIsNotGreaterThanToday", {
  validate: (value) => {
    let momentDate = moment(value, "DD/MM/YYYY");
    let now = moment();
    let pickedDateIsLowerThantNow = now.isAfter(momentDate);
    return pickedDateIsLowerThantNow;
  },
  message: "Lo siento, pero todavía no aceptamos personas del futuro.",
});

@Component({
  components: {
      LoadingButton,
      Multiselect
  },
})
export default class BudgetConfigModal extends Vue {
  @Getter("Budget/getShowProfileModal") showProfileModal!: boolean;
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Asset/getCurrencies") currencies!: Currency[];
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  myFiles: any[] = [];
  isLoading = false;
  activeTab = 0;
  phoneMask: string = "+1 (999) 999-9999";
  cedulaMask: string = "999-9999999-9";
  changePassModel = new ChangePasswordModel();
  localUserInfo: UserModel = new UserModel("", "", "", "");
  showIncorrectPasswordError = false;
  selectedTipoDocumento = "";
  tipoDocumentos = ["Cedula", "Pasaporte"]
  selectedCurrency = {} as Currency

  get userProfilePicture() {
    return (
      this.user.profilePicture ||
      "https://avatars.dicebear.com/api/initials/" + this.user.nombre + ".svg"
    );
  }

  @Watch("defaultCurrency")
  setCurrency(savedCurrency: Currency) {
    this.selectedCurrency = {
      ...savedCurrency
    }
  }

  get passwordsMatch(): boolean {
    return (
      this.changePassModel.newPassword ==
        this.changePassModel.newPasswordConfirm ||
      !this.changePassModel.newPasswordConfirm
    );
  }

  get showCurrentPassIsSameAsNewError(): boolean {
    return !!this.changePassModel.currentPassword 
          && (this.changePassModel.newPassword == this.changePassModel.currentPassword);
  }

  get selectedTipoDocIsCedula() {
    return this.selectedTipoDocumento == "Cedula";
  }

  @Watch("showProfileModal")
  onShowProfileModalChange(newValue: boolean) {
    // if (newValue) this.$bvModal.show("modalEnrollCateg");
    // else this.$bvModal.hide("modalEnrollCateg");
    if (newValue) {
      $("#configModal .tab-pane").removeClass("show active");
      $("#list-home").addClass("show active");
      //
      $("#configModal .list-group-item").removeClass("active");
      $("#list-home-trigger").addClass("active");
      //
      ($("#configModal") as any).modal("show");
    } 
    else {
      ($("#configModal") as any).modal("hide");
      this.initProfileForm();
    } 
    //
  }

  @Watch("selectedTipoDocumento")
  onSelectedTipoDocChange(newValue: string) {
    // Reset input value
    this.localUserInfo.documentId = "";
    //
    if (newValue == "Cedula") {
      this.maskCedulainput();
    } else {
      this.removeMaskFromCedulaInput();
    }
  }

  mounted() {
    this.initProfileForm();
  }

  initProfileForm() {
    this.localUserInfo = { ...this.user };
    if (this.defaultCurrency) this.selectedCurrency = {...this.defaultCurrency};
    //
    Inputmask(this.phoneMask, { clearMaskOnLostFocus: false }).mask(
      document.getElementById("telInput")
    );

    this.activateMaskOnDatePicker();
    
    var selectedTipoDocIsCedula = this.checkIfDocIdIsCedula(this.localUserInfo.documentId);
    if (selectedTipoDocIsCedula 
        || !this.localUserInfo.documentId) {
      //Si el tipo Doc es Cedula o no se ha llenado el campo, set Cedula como selected
      this.selectedTipoDocumento = "Cedula";
    } else {
      this.selectedTipoDocumento = "Pasaporte";
    }
    //We have to reset the documentId in the localUserInfo, because tipoDocumentoWatch
    //Clean it when we set selectedTipoDocumento
    Vue.nextTick(() => {
      this.localUserInfo.documentId = this.user.documentId;
    });
  }

  activateMaskOnDatePicker() {
    Inputmask("99/99/9999", 
            { 
              clearMaskOnLostFocus: true, 
            })
            .mask(document.getElementById("birthDateInput"));
  }

  checkIfDocIdIsCedula(docId: string | undefined) {
    let onlyNumbersDocId = docId?.match(/\d+/g)?.join('') ?? "";
    return validateDominicanId(onlyNumbersDocId);
  }

  maskCedulainput(){
    Inputmask(this.cedulaMask, { clearMaskOnLostFocus: false }).mask(
        document.getElementById("docId")
      );
  }

  removeMaskFromCedulaInput() {
    Inputmask.remove(document.getElementById("docId"));
  }

  changeActiveTab(tabIndex: number) {
    this.activeTab = tabIndex;
  }

  async saveChanges() {
    switch (this.activeTab) {
      case 0:
        await this.saveProfileInfo();
        break;
      case 2:
        await this.saveSecurityInfo();
        break;
    }
  }

  async saveProfileInfo() {
    let profileFormIsValid = await (this.$refs
      .profileFormValidator as any).validate();
    if (profileFormIsValid) {
      this.isLoading = true;
      const user = await Auth.currentAuthenticatedUser();
      try {
        let phone = this.localUserInfo.telefono
            ? this.localUserInfo.telefono
            : "";
        //
        var onlyNumbersPhone = phone.match(/\d+/g)?.join('') || "";
        if (onlyNumbersPhone) {
          onlyNumbersPhone = "+" + onlyNumbersPhone;
        }
        let documentId = this.localUserInfo.documentId || "";
        //
        if (this.checkIfDocIdIsCedula(this.localUserInfo.documentId)) {
          documentId = documentId?.match(/\d+/g)?.join('') ?? "";
        }
        
        await Auth.updateUserAttributes(user, {
          name: this.localUserInfo.nombre,
          phone_number: onlyNumbersPhone,
          preferred_username: documentId,
          birthdate: this.localUserInfo.fechaNacimiento,
          gender: this.localUserInfo.sexo,
          'custom:currency': this.selectedCurrency.code
        });
        this.$toast.open({
          type: "success",
          message: "Operación exitosa!",
          position: "top-right",
        });
        this.$store.dispatch(
          "IdentityManagement/userInfoUpdated",
          {...this.localUserInfo} //Send copy of object
        );
        this.$store.dispatch("Asset/changeDefaultCurrency", this.selectedCurrency.code);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async saveSecurityInfo() {
    let securityFormIsValid = await (this.$refs
      .securityFormValidator as any).validate();
    if (securityFormIsValid 
        && this.passwordsMatch
        && !this.showCurrentPassIsSameAsNewError) {
      this.isLoading = true;
      //
      const user = await Auth.currentAuthenticatedUser();
      try {
        console.log(this.phoneMask);
        await Auth.changePassword(
          user,
          this.changePassModel.currentPassword,
          this.changePassModel.newPassword
        );
        this.$toast.open({
          type: "success",
          message: "Operación exitosa!",
          position: "top-right",
        });
      } catch (error) {
        if (error.code == "NotAuthorizedException") {
          this.showIncorrectPasswordError = true;
        } else {
          console.error(error);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }

  addImage() {
    (this.$refs.pond as any).browse();
  }
  handleFilePondInit() {
    console.log("FilePond has initialized");
  }

  closeProfileModal() {
    this.$store.dispatch("Budget/hideProfileModal");
  }

}
