
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class EmptyStateDashboard extends Vue{
  @Prop() icon!: string;
  @Prop({ default:'align-center mt-4 mb-lg-5' }) classes!: string;
  @Prop({ default:'' }) className!: string;

  getImgUrl(icon: string) {
      return require('@/assets/img/svg/' + icon + '.svg');
  }
}
