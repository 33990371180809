import { render, staticRenderFns } from "./MobileFinancialHealth.vue?vue&type=template&id=2641adae&scoped=true&"
import script from "./MobileFinancialHealth.vue?vue&type=script&lang=ts&"
export * from "./MobileFinancialHealth.vue?vue&type=script&lang=ts&"
import style0 from "./MobileFinancialHealth.vue?vue&type=style&index=0&id=2641adae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2641adae",
  null
  
)

export default component.exports