<template>
<div class="pt-4 pb-1">
  <div @click="selectCard" class="loan-card-container" :class="{'selected-card': selected}" >
    <img class="edit-button" @click="editCurrentLoan" src="@/assets/img/svg/edit.svg" alt="" v-if="selected">
    <div class="loan-card-header">
      <div class="">
        <img src="https://www.impulsapopular.com/wp-content/uploads/2020/08/banco_popular-01.png" style="width: 40px;" alt="Icon">
      </div>
    <div class="loan-card-header-text">
      <p class="bank-name">{{ this.bankName }}</p>
      <p class="account-denom">Cuenta {{ this.code }}</p>
    </div>
  </div>
  <div>
    <p class="mb-0 loan-card-text-15">Límite de la línea en DOP</p>
    <p class="fs-8"><b>DOP {{ formatNumber(this.limitAmount) }}.00</b></p>
  </div>
  <div>
    <p class="mb-0 loan-card-text-15">Balance disponible DOP</p>
    <p class="fs-8"><b>DOP {{ formatNumber(this.available) }}.00</b></p>
  </div>
  <div class="d-flex d-flex-between" style="justify-content: space-between;">
    <div>
      <p class="mb-0 loan-card-text-15">Consumido DOP</p>
      <p class="fs-8" style="color: #BF6D6D;"><b>DOP {{ formatNumber(this.consumed) }}.00</b></p>
    </div>
    <div>
      <p class="mb-0 loan-card-text-15">Interés</p>
      <p class="fs-8"><b>{{ this.interestRate }}%</b></p>
    </div>
  </div>
  <div class="d-flex d-flex-between" style="justify-content: space-between;">
    <div>
      <p class="mb-0 loan-card-text-15">Monto cuota</p>
      <p style="fs-6" class="fs-7"><b>DOP {{ formatNumber(this.paymentAmount) }}.00</b></p>
    </div>
    <div>
      <p class="mb-0 loan-card-text-15" style="textAlign: right;">Fecha de pago</p>
      <p class="align-right fs-7"><b>{{ this.paymentDate }}</b></p>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
export default {
  props: ['bankName', 'code','denomination','limitAmount','available','consumed','paymentAmount','interestRate','paymentDate', 'selected', 'id'],
  methods: {
    editCurrentLoan() {
      this.$emit('edit-credit-line');
    },
    selectCard() {
      this.$emit('toggle-select', this.id);
    },
    formatNumber(num) {
      return parseInt(num).toLocaleString('en-US', {minimumFractionDigits: 2})
    }
  }
}
</script>

<style>
@media screen and (max-width:450px) {
  .loan-card-container {
    min-width: none !important;
  }
}

.loan-card-container {
  position: relative;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #CEE6FF;
	/* min-width: 352px; */
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 12px;
	margin-right: 35px;
}
.loan-card-container:hover {
  box-shadow: var(--hover-shadow);
}
.loan-card-text-15 {
  font-size: 0.8em;
}
.loan-card-text-13 {
  font-size: 0.7em;
}
.loan-card-header {
	display: flex;
}
.loan-card-header-text {
	margin-left: 10px;
}
.bank-name {
	margin-bottom: 0px;
  font-size: 1em;
  font-weight: 600;
}
.loan-card-content {
	display: flex;
	justify-content: space-between;
}
.account-denom {
  font-size: 0.8em;
}

.edit-button {
  width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -15px;
    right: 0;
    left: calc(88%);
}
</style>