
import { Component, Vue, Prop } from "vue-property-decorator";
import { BudgetCategory, BudgetSubCategory, CategoryType } from "@/models/Budget/BudgetCategory";
import { Getter } from "vuex-class";
import BudgetConfiguration from "@/models/Budget/BudgetConfiguration";
import Swal from 'sweetalert2';

@Component({})
export default class BudgetCategoryEmptyState extends Vue {
  @Prop({default: () => []}) skipCategories!: BudgetCategory[];
  @Getter("Budget/getCategoryCatalogByType") categories!: BudgetCategory[];
  @Getter('Budget/getBudgetConfiguration') budgetConfiguration!: BudgetConfiguration;
  @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];

  get availableCategories() {
      if (this.categories) {
        let list = this.categories.filter(c => {
            return !this.skipCategories.some(budgetCategory => budgetCategory.uuid == c.uuid);
        });
        return list;
      }
      return []
  }

  shortenCategoryName(category: string) {
    if (category == 'Ahorro e Inversión') return 'Ahorro/Inv'
    if (category == 'Pago Deudas') return 'Deudas'
    if (category == 'Donaciones') return 'Donación'
    if (category == 'Alimentación') return 'Alimen...'
    if (category == 'Personales') return 'Personal'
    return category;
  }

  canAddMoreCategories(): boolean {
    return true;
        // let maxCategorias = this.budgetConfiguration.maxCategorias;

        // return this.budgetCategories.length < maxCategorias;
  }

  mapCategoryToSVG(category: string) {
    const prefix = 'cat-empty'
    if (category == 'alimentacion') {
      return `${prefix}-`
    }
  }

  getImgUrl(category: string) {
    let lower = category.toLowerCase();
    if (lower == 'ahorro e inversión') {
      return require('@/assets/img/svg/cat-empty-ahorros.svg');
    }
    if (lower == 'alimentación') {
      return require('@/assets/img/svg/cat-empty-alimentacion.svg');
    }
    if (lower == 'educación') {
      return require('@/assets/img/svg/cat-empty-educacion.svg');
    }
    if (lower == 'pago deudas') {
      return require('@/assets/img/svg/cat-empty-pagodeuda.svg');
    }
    return require('@/assets/img/svg/cat-empty-' + lower + '.svg');
  } 

  addCategoryToBudget(category: BudgetCategory) {
        let budgetCategory = {} as BudgetCategory;
        let isValid = this.canAddMoreCategories();
        if (!isValid) {
            this.printNoMoreCategoryAlert();
            return;
        }
        budgetCategory.alias = category.name;
        budgetCategory.category = category.uuid;
        this.$emit("onCategorySelected", budgetCategory);
    }

    printNoMoreCategoryAlert() {
        Swal.fire({
            titleText: 'Aviso',
            text: 'Por el momento el limite de categorias que puedes agregar es de '+this.budgetConfiguration.maxCategorias,
            icon: 'warning',
            confirmButtonText: 'De acuerdo'
        })
    }
}
