
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BudgetCategory, BudgetSubCategory, CategoryType } from "@/models/Budget/BudgetCategory";

@Component({
    components: {
    }
})
export default class DistributionSummaryCategoryEntry extends Vue {
    @Prop() category!: BudgetCategory;

    get getCategoryName() {
        if (this.category.alias == undefined || this.category.alias == '' || this.category.category == null) {
          return this.category.category;
        }
        return this.category.alias;
    }

    get totalMontoPresupuestado() {
        let montos = this.category?.subcategories?.map(x => x.budgeted);
        if (!montos || montos.length == 0)
            return 0;

        return montos.reduce((a, b) => a+b);
    }
    get totalMontoEjecutado() {
        let montos = this.category.subcategories?.map(x => x.executed);
        if (!montos || montos.length == 0)
            return 0;
        return montos.reduce((a, b) => a+b);
    }

    get variation() {
        return this.totalMontoPresupuestado - this.totalMontoEjecutado;
    }
    
}
