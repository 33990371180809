
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Plan as PlanModel, PlanFeatureGrouping, PlanRequest } from "@/models/Plans"
import { Getter } from "vuex-class";

@Component({})
export default class BenefitsTable extends Vue {
  @Prop() featurePlans! : PlanFeatureGrouping[]
  @Prop() isAnnual! : boolean;
  @Getter("Asset/getPlans") plans!: PlanModel[];
  @Getter("IdentityManagement/getUserPlan") userPlan!: string;
  @Getter('IdentityManagement/getUserSubscriptionStatus') subscriptionStatus!: string;
  isLoading = false

  get freePlan() {
      if (this.plans) {
        let freePlan = this.plans.find(p => p.name.toLowerCase() == 'gratis')
        if (freePlan) return freePlan.uuid
      }
      return ''
    }

  get userPlanName() {
    if (this.plans && this.userPlan) return this.plans.find(p => p.uuid == this.userPlan)?.name
    return ''
  }

    get subscriptionIsValid() {
      if (this.subscriptionStatus) return ['P', 'A'].includes(this.subscriptionStatus)
      return false
    }

    get isSubscriptionSuspended() {
      if (this.subscriptionStatus) return this.subscriptionStatus == 'L'
      return false
    }

  goToCustomerPortal() {
        this.$store.dispatch("IdentityManagement/getCustomerPortalUrl")
        .then(res => {
            if (res.url) {
                let a = document.createElement('a');
                a.target = "_blank";
                a.href = res.url
                a.click();
            }
        })
    }

  rows = [
    {
      description: 'Acceso al módulo de presupuesto',
      options: ['check', 'check', 'check']
    },
    {
      description: 'Presupuestos permitidos',
      options: ['6', '12', '24']
    },
    {
      description: 'Personalizar categorías y subcategorias',
      options: ['6', '12', '24']
    },
    {
      description: 'Agregar gasto e ingresos',
      options: ['check', 'check', 'check']
    },
    {
      description: 'Agregar cuenta de ahorro y/o corriente',
      options: ['check', 'Ilimitado', 'Ilimitado']
    },
    {
      description: 'Agregar tarjeta de crédito',
      options: ['check', 'Ilimitado', 'Ilimitado']
    },
    {
      description: 'Agregar préstamos',
      options: ['check', 'Ilimitado', 'Ilimitado']
    },
    {
      description: 'Registrar servicios (Agua, Luz, etc.)',
      options: ['check', 'Ilimitado', 'Ilimitado']
    },
    {
      description: 'Dashboard personalizado',
      options: ['check', 'check', 'check']
    },
    {
      description: 'Asesoría inicial con Caudall | Expertos',
      options: ['denied', 'check', 'check']
    },
    {
      description: 'Acceso a contratar asesoría con expertos',
      options: ['check', '10% desc.', '15% desc.']
    },
    {
      description: 'Acceso al Caudall | Academia. Mejora Salud...',
      options: ['Limitado', 'Ilimitado', 'Ilimitado']
    },
    {
      description: 'Soporte a través de tickets',
      options: ['24', '4', '2']
    },
    {
      description: 'Acceso al Caudall | Gym Financiero',
      options: ['0% desc.', '10% desc.', '15% desc.']
    },
    {
      description: 'Acceso a capacitaciones especiales',
      options: ['0% desc.', '10% desc.', '15% desc.']
    },
    
  ]

  parseNumeric(n: number | string) {
    return typeof n == 'string' ? parseFloat(n) : n;
  }

  isPreferredPlan(planName: string) {
    return planName.toLowerCase() === 'premium'
  }

  changePlan(planId: string) {
    if (planId == this.userPlan) return;

    let request = { to_plan: planId, period: this.isAnnual ? 'Y' : 'M'} as PlanRequest
    // if (planId == this.freePlan) this.isLoading = true;
    this.isLoading = true;
    this.$store.dispatch("IdentityManagement/requestChangePlan", request)
      .then((plan: PlanRequest) => {
        if (plan.checkout_url) {
            let a = document.createElement('a');
            a.target = "_blank";
            a.href = plan.checkout_url
            a.click();
          }

          this.$store.dispatch("IdentityManagement/userSubcription").then(() => this.isLoading = false)
          // if (planId == this.freePlan) {
          //   this.$store.dispatch("IdentityManagement/userSubcription").then(() => this.isLoading = false)
          // }
      })
   }

  isIcon(option: string) {
    return option.toLowerCase() === 'check' || option.toLowerCase() === 'denied'
  }

  changeMode() {
    this.$emit('change-type')
  }

  getComment(planName: string) {
      if (planName.toLowerCase() == 'gratis') return 'Básico'
      if (planName.toLowerCase() == 'premium') return 'Más utilizado'
      return 'Desbloquea todo el potencial'
  }

  getImgUrl(showIcon: boolean) {
    let icon = 'denied'
    if (showIcon) icon = 'green-check';
    return require('@/assets/img/svg/' + icon + '.svg');
  }

  formatPrice(price: string, discount: string, annual = false) {
    let value = this.parseNumeric(price);

    if (this.isAnnual) {
      let dct = this.parseNumeric(discount)
      value = parseFloat((value - (value * (dct / 100))).toFixed(2))
    }

    if (annual) value = parseFloat((value * 12).toFixed(2))
    return '$' + value
  }

  // formatPrice(price: string) {
  //   let value = price;
  //   if (this.isAnnual) {
  //       value = parseFloat((this.price - (this.price * (this.numericDiscount / 100))).toFixed(2))
  //   }
  //   return value
  // }

  // get plans() {
  //     return [
  //     {
  //       type: 'Gratis',
  //       typeSubtext: 'Descubre Caudall',
  //       typeComment: 'Básico',
  //       price: '$0.00',
  //       ctaMessage: 'Este es tu plan actualmente',
  //       message: !this.isMonthly ? '' : '',
  //     },
  //     {
  //       type: 'Premium',
  //       typeSubtext: this.isMonthly ? '+400 Usuarios lo recomiendan' : 'Un pago de $28.32 al año',
  //       typeComment: 'Más utilizado',
  //       price: this.isMonthly ? '$2.95' : '$2.65',
  //       ctaMessage: 'Seleccionar plan',
  //       message: !this.isMonthly ? '' : 'Un pago de $28.32 al año',
  //     },
  //     {
  //       type: 'Elite',
  //       typeSubtext: this.isMonthly ? '' : 'Un pago de $37.92 al año',
  //       typeComment: 'Desbloquea todo el potencial',
  //       price: this.isMonthly ? '$3.65' : '$3.15',
  //       ctaMessage: 'Seleccionar plan',
  //       message: !this.isMonthly ? '' : 'Un pago de $37.92 al año',
  //     },
  //   ]
  // }
}
