
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Service as ServiceUserCatalog } from "@/models/UserCatalog/UserCatalog"
import { ServiceType } from "@/models/Catalog/Catalog"
import ServiceSummaryCard from "@/components/assetsLiabilities/Services/ServiceSummaryCard.vue"
import UIkit from "uikit";
import { Currency } from '@/models/Common/Common'

@Component({
  components: {
    ServiceSummaryCard
  }
})
export default class ServiceSummary extends Vue {
  @Prop() isDesktopViewport!: boolean;
  @Getter("Asset/getServices") services! : ServiceType[]
  @Getter("Asset/getUserServices") userServices!: ServiceUserCatalog[]
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter("Asset/getShowServiceModal") showModal!: boolean
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Asset/getIsLoadingServices") isLoading!: boolean;
  loadedServices = false;
  isEditing = true

  @Watch("showModal")
  showServiceModal(value: boolean) {
    this.createNewService();
  }

  showServiceGeneralModal() {
    (this.$refs['serviceGeneralModal'] as any).show();
  }

  hideServiceGeneralModal() {
    (this.$refs['serviceGeneralModal'] as any).hide();
  }

  getImgUrl(icon: string) {
    if (!icon || icon == '') return 'https://via.placeholder.com/25'
      return require('@/assets/img/svg/' + icon + '.svg');
  }

  serviceTypes = [
    {
      icon: 'thunder-2-blue',
      text: 'Luz o energía eléctrica',
      id: '6ceb7824-78d1-4f20-8bea-253e95e63623'
    },
    {
      icon: 'water-blue',
      text: 'Agua',
      id: 'f5d2d7c1-24f5-4a3d-93dc-fdd75bb886d2'
    },
    {
      icon: 'truck-blue',
      text: 'Ayuntamiento ó recogida de basura',
      id: 'ea4432b4-2a24-48a1-b039-52d5c41c9952'
    },
    {
      icon: 'phone-blue',
      text: 'Telecomunicación (Internet, Teléfono, etc)',
      id: 'bb8bd13b-592c-4c66-9dc3-c2708e944d97'
    },
    {
      icon: 'school-blue',
      text: 'Educación',
      id: '82d7eb16-b2fa-40d2-a592-72711cc95499'
    },
    {
      icon: 'other-service-blue',
      text: 'Otros',
      id: '316d4cbb-c6b4-4ded-bb25-259c4bfca3e9'
    },
  ]

  formatAmount(amount: number | string, currency = this.defaultCurrency) {
    if (typeof amount == "string") amount = parseFloat(amount)
    return currency.code.toUpperCase() + ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
  }

  mounted() {
    if (this.userServices) {
      this.loadedServices = true;
    }
  }

  changeEditBttn() {
    this.isEditing = !this.isEditing
  }

  getServiceName(id: string) {
    return this.services.filter(serv => serv.uuid == id)[0].name
  }

  @Watch("userServices")
  changeCardComponent() {
    this.loadedServices = true
  }

  deleteService(id: string) {
    this.$store.dispatch("Asset/deleteService", { userId: this.user.id, serviceId: id })
  }
  
  openEditServiceModal(id: string) {
    let editingService = this.userServices.filter(service => service.uuid == id)[0];
    this.$root.$emit('editService', editingService);
    UIkit.modal(document.getElementById('add-new-service-modal')).show()
  }

  openServiceModal(serviceId: string) {
    this.$root.$emit('clearService');
    this.hideServiceGeneralModal();
    this.$root.$emit('assignService', serviceId);
    UIkit.modal(document.getElementById('add-new-service-modal')).show();
  }
  
  createNewService() {
    this.showServiceGeneralModal();
  }
}
