
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LoadingButton from "@/components/LoadingButton.vue";
import SideBar from "@/components/layout/SideBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import Shepherd from 'shepherd.js';
import BankAccount from "@/components/assetsLiabilities/bankAccounts/BankAccount.vue"
import AddProductModal from "@/components/assetsLiabilities/AddProductModal.vue"
import ProductSummary from "@/components/assetsLiabilities/ProductSummary.vue"
import AssetsTransactions from "@/components/assetsLiabilities/assetsTransactions/assetsTransactions.vue"
import ServiceSummary from "@/components/assetsLiabilities/Services/ServiceSummary.vue"
import CreditCard from "@/components/assetsLiabilities/creditCard/CreditCard.vue"
import UserModel from "@/models/IdentityManagement/UserModel";
import LoanMain from "@/components/assetsLiabilities/loan/LoanMain.vue"
import CreditLineMain from "@/components/assetsLiabilities/CreditLine/CreditLineMain.vue"
import MobileFinancialHealth from "@/components/dashboard/MobileFinancialHealth.vue"
import FinancialHealth from "@/components/dashboard/FinancialHealth.vue"
import DashboardSummary from "@/components/dashboard/DashboardSummary.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import UpcomingEvents from "@/components/dashboard/UpcomingEvents.vue";
import UpcomingPayments from "@/components/dashboard/UpcomingPayments.vue";
import Patrimony from "@/components/dashboard/Patrimony.vue";
import TransactionAggregateChart from "@/components/dashboard/TransactionAggregateChart.vue"
import TransactionTable from "@/components/dashboard/TransactionTable.vue"
import UserBudget from "@/models/Budget/UserBudget";
import "vue-select/dist/vue-select.css";
import DashboardService from "@/services/DashboardService"
import { Currency } from '@/models/Common/Common'
import moment from 'moment'

@Component({
    components: {
        LoadingButton,
        SideBar,
        TopBarAssets,
        AddProductModal,
        ProductSummary,
        BankAccount,
        AssetsTransactions,
        ServiceSummary,
        CreditCard,
        LoanMain,
        CreditLineMain,
        MobileFinancialHealth,
        FinancialHealth,
        DashboardSummary,
        NewTransactionModal,
        UpcomingEvents,
        Patrimony,
        UpcomingPayments,
        TransactionAggregateChart,
        TransactionTable
    }
})
export default class Dashboard extends Vue {
    dashboardService = new DashboardService();
    tourStarted = false;
    tour!: Shepherd.Tour;
    unsubscribeToActionEvent!: () => void;
    activeMobileTab = 0;
    isDesktopViewport = false;
    isMobileViewport = true;
    selectedType = 'all';
    assetLoaded: boolean = false;

    @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
    @Getter("Budget/getBudgetCategoryCount") budgetCategoryCount!: number;
    @Getter("Budget/getActiveBudget") activeBudget!: UserBudget;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("IdentityManagement/getUser") user!: UserModel;
    @Getter("Asset/getShowTransactionSectionStatus") showTransactions!: boolean;
    @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;

    @Getter("Dashboard/getAvailableBalance") available!: number;

    @Watch("activeBudget")
    onActiveUserBudgetChange() {
      this.$store.dispatch('Budget/getBudgetTransactions', {userId: this.user.id, budgetDate: this.activeBudget.date}) 
    }

    @Watch("defaultCurrency")
    loadDataOnDefaultCurrency(value: Currency) {
      if (value && value.code) {
        const currency = value.code.toLowerCase();
        this.loadDashboard(currency);
      }
      // this.$store.dispatch('Budget/getBudgetTransactions', {userId: this.user.id, budgetDate: this.activeBudget.fecha}) 
    } 

    changeFilter(type:string) {
      this.selectedType = type;
    }

    loadDashboard(code: String) {
      this.$store.dispatch("Dashboard/setGeneralBalance");
      this.$store.dispatch("Dashboard/setPatrimony");
      this.$store.dispatch("Dashboard/setFinancialHealth", this.user.correoElectronico);
      this.$store.dispatch("Dashboard/setDueServices");
      this.$store.dispatch("Dashboard/setDashboardTransactions", {userId: this.user.id, moneda: code});
      this.$store.dispatch("Dashboard/setGlobalTransactions");
      this.$store.dispatch("Dashboard/setEvents");
    }

    compareFilter(type:string) {
      return this.selectedType == type;
    }

    showProduct(productName: string) {
      if (!this.isDesktopViewport) return true;
      return productName == this.selectedType || this.selectedType == 'all'
    }

    createNewService() {
      this.$store.dispatch("Asset/toggleServiceModal", true);
    }

    hideModal() {
      (this.$refs['IBFModal-sm'] as any).hide();
    }

    showModal() {
      (this.$refs['IBFModal-sm'] as any).show();
    }

    get filterButtons() {
      return [
        {
          text: 'Todos',
          type: 'all',
          link: '#product-summary-component'
        },
        {
          text: 'Cuentas',
          type: 'bank-account',
          link: '#bank-account-component'
        },
        {
          text: 'Tarjetas de crédito',
          type: 'credit-card',
          link: '#credit-card-component'
        },
        // {
        //   text: 'Línea de Crédito',
        //   type: 'credit-line',
        //   link: '#credit-line-component'
        // },
        {
          text: 'Préstamos',
          type: 'loan',
          link: '#loan-component'
        },
      ]
    }

    created() {
      this.checkMobileViweport();
      this.$store.dispatch("Asset/assetCatalogLoaded");
      this.$store.dispatch("Dashboard/setPatrimony");
      this.$store.dispatch("Asset/userCatalogLoad", this.user.id);
      this.$store.dispatch("Budget/getBudgetConfig", this.user.id);
      this.$store.dispatch("Budget/getSkipTutorial");
      this.$store.dispatch("Budget/loadUserBudgets");
    }
    //
    mounted() {
        let script = document.createElement('script')
        let url = process.env.BASE_URL + "js/ui/budget.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);
        window.addEventListener('resize', this.checkWindowSize)
        window.addEventListener('resize', this.checkMobileViweport)
        this.checkWindowSize();
    }

    checkWindowSize() {
      if (window.innerWidth > 992) {
        this.isDesktopViewport = true;
      } else {
        this.isDesktopViewport = false;
      }
    }

    checkMobileViweport() {
      if (window.innerWidth > 500) {
        this.isMobileViewport = false;
      } else {
        this.isMobileViewport = true;
      }
    }
    
    changeActiveTab(tabNumber: number) {
        this.activeMobileTab = tabNumber;
    }
}
