
import { Vue, Component, Watch } from "vue-property-decorator";
import UpcomingEvent from '@/components/dashboard/Event.vue'
import EmptyStateDashboard from "../helpers/emptyStateDashboard.vue";
import { Event } from "@/models/"
import { Getter } from "vuex-class";
import moment from "moment"
 
@Component({
  components: {
    UpcomingEvent,
    EmptyStateDashboard
  }
})
export default class UpcomingEvents extends Vue {
  @Getter("Dashboard/getNextEvents") events!: Event[];
  @Getter("Dashboard/isLoadingEventUrl") isLoadingEventUrl!: boolean;
  @Getter("Dashboard/isDashboardLoaded") isLoaded!: boolean;
  modalEvent = {} as Event;
  initialLoad = false;
  descriptionTemplate = ""

  @Watch("events")
  toggleInitialLoad(value: Event[]) {
    if (!this.initialLoad) this.initialLoad = true;
  }

  setModalEvent(eventId: string) {
    this.modalEvent = this.events.filter((e: Event) => e.id == eventId)[0];
    this.showModal()
  }

  showModal() {
    (this.$refs['eventModal'] as any).show();
  }

  hideModal() {
    (this.$refs['eventModal'] as any).hide();
  }

  openUrl() {
    (this.$refs['eventModal'] as any).hide();
    const id = this.modalEvent.id + '-list';
    const el = document.getElementById(id) as HTMLAnchorElement;
    el.href = this.modalEvent.register_url;
    el.click()
  }
}
