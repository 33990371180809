
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class AccountSummaryOption extends Vue {
    @Prop({ default: '' }) title!: string;
    @Prop({ default: '' }) description!: string;
    @Prop({ default: '' }) icon!: string;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    isHover = false;

    getImgUrl(icon: string) {
        if (this.isHover) icon = icon + '-white'
        return require('@/assets/img/svg/' + icon + '.svg');
    }

		get marginTop() {
			if (this.$vnode.key) {
				return parseInt(this.$vnode.key as any) > 1 ? 'mt-xl-4' : ''
			}
		}

    selectedColor() {
        this.isHover = true;
    }

    unselectedColor() {
        this.isHover = false;
    }

    onClick() {
        if (this.title.toLowerCase() == 'Planes de Caudall'.toLowerCase()) this.$router.push('/plans')
        else if (this.title.toLowerCase() == 'Preferencias de mi cuenta'.toLowerCase()) this.showProfileModal();
        else this.goToCustomerPortal()
    }

    goToCustomerPortal() {
        this.$store.dispatch("IdentityManagement/getCustomerPortalUrl")
        .then(res => {
            if (res.url) {
                let a = document.createElement('a');
                a.target = "_blank";
                a.href = res.url
                a.click();
            }
        })
    }

    showProfileModal() {
      if (this.isMobile)
        $('#sidebar').removeClass('active');
      this.$store.dispatch("Budget/showProfileModal");
    }
}
