// export default class BudgetTransaction {
//     constructor(
//         public tipo: TransactionType,
//         public id: string = "",
//         public idSubCategoria: string = "",
//         public monto: number = 0,
//         public fechaTransaccion: string = "",
//         public descripcion: string = "",
//         public moneda: string = "",
//         public cuentaOrigen: string = "",
//         public subCategoriaPorCatalogoUsuario: boolean = false,
//         public cuentaDestino: string = ""
//     ) {}
// }

import { Currency } from "../Common";

// export default interface BudgetTransaction {
//     tipo: TransactionType,
//     id: string;
//     idSubCategoria: string;
//     monto: number;
//     fechaTransaccion: string;
//     descripcion: string;
//     moneda: string;
//     cuentaOrigen: string;
//     subCategoriaPorCatalogoUsuario: boolean;
//     cuentaDestino: string;
// }

export default interface BudgetTransaction {
    uuid: string;
    sub_category: string;
    type: TransactionType,
    from_account: string;
    to_account: string;
    description: string;
    currency: string | Currency;
    rate: number;
    amt: number;
    date: string;
    subscription?: string;
}

export enum TransactionType {
    Income = "I",
    Expense = "G",
    Payment = "P"
}