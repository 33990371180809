
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import SideBar from "@/components/layout/SideBar.vue";
import InnerFooter from "@/components/layout/InnerFooter.vue";
import TopBar from "@/components/layout/TopBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import BudgetCateogryFiller from "@/components/budget/BudgetCategoryFiller.vue";
import DistributionChart  from "@/components/budget/DistributionChart.vue";
import DistributionSummary  from "@/components/budget/DistributionSummary.vue";
import TransactionSummary from "@/components/budget/TransactionSummary.vue";
import GeneralSummary from "@/components/budget/GeneralSummary.vue";
import Shepherd from 'shepherd.js';
import UserBudget from "@/models/Budget/UserBudget";
import EnrollmentCategoryModal from "@/components/budget/EnrollmentCategoryModal.vue";
import NewBudgetModal from "@/components/budget/NewBudgetModal.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import TransactionTable from "@/components/dashboard/TransactionTable.vue";
import DesktopSideColumn from "@/components/budget/DesktopSideColumn.vue"
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import AccountSummaryOption from "@/components/accountSummary/AccountSummaryOptions.vue"
import Plan from "@/components/accountSummary/Plan.vue"
import { Plan as PlanModel, Feature, FlatPlanFeature, PlanFeatureGrouping, PlanRequest } from "@/models/Plans"
import BenefitsTable from "@/components/accountSummary/BenefitsTable.vue"
import Swal from 'sweetalert2';

interface FeatureSort {
  code: string;
  name: string;
}

@Component({
  components: {
      LoadingButton,
      SideBar,
      TopBar,
      InnerFooter,
      BudgetCateogryFiller,
      DistributionChart,
      DistributionSummary,
      TransactionSummary,
      GeneralSummary,
      EnrollmentCategoryModal,
      NewBudgetModal,
      TopBarAssets,
      NewTransactionModal,
      TransactionTable,
      DesktopSideColumn,
      BudgetMonthSelection,
      ConfirmTooltip,
      AccountSummaryOption,
      Plan,
      BenefitsTable
  }
})
export default class Plans extends Vue {
  @Getter("Asset/getPlans") plans!: PlanModel[];
  @Getter("IdentityManagement/getUserPlan") userPlan!: string;
  @Getter('IdentityManagement/getUserSubscriptionStatus') subscriptionStatus!: string;
  elementHeight = 0;
  isLoading = false
  
    isAnnual = false

    get userPlanName() {
    if (this.plans && this.userPlan) return this.plans.find(p => p.uuid == this.userPlan)?.name
    return ''
  }

    get subscriptionIsValid() {
      if (this.subscriptionStatus) return ['P', 'A'].includes(this.subscriptionStatus)
      return false
    }

    get isSubscriptionSuspended() {
      if (this.subscriptionStatus) return this.subscriptionStatus == 'L'
      return false
    }

    changePlanType() {
      this.isAnnual = !this.isAnnual
    }

     goToCustomerPortal() {
        this.$store.dispatch("IdentityManagement/getCustomerPortalUrl")
        .then(res => {
            if (res.url) {
                let a = document.createElement('a');
                a.target = "_blank";
                a.href = res.url
                a.click();
            }
        })
    }

    get flatPlanFeatures() {
      if (this.plans) {
        let data = [] as FlatPlanFeature[]
        for (let plan of this.plans) {
          for (let feature of plan.features) {
            data.push({
              uuid: feature.uuid,
              quantity: feature.quantity,
              price: feature.price,
              planId: plan.uuid,
              featureCode: feature.feature.code,
              featureName: feature.feature.name,
              featureDescription: feature.description,
              featureType: feature.feature.type
            })
          }
        }
        return data
      }
      return []
    }

    changePlan(planId: string) {
      if (planId == this.userPlan) return;

      let request = { to_plan: planId, period: this.isAnnual ? 'Y' : 'M'} as PlanRequest
      // if (planId == this.freePlan) this.isLoading = true;
      this.isLoading = true;
      this.$store.dispatch("IdentityManagement/requestChangePlan", request)
        .then((plan: PlanRequest) => {
          if (plan.checkout_url) {
            let a = document.createElement('a');
            a.target = "_blank";
            a.href = plan.checkout_url
            a.click();
          }

          this.$store.dispatch("IdentityManagement/userSubcription").then(() => this.isLoading = false)
          // if (planId == this.freePlan) {
          //   this.$store.dispatch("IdentityManagement/userSubcription").then(() => this.isLoading = false)
          // }
        })
   }

    get features() {
      if (this.flatPlanFeatures) {
        let data = [] as FeatureSort[]
        for (let fp of this.flatPlanFeatures) {
          let codes = data.map(f => f.code);
          if (!codes.includes(fp.featureCode)) data.push({ name: fp.featureName, code: fp.featureCode })
        }
        return data
      }
      return []
    }

    get featureToDisplay() {
      if (this.features && this.flatPlanFeatures && this.plans) {
        let data = [] as PlanFeatureGrouping[];
        for (let feature of this.features) {
          let features = [] as FlatPlanFeature[]
          for (let plan of this.plans) {
            let el = this.flatPlanFeatures.find(fp => fp.planId == plan.uuid && fp.featureCode == feature.code)

            if (el) features.push(el)
            else features.push({} as FlatPlanFeature)
          }
          data.push(
            {
              name: feature.name,
              code: feature.code,
              feature: features
            }
          )
        }
        return data
      }
      return []
    }

    get freePlan() {
      if (this.plans) {
        let freePlan = this.plans.find(p => p.name.toLowerCase() == 'gratis')
        if (freePlan) return freePlan.uuid
      }
      return ''
    }

    toMyAccount() {
      this.$router.push('/account')
    }

    mounted() {
      this.$store.dispatch("IdentityManagement/userSubcription");
      this.$store.dispatch("Asset/getAllPlans");
      const nav = document.getElementById('comparison-cat-container');
      const navYX = nav ? nav.getBoundingClientRect().y : 0; 

      window.addEventListener('scroll', () => {
        const nav = document.getElementById('comparison-cat-container');
        const navY = nav ? nav.getBoundingClientRect().y : 0; 
        if (!this.elementHeight) this.elementHeight = navY;
        if (window.scrollY >= this.elementHeight) {
          const nav = document.getElementById('comparison-cat-container');
          nav?.classList.add('fixed-top');
        } else {
          const nav = document.getElementById('comparison-cat-container');
          nav?.classList.remove('fixed-top');
        }
      })
    }
}
