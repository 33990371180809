
import { Component, Vue } from "vue-property-decorator";
import { SurveyModel, PreguntasModel, OpcionesModel } from "@/models/Enrollment/SurveyServiceResponse";
import EnrollmentService from "@/services/EnrollmentService";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import { RouterLink } from 'vue-router'


@Component({
    components: {
        LoadingButton,
        RouterLink
    }
})
export default class Enrollment extends Vue {
    enrollmentService = new EnrollmentService();
    survey: SurveyModel | undefined | null = null;
    isLoading = false;
    activeQuestion: PreguntasModel | undefined | null = null;
    fadeOutLeftAnimationIndex = -1;
    fadeOutRightAnimationIndex = -1;
    fadeInRightAnimationIndex = -1;
    fadeInLeftAnimationIndex = -1;
    //
    @Getter('IdentityManagement/getUser') user!: UserModel;
    @Getter("IdentityManagement/getHasAccessToDashboard") hasAccessToDashboard!: boolean;
    get preguntasEncuesta() {
        if (!this.survey)
            return [];
        
        return this.survey.secciones[0]?.preguntas;
    }

    get activeQuestionIndex(): number {
        if (this.preguntasEncuesta && this.activeQuestion)
            return this.preguntasEncuesta.indexOf(this.activeQuestion);

        return -1
    }
    mounted() {
        //
        this.enrollmentService.GetEncuesta()
            .then((response) => {
                if (response.data.statusCode == 200) {
                    this.survey = response.data.entity?.encuesta;
                    if (this.preguntasEncuesta)
                        this.activeQuestion = this.preguntasEncuesta[0];
                }
            });
    }
    selectOrUnselectOption(option: OpcionesModel) {
        //if option is selected, unselect it. Else select it
        option.valor = !option.valor;
    }
    submitSelection() {
        this.isLoading = true;
        var request = {
            idUsuario: this.user.id,
            encuesta: this.survey
        }
        if (this.survey)
            this.enrollmentService
                .SaveUserSurveySelection(this.user.id, this.survey)
                    .then(res => {
                        if (this.hasAccessToDashboard) this.goToDashboard();
                        else this.goToBudget();
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
    }

    moveNextOrSubmit() {
        if (this.activeQuestionIndex == ((this.preguntasEncuesta?.length || 0) - 1)){
            //If this is the last step, submit
            this.submitSelection();
            return;
        }

        //Set animation
        this.fadeOutLeftAnimationIndex = this.activeQuestionIndex;
        //Wait for animation to end
        setTimeout(() => {
            let nextIndex = this.activeQuestionIndex + 1;
            if (this.preguntasEncuesta) {
                // this.fadeInRightAnimationIndex = nextIndex;
                this.activeQuestion = this.preguntasEncuesta[nextIndex];
            }
            //remove class from previus step
            this.fadeOutLeftAnimationIndex = -1;
        }, 500)
        
        
    }

    movePrevious() {
        //Set animation
        this.fadeOutRightAnimationIndex = this.activeQuestionIndex;
        //Wait for animation to end
        setTimeout(() => {
            let previousIndex = this.activeQuestionIndex - 1;
            if (this.preguntasEncuesta) {
                this.fadeInLeftAnimationIndex = previousIndex;
                this.activeQuestion = this.preguntasEncuesta[previousIndex];
            }
            

            //remove class from previus step
            this.fadeOutRightAnimationIndex = -1;
        }, 500)
        
    }

    goToDashboard() {
        this.$router.push("/dashboard");
    }

    goToBudget() {
        this.$router.push("/budget");
    }

}
