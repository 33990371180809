
import { Component, Vue, Watch } from "vue-property-decorator";
import MobileDashboardSummaryCard from "./MobileDashboardSummaryCard.vue";
import DashboardSummaryCard from "./DashboardSummaryCard.vue";
import { Getter } from "vuex-class";
import { Currency } from '@/models/Common/Common'

@Component({
  components: {
    DashboardSummaryCard,
    MobileDashboardSummaryCard
  }
})
export default class DashboardSummary extends Vue {
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Dashboard/getAvailableBalance") available!: number;
  @Getter("Dashboard/getExpenseBalance") expenses!: number;
  @Getter("Dashboard/getExpensesDOP") expensesDOP!: number;
  @Getter("Dashboard/getExpensesUSD") expensesUSD!: number;
  @Getter("Dashboard/getIncomeBalance") income!: number;

  get currencyCode() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.code
    }
    return 'DOP'
  }

  get currencyName() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.name
    }
    return ''
  }
  
  get balanceSummaryData() {
    return [
    {
      title: 'Ingresos',
      titleColor: 'blue',
      text: 'Lo que he ganado',
      amount: this.income,
      currencyCode: this.currencyCode,
      iconBkgColor: '#DEFFE8',
      footerText: 'Lo que he ganado'
    },
    {
      title: 'Gastos',
      titleColor: 'red',
      text: 'Lo que he consumido',
      amount: this.expenses,
      currencyCode: this.currencyCode,
      iconBkgColor: '#FFD2D3',
      footerText: 'Lo que he consumido'
    },
    {
      title: 'Disponible',
      titleColor: '#58C879',
      text: 'Invertir, comprar ó ahorrar',
      amount: this.available,
      currencyCode: this.currencyCode,
      iconBkgColor: '#58C879',
      footerText: 'A tu favor para invertir, comprar ó ahorrar'
    },
  ]
  } 

  get mobileBalanceSummaryData() {
    return [
    {
      title: 'Ingresos',
      titleColor: 'blue',
      text: 'Lo que he ganado',
      amount: this.income,
      currencyCode: this.currencyCode,
      iconBkgColor: '#DEFFE8',
      footerText: 'Lo que he ganado'
    },
    {
      title: 'Gastos',
      titleColor: 'red',
      text: 'Lo que he consumido',
      amount: this.expenses,
      currencyCode: this.currencyCode,
      iconBkgColor: '#FFD2D3',
      footerText: 'Lo que he consumido'
    },
    {
      title: 'Disponible',
      titleColor: '#58C879',
      text: 'A tu favor para invertir, comprar o ahorrar',
      amount: this.available,
      currencyCode: this.currencyCode,
      iconBkgColor: '#58C879',
      footerText: 'A tu favor para invertir, comprar ó ahorrar'
    }
  ]
  } 
}
