
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import CreditCardEl from "@/components/assetsLiabilities/creditCard/CreditCardEl.vue"
import { CreditCard as CreditCardUserCatalog, CreditCardBalance } from "@/models/UserCatalog/UserCatalog"
import CarouselArrows from '@/components/helpers/carousel-arrows.vue'
import EmptyState from '@/components/helpers/emptyState.vue'
import { ContainerComponent } from '@/components/assetsLiabilities/mixins/index'
import UIkit from 'uikit'
import { Currency, Bank } from "@/models";
import { isBigNumber } from "@/utils/HelperMethods";

@Component({
  components: {
    CreditCardEl,
    CarouselArrows,
    EmptyState
  }
})
export default class CreditCard extends Mixins(ContainerComponent) {
  // props: ['bank-name', 'denomination', 'account-name', 'account-type', 'remaining-balance', 'isDesktopViewport'],
bkgColor = '#58C8791C'
@Prop() isDesktopViewport!: boolean;
@Getter("Asset/getUserCreditCards") creditCards!: CreditCardUserCatalog[]
@Getter("Asset/getTotalCreditDOP") totalCreditDOP!: number
@Getter("Asset/getTotalCreditUSD") totalCreditUSD!: number
@Getter("Asset/getTotalConsumedDOP") totalConsumedDOP!: number
@Getter("Asset/getTotalConsumedUSD") totalConsumedUSD!: number
@Getter('Asset/getSelectedAccount') selectedCard! : String;
@Getter('Asset/getDefaultCurrency') defaultCurrency! : Currency;
@Getter('Asset/getDOPCurrency') DOPCurrency! : string;
@Getter("IdentityManagement/getUser") user!: UserModel;
@Getter('Asset/getBanks') banks! : Bank[];
textColor = '#1D7838'
iconColor = '#58C879'
loadedcreditCards = false
showEditOnDoubleBalance = false
isBigNumber = isBigNumber

@Watch("creditCards")
  changeCardComponent() {
    this.loadedcreditCards = true
}

getDenomination(currency: string | object) {
  if (typeof currency == 'object') return (currency as any).uuid == this.DOPCurrency ? 'DOP' : 'USD'
  return currency == this.DOPCurrency ? 'DOP' : 'USD'
}

getSelectedCardBalance(balances: CreditCardBalance[]) {
  return balances.filter((b: CreditCardBalance) => b.uuid == this.selectedCard).length > 0 && this.showEditOnDoubleBalance
}

mounted() {
    if (this.creditCards) {
      this.loadedcreditCards = true
    }
  }

isDoubleBalance(creditCard: CreditCardUserCatalog) {
  return false;
  // return creditCard.saldos.length > 1;
}

get creditCardData()  {
  return [
      {
        title: 'Crédito total en dólares',
        formattedAmount: `USD ${this.formatNumber(this.totalCreditUSD)}`,
        amt: this.totalCreditUSD,
        denom: `Consumido USD ${this.formatNumber(this.totalConsumedUSD)}`,
        currency: 'usd'
      },
      {
        title: 'Crédito total en pesos',
        formattedAmount: `DOP ${this.formatNumber(this.totalCreditDOP)}`,
        amt: this.totalCreditDOP,
        denom: `Consumido DOP ${this.formatNumber(this.totalConsumedDOP)}`,
        currency: 'dop'
      }
    ]
}
selectedType = 'all'

  selectCard(idCuenta: string, code? : string, openEdit = true) {
    const creditCard = this.creditCards.find((b: any) => b.uuid == idCuenta);
    let selectedBalance = (creditCard?.balances[0] as any)

    if (code) {
      selectedBalance = creditCard?.balances.filter(c => (c.currency as any).code.toLowerCase() == code.toLowerCase())[0]
    }

    this.showEditOnDoubleBalance = openEdit
    this.loadTransactions(selectedBalance.uuid, 'credit_card_balance');
    this.selectedAccountCurrency(selectedBalance.currency.code.toUpperCase());
  }

  loadSelectedAccountCurrency(code: string) {
    this.selectedAccountCurrency(code);
  }
	
  formatNumber(n: number) {
    return n.toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  createNewCreditCard() {
    this.$root.$emit('clearCreditCard');
    UIkit.modal(document.getElementById('credit-card-modal')).show();
  }

  getBankName(bankId: string, property: keyof Bank = 'short_name') {
    if (this.banks) {
      let bank = this.banks.find(bank => bank.uuid === bankId);
      if (bank) return bank[property]
    }
    return ''
  }

  get filteredCreditCards() {
    return this.creditCards;
    // return this.creditCards.filter(
    //   cc => cc.balances.length > 1 || 
    //   (cc.balances.length == 1 && cc.balances[0].currency == this.defaultCurrency.uuid))
  }

  get filteredCreditCardSummary() {
    return this.creditCardData;
      // if (this.selectedType == 'all' || this.selectedType == 'double') {
      //   return this.creditCardData.filter(ccData => ccData.currency.toLowerCase() === this.defaultCurrency.code?.toLowerCase());
      // }
      // return this.creditCardData.filter(ccData => ccData.currency.toLowerCase() === this.defaultCurrency.code?.toLowerCase());
    }
  
  deleteSelectedAccount(id: string) {
    this.$store.dispatch("Asset/deleteCreditCard", { userId: this.user.id, creditCardId: id });
  }

  filterCardType(text: string) {
      this.selectedType = text;
  }
  creditCardTypeLength(type: string) {
    return 0
    // if (type == 'all') {
    //   return this.creditCards.length;
    // }
    // if (type == 'double') {
    //   return this.creditCards.filter(cc => cc.saldos.length > 1).length;
    // }
    // return this.creditCards.filter(c => c.saldos.length == 1)
    //         .filter(fc => fc.saldos[0].moneda.code.toLowerCase() == type).length
  }
  scrollSummaryCard(direction: string, className='credit-card-summary-container') {
    var cardSumEl = document.getElementsByClassName(className)[0];

    if (direction == 'left') {
      cardSumEl.scrollBy(-60, 0);
    } else {
      cardSumEl.scrollBy(60, 0);
    }
  }
  openEditAccountModal(editingId: string) {
    let editingCreditCard = this.creditCards.filter(creditCards => creditCards.uuid == editingId)[0];
    this.$root.$emit('editCreditCard', editingCreditCard);
    UIkit.modal(document.getElementById('credit-card-modal')).show()
  }
  hideEditAccountModal() {
    UIkit.modal('bank-account-modal').hide()
  }
}
