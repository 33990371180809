
import { Vue, Prop, Component } from "vue-property-decorator";
import { Service, ServiceToPay } from "@/models/UserCatalog/UserCatalog";
import { Currency } from '@/models/Common/Common';
import { Getter } from "vuex-class";


@Component({})
export default class NextPayment extends Vue {
  @Prop() name!: string;
  @Prop({ default: '' }) date!: string;
  @Prop({ default: '' }) time!: string;
  @Prop({ default: '' }) place!: string;
  @Prop({ default: false }) isFirst!: boolean;
  @Prop({ default: false }) isLast!: boolean;
  @Prop() service!: Service;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;

  get currencyCode() {
    if (this.defaultCurrency && this.defaultCurrency.code) return this.defaultCurrency.code.toUpperCase();
    else return ''
  }

  get formattedAmount() {
    let amount = 0;
    if (this.service && this.service.amt) {
      amount = typeof this.service.amt == 'number' ? this.service.amt : parseFloat(this.service.amt)
    }
    return amount.toLocaleString('en-US', {minimumFractionDigits: 2});
    
  }

  get borderClass() {
    let classText = '';
    if (this.isFirst) {
      classText += ' upper-border-radius'
    } 
    if (this.isLast) {
      classText += ' lower-border-radius'
    }
    return classText;
  }

  get payDay() : number {
    try {
      return parseInt(this.service.payment_date.split('-')[2]);
    } catch (error) {
      return 0 
    }
  }

  get remainingDays() {    
    const todayFullDate = new Date()
    const todayDate = todayFullDate.getDate()
    if (this.payDay - todayDate >= 0) {
      return this.payDay - todayDate
    }
    const currentYear = todayFullDate.getFullYear();
    const currentMonth = todayFullDate.getMonth() + 1;
    const daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate()

    return (daysInCurrentMonth - todayDate) + this.payDay;
  }

  openTransactionModal() {
    this.$store.dispatch('Budget/setServiceToPay', { uuid: this.service.uuid, amount: this.service.amt, currency: (this.service.currency as any).uuid } as ServiceToPay);
    ($('#transactionModal') as any).modal();

    const activeTab = 'home-tab';
    const el = document.getElementById(activeTab);
    el?.click();
  }

  getIconName(service: string) {
    switch (service) {
      case 'luz':
        return 'thunder-2'
        break;
      case 'agua':
        return 'water'
        break;
      case 'ayuntamiento':
        return 'truck'
        break;
      case 'telecomunicaciones':
        return 'phone'
        break;
      case 'colegio':
        return 'school'
        break;
      default:
        return 'other-service'
        break;
    }
  }

  getColorName(service: string) {
    switch (service) {
      case 'luz':
        return '#F49600'
        break;
      case 'agua':
        return '#409BFA'
        break;
      case 'ayuntamiento':
        return '#00C73B'
        break;
      case 'telecomunicaciones':
        return '#409BFA'
        break;
      case 'colegio':
        return '#13477E'
        break;
      default:
        return '#13477E'
        break;
    }  
  }

  getImgUrl() {
    if (this.service && this.service.service_type) {
      let icon = this.getIconName(this.service.service_type);
      return require('@/assets/img/svg/' + icon + '.svg');
    } 
    return require('@/assets/img/svg/rings.svg');
  }

  get colorIconBkg() {
    if (this.service && this.service.service_type) {
      return this.getColorName(this.service.service_type);
    }
    return '#13477E'
  }
}
