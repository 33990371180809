<template>
<div class="mx-auto ml-lg-4 mt-4 ml-md-0 mt-lg-0 mr-xl-1">
  <div class="mx-1 mt-2 px-2 pt-3 pb-0 loan-card-container-new mr-lg-4" @click="selectCard" :class="{'selected-card': selected}">
    <div class="uk-flex align-items-center" style="position: relative;">
      <transition name="fade">
        <img class="loan-edit-button" @click.stop="editCurrentLoan" src="@/assets/img/svg/edit.svg" alt="" v-if="selected">
      </transition>
      <transition name="fade">
        <ConfirmTooltip
          :message="'¿Estás seguro que deseas borrar este préstamo?'"
          v-if="selected"
          @onConfirmTrue="deleteLoan">
          <span aria-hidden="true">
            <img class="loan-delete-button" src="@/assets/img/trash.png" alt="">
          </span>
        </ConfirmTooltip>
      </transition>
      <div class="mr-2">
        <img :src="logo" style="borderRadius: 5px; width: 40px;" alt="Icon">
      </div>
      <div>
        <p class="m-0 fs-9"><b>{{ bankName }}</b></p>
        <p class="mb-0 fs-8">{{ code }}</p>
      </div>
    </div>

    <div class="mt-3">
      <div>
        <p class="m-0 fs-8">Valor del préstamo en {{ denomination == 'DOP' ? 'DOP' : 'USD' }}</p>
        <p class="m-0 fs-8"><b>{{ denomination == 'DOP' ? 'DOP ' : 'USD ' }}{{ formatNumber(amount) }}</b></p>
      </div>

      <div class="mt-3">
        <p class="m-0 fs-8">Abonado</p>
        <p class="m-0 fs-8"><b>{{ denomination == 'DOP' ? 'DOP ' : 'USD ' }}{{ formatNumber(payed) }}</b></p>
      </div>

      <div class="uk-flex mt-3 justify-content-between">
        <div>
          <p class="m-0 fs-8">Pendiente de pago</p>
          <p class="m-0 fs-8" style="color: #BF6D6D;">
            <b>
              {{ denomination == 'DOP' ? 'DOP ' : 'USD ' }}{{ formatNumber(amount - payed) }}
            </b>
          </p>
        </div>
        <div>
          <p class="m-0 fs-8">Interés</p>
          <p class="m-0 fs-8"><b>{{ interestRate }}%</b></p>
        </div>
      </div>

      <div class="uk-flex mt-3 justify-content-between">
        <div>
          <p class="m-0 fs-8">Monto cuota</p>
          <p class="m-0 fs-8"><b>{{ denomination == 'DOP' ? 'DOP ' : 'USD ' }}{{ formatNumber(paymentAmount) }}</b></p>
          <p>&nbsp;</p>
        </div>
        <div class="align-right">
          <p class="m-0 fs-7">Fecha de pago</p>
          <p class="m-0 fs-7" style="overflow-wrap: break-word;"><b>días {{paymentDay}} de cada mes</b></p>
        </div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <div v-if="selected" class="d-none uk-flex mr-1 mr-lg-4 mt-3 justify-content-around">
      <button @click="openTransactionModal(false)" class="w-100 fs-8 p-3 add-income">+ Aplicar Pago</button>
    </div>
  </transition>
</div>
</template>

<script>
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";

export default {
  components: {
    ConfirmTooltip
  },
  props: ['bankName', 'code','denomination','amount','payed','paymentAmount','interestRate','paymentDate', 'id', 'selected', 'logo'],
  computed: {
    remaining() {
      return this.amount - this.payed
    },
    paymentDay() {
      if (this.paymentDate) {
        return this.paymentDate.split('-').pop()
      }
      return ''
    }
  },
  methods: {
    formatNumber(num) {
      return parseFloat(num).toLocaleString('en-US', {minimumFractionDigits: 2})
    },
    editCurrentLoan() {
      this.$emit('edit-loan', this.id);
    },
    deleteLoan() {
      this.$emit('delete-loan', this.id);
    },
    selectCard(e) {
      if (e.target.className !== 'loan-delete-button') {
        this.$emit('toggle-select', this.id);
      }
    },
    openTransactionModal(value) {
      return
    }
  }
}
</script>

<style>
.loan-edit-button {
  width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -39px;
    right: 0;
    left: calc(93%);
    cursor: pointer;
}
.loan-delete-button {
  padding: 8px;
  width: 38px;
    height: 38px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 1px #ced0d3;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background-color: white;
    position: absolute;
    margin: auto;
    top: -35px;
    right: 0;
    left: calc(65%);
    cursor: pointer;
}
.loan-card-container-new {
  min-width: 250px;
  border: 1px solid #CEE6FF;
  border-radius: 12px;
  box-shadow: 0 2px 5px 1px #ced0d3
}
.loan-card-container-new:hover {
  box-shadow: var(--hover-shadow);
}
.loan-card-container {
  min-width: 250px;
  /* position: relative;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #CEE6FF;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 12px;
	margin-right: 35px; */
}
.loan-card-text-15 {
  font-size: 0.90em;
}
.loan-card-text-13 {
  font-size: 0.8em;
}
.loan-card-header {
	display: flex;
}
.loan-card-header-text {
	margin-left: 10px;
}
.bank-name {
	margin-bottom: 0px;
  font-size: 1em;
  font-weight: 600;
}
.loan-card-content {
	display: flex;
	justify-content: space-between;
}
.account-denom {
  font-size: 0.8em;
}

.edit-button {
  width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -15px;
    right: 0;
    left: calc(92%);
}
</style>