import { render, staticRenderFns } from "./Patrimony.vue?vue&type=template&id=040dc586&scoped=true&"
import script from "./Patrimony.vue?vue&type=script&lang=ts&"
export * from "./Patrimony.vue?vue&type=script&lang=ts&"
import style0 from "shepherd.js/dist/css/shepherd.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./Patrimony.vue?vue&type=style&index=1&id=040dc586&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040dc586",
  null
  
)

export default component.exports