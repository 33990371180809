
import { Component, Vue } from "vue-property-decorator";
import LoginModel from "@/models/IdentityManagement/LoginModel";
import LoadingButton from "@/components/LoadingButton.vue";
import IdentityManagementService from "@/services/IdentityManagementService";
import { Getter } from 'vuex-class';

@Component({
    components: {
        LoadingButton
    }
})
export default class PasswordRecovery3 extends Vue {
    identityManagementService = new IdentityManagementService();
    mainPassword = "";
    confirmPassword = "";
    isLoading = false;
    showTokenExpireError = false;
    @Getter('IdentityManagement/getUserPasswordResetToken') userPasswordResetToken!: string;
    @Getter('IdentityManagement/getUserEmail') userEmail!: string;

    get passwordsMatch(): boolean {
        return this.mainPassword == this.confirmPassword || !this.confirmPassword;
    }
    mounted() {
        //Include external Scripts
        let script = document.createElement('script')
        let url = process.env.BASE_URL + "js/loginui.js";
        script.setAttribute('src', url);
        document.body.appendChild(script);
        //
    }

    onSubmit() {
        if (!this.passwordsMatch)
            return;

        this.isLoading = true;
        let pass = this.mainPassword;
        this.identityManagementService.ResetPassword({
                                            correoElectronico: this.userEmail, 
                                            tokenUsuario: this.userPasswordResetToken,
                                            contrasena: pass})
        .then((res) => {
            if (res.data.statusCode != 200) {
                this.showTokenExpireError = true;
            } else {
                this.$router.push("/login");
            }
        })
        .catch((err) => {
            console.error(err);
        })
        .finally(() => {
            this.isLoading = false;
        })
    }

}
