
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import AddCreditLineModal from '@/components/assetsLiabilities/CreditLine/AddCreditLineModal.vue'
import CreditLineCard from "@/components/assetsLiabilities/CreditLine/CreditLineCard.vue"
import { ContainerComponent } from '@/components/assetsLiabilities/mixins/index'
import UIkit from 'uikit';

@Component({
  components: {
    CreditLineCard,
    AddCreditLineModal
  }
})
export default class CreditLineMain extends Mixins(ContainerComponent) {
  @Prop() isDesktopViewport!: boolean
  creditLineCardData = [
				{
					title: 'Total disponible en crédito',
					amt: 'DOP 90,000.00',
					denom: 'Pesos dominicanos'
				},
				{
					title: 'Total en crédito Pesos',
					amt: 'DOP 45,000.00',
					denom: 'Pendiente de pago'
				}
			]
  creditLineList = [
    {
      'id': 1,
      'bankName': 'BHD León',
      'code': 'XXXXXX 281',
      'denomination': 'Pesos',
      'limitAmount': '100000',
      'available': '75000',
      'consumed': '25000',
      'paymentAmount': '2100',
      'interestRate': '28.95',
      'paymentDate': 'días 10 de cada mes'
    },
    {
      'id': 2,
      'bankName': 'Popular',
      'code': 'XXXXXX 281',
      'denomination': 'Dolares',
      'limitAmount': '200,000',
      'available': '75000',
      'consumed': '15,000',
      'paymentAmount': '2100',
      'interestRate': '28.95',
      'paymentDate': 'días 10 de cada mes'
    },
    {
      'id': 3,
      'bankName': 'BHD León',
      'code': 'XXXXXX 281',
      'denomination': 'Dolares',
      'limitAmount': '100000',
      'available': '75000',
      'consumed': '25000',
      'paymentAmount': '2100',
      'interestRate': '28.95',
      'paymentDate': 'días 10 de cada mes'
    }
  ]
  selectedType = 'all'
  // props: ['', 'denomination', 'account-name', 'account-type', 'remaining-balance', 'isDesktopViewport'],

  get filteredCreditLines() {
      if (this.selectedType == 'all') {
        return this.creditLineList;
      }
      return this.creditLineList.filter(item => item.denomination.toLowerCase() == this.selectedType);
    }

  createNewCreditLine() {
      var modalEl = this.$refs['credit-line-ref'];
      // modalEl.clearLoan()
      var el = UIkit.modal(document.getElementById('credit-line-modal'))
      el.show()
    }
    filterCardType(text: string) {
      this.selectedType = text;
    }
    creditLineListTypeLength(type: string) {
      if (type == 'all') {
        return this.creditLineList.length;
      }
      return this.creditLineList.filter(item => item.denomination.toLowerCase() == type).length;
    }
    scrollSummaryCard(direction: string, className='credit-line-summary-container') {
      var cardSumEl = document.getElementsByClassName(className)[0];

      if (direction == 'left') {
        cardSumEl.scrollBy(-60, 0);
      } else {
        cardSumEl.scrollBy(60, 0);
      }
    }
    openEditCreditLineModal() {
      UIkit.modal(document.getElementById('credit-line-modal')).show();
    }
    hideEditLoanModal() {
      UIkit.modal('credit-line-modal').hide()
    }
}
