
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { BankAccount as BankAccountCatalog, CreditCard, Loan } from '@/models/UserCatalog/UserCatalog'
import BudgetTransaction from "@/models/Budget/BudgetTransaction";
import moment from "moment";
import BankAccountCardVue from "../bankAccounts/BankAccountCard.vue";

enum AccountType {
  BankAccount,
  CreditCard,
  Loan
}

@Component({})
export default class assetsTransactions extends Vue {
  @Getter('Asset/getUserBankAccounts') bankAccounts! : BankAccountCatalog[];
  @Getter('Asset/getUserCreditCards') creditCards!: CreditCard[];
  @Getter('Asset/getUserLoans') loans!: Loan[];
  @Getter('Asset/getAccountTransactions') accountTransactions!: BudgetTransaction[];
  @Getter('Asset/getSelectedAccount') accountId!: string;
  @Getter('Asset/getSelectedAccountCurrency') accountCurrency!: string;
  @Getter('Asset/getDOPCurrency') DOPCurrency! : string;
  transactionSearchTerm = ""
  accountType = AccountType;

  eraseText() {
    this.transactionSearchTerm = "";
  }

  getDenomination(currencyId: string) {
  if (currencyId == this.DOPCurrency) return 'DOP'
  return 'USD'
}

  get displayableTransactions() {
    let categoryName = this.transactionSearchTerm?.toLowerCase() || "";
    return this.accountTransactions.filter((x: any) => x.transaction.description.toLowerCase().startsWith(categoryName));
  }

  get selectedAccountType() {
    let account = this.account as any;
    if (account) {
      if (account.codigo == 'cb') return this.accountType.BankAccount
      if (account.codigo == 'tc') return this.accountType.CreditCard
      return this.accountType.Loan
    }
    return null
  }

  dayDifference(isoDate: string) {
    const unformattedDate = new Date(isoDate);
    const transactionDate = new Date(unformattedDate.getTime() + (unformattedDate.getTimezoneOffset() * 60000));
    const today = new Date();
    const tzoffset = (new Date()).getTimezoneOffset() * 60000;
    const formattedToday = (new Date(Date.now() - tzoffset)).toISOString().split('T')[0];
    const formattedTransactionDate = transactionDate.toISOString().split('T')[0];

    if (formattedToday == formattedTransactionDate) {
      return 'Hoy'
    }
    const diffTime = Math.abs((transactionDate.getTime() + tzoffset) - today.getTime());
    return 'Hace ' + (Math.floor(diffTime / (1000 * 60 * 60 * 24))) + ' día(s)'
  }

  getBackgroundTransactionBackgroundColor(transaction: any, pos: string, neg: string) {
    if (transaction.type == 'D') return { 'backgroundColor' : neg };
    if (transaction.type == 'C') return { 'backgroundColor' : pos };
    return {}
  }

  formatAmount(amount: string | number, currency: string, rate: string | number) {
      if (typeof amount == "string") amount = parseFloat(amount)
      if (typeof rate == "string") rate = parseFloat(rate)

      if (this.accountCurrency.toUpperCase() == 'DOP' && currency.toUpperCase() == 'USD') amount = amount * rate;
      if (this.accountCurrency.toUpperCase() == 'USD' && currency.toUpperCase() == 'DOP') amount = amount / rate;

      return ' ' + amount.toLocaleString('en-US', {minimumFractionDigits: 2});
    }

  getBackgroundTransactionColor(transaction: BudgetTransaction, pos: string, neg: string) {
    if ((transaction as any).type == 'D') return { 'color' : neg };
    if ((transaction as any).type == 'C') return { 'color' : pos };
    return {}
  }

  get account() {
    if (this.bankAccounts && this.creditCards && this.accountId) {
      // let bankAccount = this.bankAccounts.find(ba => ba.id === this.accountId);
      let bankAccount = this.bankAccounts.find(ba => ba.uuid === this.accountId);
      let creditCard = this.creditCards.find(cc => cc.uuid === this.accountId);
      let loan = this.loans.find(loan => loan.uuid === this.accountId);
      
      if (bankAccount) {
        return bankAccount;
      } else if (creditCard) {
        return creditCard
      } else {
        return loan
      }
    }
    return null
  }

  get bankName() {
    if (!this.account) {
      return null
    }

    return (this.account.bank as any).name;
  }

  get bankAccountNumber() {
    if (!this.account || !('numero' in this.account)) {
      return null
    }

    // return this.account.numero;
    return 0
  }

  get registeredTransactionAmount() {
    if (!this.displayableTransactions || this.displayableTransactions.length === 0) {
      return '0 transacciones registradas'
    }
    if (this.displayableTransactions.length === 1) {
      return '1 transacción registrada'
    }
    return this.displayableTransactions.length + ' transacciones registradas';
  }

  formatDate(date: string) {
    return moment(date).fromNow();
  }
}
