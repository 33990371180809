
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

@Component({
  components: {
    Autocomplete,
  },
})
//https://github.com/trevoreyre/autocomplete/issues/93
export default class AutocompleteInput extends Vue {
  @Prop() searchFunction!: Function;
  @Prop() getResultValue!: Function;
  @Prop({ default: true }) isRequired!: boolean;
  @Prop({ default: "" }) requiredErrorMessage!: string;
  @Prop({ default: "" }) defaultValue!: string;
  @Prop({ default: false }) focusOnShown!: boolean;
  selectedOption: any = null;
  inputElement: HTMLInputElement | null = null;
  showRequiredMessage = false;

  @Watch("showRequiredMessage")
  onShowMessageChange(newValue: boolean) {
    if (newValue) this.inputElement?.classList.add("is-invalid");
    else this.inputElement?.classList.remove("is-invalid");
  }

  mounted() {
    //(I discovered the input in the ref by myself it is not in the docs)
    this.inputElement = (this.$refs.autocompleteComponent as any).$refs
      .input as HTMLInputElement;
    //
    //Add formControl class to input
    this.inputElement.classList.add("form-control");
    //Register blur event on input
    this.inputElement.addEventListener("blur", this.handleBlur);
    if (this.isRequired) {
      //Register on input event
      this.inputElement.addEventListener("input", this.onInputChange);
    }
    //
    if (this.focusOnShown)
      //Focus input
      this.inputElement.focus();
  }

  handleBlur(event: FocusEvent) {
    Vue.nextTick(() => {
      var inputText = (event.target as HTMLInputElement).value;
      if (this.isRequired) {
        //If value is required and input value is empty dont emit events and show validation
        let isValid = this.checkValidations(inputText);
        if (!isValid)
          return;
      }
      if (this.selectedOption)
        this.$emit("onOptionSelected", this.selectedOption);
      else 
        this.$emit("onNewOptionCreated", inputText);
    });
  }

  onOptionSelected(value: any) {
    //This method executes when user hit enter or select an option from the result list
    //If user selected an option, value will have the selected object
    //Otherwise it means that the user typed an unknown option.
    //So if "value" has value save that object to return on blur Event
    this.selectedOption = value;
    //Now trigger blur event on input to send selection to parent component
    this.inputElement?.blur();
  }

  onInputChange(event: Event) {
    var inputValue = (event.target as HTMLInputElement).value;

    this.checkValidations(inputValue);
  }

  checkValidations(inputValue: string) {
    this.showRequiredMessage = inputValue == "";
    return !this.showRequiredMessage;
  }
}
