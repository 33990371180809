
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import { isBigNumber } from "@/utils/HelperMethods";

@Component({
  components: {
    NewTransactionModal
  }
})
export default class DashboardSummaryCard extends Vue {
  @Prop() title!: string;
  @Prop() titleColor!: string;
  @Prop() text!: string;
  @Prop() amount!: number;
  @Prop({ default: 'DOP' }) currencyCode!: string;
  @Prop() currencyText!: string;
  @Prop() iconBkgColor!: string;
  @Prop() footerText!: string;

  isBigNumber = isBigNumber

  get titleColorProperty() {
    if (this.title && this.title.toLowerCase() === 'disponible') {
      return 'font-green-caudall'  
    }
    return 'font-gray-caudall'
  }

  get transactionBttnColor() {
    if (this.title) {
      return this.title.toLowerCase() === 'gastos' ? '#B94748': '#58C879' 
    }
    return ''
  }

  get formattedAmount() {
    let amount = 0;
    if (typeof this.amount == 'number') {
      amount = this.amount
    }
    return amount.toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  get actionText() {
    if (this.title) {
      return this.title.toLowerCase() === 'gastos' ? 'gasto': 'ingreso' 
    }
    return ''
  }

  get hideAvailable() {
    if (this.title) {
      return this.title.toLowerCase() !== 'disponible';
    }
    return false
  }

  openTransactionModal() {
    this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
    ($('#transactionModal') as any).modal();

    const activeTab = this.title.toLowerCase() === 'ingresos' ? 'profile-tab' : 'home-tab';
    const el = document.getElementById(activeTab);
    el?.click();
    
  }

  get iconName() {
    if (this.title.toLowerCase() === 'ingresos') return 'dashboard-income'
    else if (this.title.toLowerCase() === 'gastos') return 'dashboard-expense'

    return 'dashboard-available'
  }

  get iconColorClass() {
    if (this.title.toLowerCase() === 'ingresos') return 'filter-income-icon'
    else if (this.title.toLowerCase() === 'gastos') return 'filter-expense-icon'

    return 'dashboard-available'
  }

  imgUrl() {
    return require("@/assets/img/svg/" + this.iconName + ".svg")
  }
}
