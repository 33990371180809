import { render, staticRenderFns } from "./NewTransactionModal.vue?vue&type=template&id=b4c60fb8&scoped=true&"
import script from "./NewTransactionModal.vue?vue&type=script&lang=ts&"
export * from "./NewTransactionModal.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./NewTransactionModal.vue?vue&type=style&index=1&id=b4c60fb8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4c60fb8",
  null
  
)

export default component.exports