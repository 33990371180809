
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  BudgetCategory,
  BudgetSubCategory,
  CategoryType,
  CatalogAux
} from "@/models/Budget/BudgetCategory";
import Chart from "chart.js";
import { Currency } from '@/models/Common/Common'
@Component({
  components: {},
})
export default class DistributionChartDesktop extends Vue {
  @Getter("Budget/getBudgetTotalIncomePresupuestado") totalIncome!: number;
  @Getter("Budget/getBudgetTotalExpenseEjecutado") totalExpense!: number;
  @Getter("Budget/getBudgetCategories") budgetCategories!: BudgetCategory[];
  @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
  @Getter("Budget/getChangeId") changeId!: string; 
  @Getter("Budget/getShowDummyDataInChart") showDummyData!: boolean;
  @Getter("Layout/isMobileView") isMobile!: boolean;
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Asset/getDefaultCurrencyCode") defaultCurrencyCode!: string;
  @Getter("Budget/getBudgetTypeTab") activeTab! : number;
  @Getter("Budget/getCategoryCatalog") categories!: BudgetCategory[];
  @Prop({ default: 'C' }) chartSub!: string;
  
  doughnutChart: any = null;
  chartTypes = ChartType;
  chartSubTypes = ChartSubType;
  defaultChartType = ChartType.Gastos

  get getDefaultCurrency() {
      if (this.defaultCurrency && this.defaultCurrency.code) {
        return this.defaultCurrency.code.toUpperCase();
      }

      return '';
    }

  get budgetTypeText() {
    if (this.activeTab == 0) return 'Consumido'
    return 'Ingresado'
  }

  get getGraphCenterAmount() {
    if (this.selectedChartType == ChartType.Gastos && this.selectedChartSubType == ChartSubType.Consumed) {
      let value = this.getExpenseCategoriesTotalEjecutado()
      if (!value) return 0
      return value.reduce((a, b) => a + b, 0)
    } else if (this.selectedChartType == ChartType.Gastos && this.selectedChartSubType == ChartSubType.Planned) {
      let value = this.getExpenseCategoriesTotalPresupuestado()
      if (!value) return 0
      return value.reduce((a, b) => a + b, 0)
    } else if (this.selectedChartType == ChartType.Ingresos && this.selectedChartSubType == ChartSubType.Consumed) {
      let value = this.getIncomeCategoriesTotalEjecutado()
      if (!value) return 0
      return value.reduce((a, b) => a + b, 0)
    } else {
      let value = this.getIncomeCategoriesTotalPresupuestado()
      if (!value) return 0
      return value.reduce((a, b) => a + b, 0)
    }
  }

  formatNumber(n: number) {
    return n.toLocaleString('en-US', {minimumFractionDigits: 2});
  }

  get selectedChartType() {
    if (this.activeTab == 0) {
      return ChartType.Gastos;
    } 
    return ChartType.Ingresos
  }

  get selectedChartSubType() {
    if (this.chartSub == 'C') {
      return ChartSubType.Consumed
    } else if (this.chartSub == 'P') {
      return ChartSubType.Planned
    } else {
      return ChartSubType.All
    }
  }

  get selectedChartSubTypeGraph() {
    if (this.chartSub == 'A') {
      return 'bar'
    } else {
      return 'doughnut'
    }
  }

  get chartDetailedSummary() {
    let executedList = [] as number[];
    let plannedList = [] as number[];

    if (this.selectedChartSubType == this.chartSubTypes.All) {
      if (this.selectedChartType == ChartType.Gastos) {
        executedList = this.getExpenseCategoriesTotalEjecutado();
        plannedList = this.getExpenseCategoriesTotalPresupuestado()
        let activeCategories = this.getExpenseCategories.map((c, i:number) => {
          let executedNum = executedList[i];
          let plannedNum = plannedList[i];

          return {
            category: c.alias || c.category,
            executed: executedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
            planned: plannedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
            color: c.categoryColor,
            perc: 0
          }
        })

        return activeCategories.concat(this.ExpenseCategoriesExcluded)
      } else {
        executedList = this.getIncomeCategoriesTotalEjecutado();
        plannedList = this.getIncomeCategoriesTotalPresupuestado();
        return this.getIncomeCategories.map((c, i:number) => {
          let executedNum = executedList[i];
          let plannedNum = plannedList[i];

          return {
            category: c.alias || c.category,
            executed: executedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
            planned: plannedNum.toLocaleString('en-US', {minimumFractionDigits: 2}),
            color: c.categoryColor,
            perc: 0
          }
        })
      }
    } else {
      let categories = this.selectTypeCategories
      let amounts = this.getTotalByTypeAndSubType(this.selectedChartType, this.selectedChartSubType)
      let amountTotal = amounts.reduce((a, b) => a + b, 0)

      let activeCategories = categories.map((c, i: number) => {
        let executed = this.selectedChartSubType == ChartSubType.Consumed ? amounts[i] : 0;
        let planned = this.selectedChartSubType == ChartSubType.Planned ? amounts[i] : 0;
        let selected = this.selectedChartSubType == ChartSubType.Consumed ? executed : planned;
        let perc = amountTotal == 0 ? 0 : (selected / amountTotal) * 100
        
        return {
            category: c.alias || c.category,
            executed: executed.toLocaleString('en-US', {minimumFractionDigits: 2}),
            planned: planned.toLocaleString('en-US', {minimumFractionDigits: 2}),
            color: c.categoryColor,
            perc: Math.round(perc)
          } as CatalogAux
      })
      
      return activeCategories.concat(this.ExpenseCategoriesExcluded)
    }
  }

  get ExpenseCategoriesExcluded() {
    let s = 0;
    let activeCategories = this.getExpenseCategories.map(c => c.category);
    let excludedCategories = this.getExpenseCategoriesFromCatalog.filter((c: any) => activeCategories.indexOf(c.uuid) == -1);
    return excludedCategories.map((c:any) => {
      return {
          category: c.name,
          executed: s.toLocaleString('en-US', {minimumFractionDigits: 2}),
          planned: s.toLocaleString('en-US', {minimumFractionDigits: 2}),
          color: c.categoryColor,
          excluded: true
        } as CatalogAux
    })
  }

  get getExpenseCategoriesFromCatalog() {
    return this.categories.filter(c => c.type == CategoryType.Expense)
  }

  get chartTotalFormatted() {
    return 0
      // let value = this.selectedChartType == ChartType.Presupuestado
      //       ? this.totalIncome
      //       : this.totalExpense;
      // if (this.showDummyData) {
      //   value = 7500;
      // }
      // return value.toLocaleString("en-US", {
      //           minimumFractionDigits: 2,
      //           maximumFractionDigits: 2,
      //       });
  }

  get getExpenseCategories() {
    return this.budgetCategories
                .filter(x => x.type == CategoryType.Expense);
  }

  get getIncomeCategories() {
    return this.budgetCategories
                .filter(x => x.type == CategoryType.Income);
  }

  get selectTypeCategories() {
    if (this.selectedChartType == this.chartTypes.Gastos) return this.getExpenseCategories
    return this.getIncomeCategories
  }

  get getExpenseCategoriesNames() {
    return this.getExpenseCategories
                .map((x) => x.alias);
  }

  get getIncomeCategoriesNames() {
    return this.getIncomeCategories
                .map((x) => x.alias);
  }

  get selectedCategoryTypeNames() {
    if (this.selectTypeCategories.length == 0) return ['Categoría 1', 'Categoría 2']
    if (this.selectedChartType == ChartType.Gastos) {
      return this.getExpenseCategoriesNames
    }
    return this.getIncomeCategoriesNames
  }

  get getExpenseCategoriesColors() {
    return this.getExpenseCategories.map((x) => x.categoryColor);
  }

  get getIncomeCategoriesColors() {
    return this.getIncomeCategories.map((x) => x.categoryColor);
  }

  get selectedCategoryTypeColors() {
    if (this.selectedChartType == ChartType.Gastos) {
      return this.getExpenseCategoriesColors
    }
    return this.getIncomeCategoriesColors
  }

  get pendingIncomeToAsign(): number {
    let categories = this.getExpenseCategoriesTotalPresupuestado();
    let totalPresupuestado = 0;
    if (categories.length) {
      totalPresupuestado = categories.reduce((a,b) => a + b);
    }

    let pending = this.totalIncome - totalPresupuestado;
    return pending;
  }

  get pendingIncomeToExpense(): number {
    let categoriesTotals = this.getIncomeCategoriesTotalEjecutado();
    let totalIncome = 0
    if (categoriesTotals.length) {
      totalIncome = categoriesTotals.reduce((a,b) => a + b);
    }

    let expenseCategoriesTotals = this.getExpenseCategoriesTotalEjecutado();
    let totalExpended = 0
    if (expenseCategoriesTotals.length) {
      totalExpended = expenseCategoriesTotals.reduce((a,b) => a + b);
    }

    let pending = totalIncome - totalExpended
    return pending;
  }

  get pendingIncomeToExpenseFormatted(): string {
    return this.pendingIncomeToExpense.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get pendingIncomeToAsignFormatted(): string {
    return this.pendingIncomeToAsign.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  get pendingIncomeToAsignIsNegative(): boolean {
    return this.pendingIncomeToAsign < 0;
  }

  get pendingIncomeToExpenseIsNegative(): boolean {
    return this.pendingIncomeToExpense < 0;
  }

  get pendingIncomeToAsignIsGreaterThan0(): boolean {
    return this.pendingIncomeToAsign > 0;
  }

  get pendingIncomeToAsignAbsFormatted(): string {
    let value = Math.abs(this.pendingIncomeToAsign);
    return value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
  }

  getExpenseCategoriesTotalPresupuestado() {
    let categoriesTotal = this.getExpenseCategories.map((x) => {
      let list = x.subcategories?.map((y) => { 
                    let result = typeof y.budgeted == 'string' ? parseFloat(y.budgeted) * (+y.rate): y.budgeted * (+y.rate);

                    if (result >= 0) {
                      return result
                    }
                    return 0
                  });
      if (!list || !list.length)
        return 0;

      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getExpenseCategoriesTotalEjecutado() {
    let categoriesTotal = this.getExpenseCategories.map((x) => {
      let list = x.subcategories?.map((y) => typeof y.executed == 'string' ? parseFloat(y.executed) * (+y.rate): y.executed * (+y.rate));
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getIncomeCategoriesTotalEjecutado() {
    let categoriesTotal = this.getIncomeCategories.map((x) => {
      let list = x.subcategories?.map((y) => y.executed * (+y.rate));
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getIncomeCategoriesTotalPresupuestado() {
    let categoriesTotal = this.getIncomeCategories.map((x) => {
      let list = x.subcategories?.map((y) => { 
                    let result = typeof y.budgeted == 'string' ? parseFloat(y.budgeted) * (+y.rate): y.budgeted * (+y.rate);
                    if (result >= 0) {
                      return result
                    }
                    return 0
                  });
      if (!list || !list.length)
        return 0;
      
      return list.reduce((a, b) => a + b);
    });
    return categoriesTotal;
  }

  getTotalByTypeAndSubType(ctype: ChartType, sbType: ChartSubType) {
    if (ctype == this.chartTypes.Gastos && sbType == this.chartSubTypes.Consumed) {
      return this.getExpenseCategoriesTotalEjecutado()
    } else if (ctype == this.chartTypes.Gastos && sbType == this.chartSubTypes.Planned) {
      return this.getExpenseCategoriesTotalPresupuestado()
    } else if (ctype == this.chartTypes.Ingresos && sbType == this.chartSubTypes.Planned) {
      return this.getIncomeCategoriesTotalPresupuestado()
    } else {
      return this.getIncomeCategoriesTotalEjecutado()
    }
  }

  @Watch("budgetIsLoading")
  budgetLoadingChange(isLoading: boolean) {
    if (!isLoading && !this.doughnutChart) {
      this.initChart();
    }
  }

  @Watch("selectedChartSubType")
  changeGraphType() {
    this.updateChart();
  }

  @Watch("showDummyData")
  onShowDummyDataChange(showDummy: boolean) {
    if (showDummy) {
      this.setDummyData();
    } else {
      this.updateChart();
    }
  }

  @Watch("changeId")
  onBudgetChange(newValue: string) {
    this.updateChart();
  }

  @Watch("selectedChartType")
  onSelectChartTypeChange(chartType: ChartType) {
    this.updateChart();
  }

  get chartSubtypeGraph() {
    return ''
  }

  get chartConfigObject() {
    if (this.selectedChartSubType == ChartSubType.All) {
      return {
        aspectRatio: 1,
        cutoutPercentage: 85,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "rgba(0, 0, 0)",
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              var label = data.labels[tooltipItem.index] || "";
              let value = 0
              if (tooltipItem.datasetIndex == 0) {
                value = data.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index];
              } else {
                value = data.datasets[0]?.data[tooltipItem.index] + data.datasets[1]?.data[tooltipItem.index];
              }
              
              if (label) {
                label += ": ";
              }
              label += value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return label;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            maxBarThickness: 8,
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              borderDash: [4,4],
              color: '#8AACCE72'
            },
            stacked: true,
            ticks: {
                  beginAtZero: true,
                  precision: 0,
                  callback: function(value: number, index: any, values: any) {
                      if (value < 1000) {
                        return value
                      }
                      return (value / 1000) + 'K' 
                  }
                }
          }] 
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      }
    }
    return {
        cutoutPercentage: 90,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          backgroundColor: "rgba(0, 0, 0)",
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              var label = data.labels[tooltipItem.index] || "";
              let value = data.datasets[0]?.data[tooltipItem.index];
              if (label) {
                label += ": ";
              }
              label += value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              return label;
            },
          },
        },
        scales: {
          xAxes: [{
            display: false
          }],
          yAxes: [{
            display: false
          }] 
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      }
  }

  initChart() {
    var canvas = document.getElementById("myChartDesktop") as HTMLCanvasElement;
    if (canvas == null) return;
    var ctx = canvas?.getContext("2d");
    if (ctx) ctx.globalCompositeOperation = "destination-over";
    this.doughnutChart = new Chart(ctx, {
      type: "bar",
      data: {
        datasets: [
          {
            data: this.getExpenseCategoriesTotalEjecutado(),
            backgroundColor: this.getExpenseCategoriesColors, //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          },
          {
            data: this.getExpenseCategoriesTotalPresupuestado(),
            backgroundColor: 'blue', //"#E4EAEE"],
            weight: 0.4,
            borderColor: "transparent",
          },
        ],

        // These labels appear in the legend and in the tooltips when hovering different arcs
        labels: [...this.getExpenseCategoriesNames, "Sin asignar"],
      },
      options: this.chartConfigObject,
    });
    this.updateChart();
  }

  updateChart() {
    if (this.doughnutChart) {
      this.doughnutChart.chart.config.type = this.selectedChartSubTypeGraph;
      this.doughnutChart.data.datasets = this.selectChartDatasets;
      this.doughnutChart.data.labels = [...this.selectedCategoryTypeNames];
      this.doughnutChart.options = this.chartConfigObject;
      this.doughnutChart.update();
    }
  }

  get selectChartDatasets() {
    if (this.selectTypeCategories.length == 0) return this.setDummyData();
    if (this.selectedChartType == ChartType.Gastos && this.selectedChartSubType == ChartSubType.Consumed) {
      return [
        {
          data: this.getExpenseCategoriesTotalEjecutado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ]      
    } else if (this.selectedChartType == ChartType.Gastos && this.selectedChartSubType == ChartSubType.Planned ) {
      return [
        {
          data: this.getExpenseCategoriesTotalPresupuestado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ] 
    } else if (this.selectedChartType == ChartType.Ingresos && this.selectedChartSubType == ChartSubType.Planned ) {
      return [
        {
          data: this.getIncomeCategoriesTotalPresupuestado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ] 
    } else if (this.selectedChartType == ChartType.Ingresos && this.selectedChartSubType == ChartSubType.Consumed ) {
      return [
        {
          data: this.getIncomeCategoriesTotalEjecutado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ] 
    } else if (this.selectedChartType == ChartType.Ingresos && this.selectedChartSubType == ChartSubType.All ) {
      let executed = this.getIncomeCategoriesTotalEjecutado()
      return [
      {
          data: this.getIncomeCategoriesTotalEjecutado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        },
        {
          data: this.getIncomeCategoriesTotalPresupuestado().map((a, i) => {
            let result = a - executed[i];
            return result < 0 ? 0 : result
          }),
          backgroundColor: '#f2f2f2cc', //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ] 
    } else {
      let executed = this.getExpenseCategoriesTotalEjecutado()
      return [
      {
          data: this.getExpenseCategoriesTotalEjecutado(),
          backgroundColor: this.selectedCategoryTypeColors, //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        },
        {
          data: this.getExpenseCategoriesTotalPresupuestado().map((a, i) => {
            let result = a - executed[i];
            return result < 0 ? 0 : result
          }),
          backgroundColor: '#f2f2f2cc', //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ] 
    }
    return []
  }

  // To delete
  getDataChartPresupuestado() {
    let expenseCategoriesTotalesPresupuestados = this.getExpenseCategoriesTotalPresupuestado();
    let totalPresupuestadoExpenseCategory = expenseCategoriesTotalesPresupuestados.length 
                                          ? expenseCategoriesTotalesPresupuestados.reduce((a, b) => a + b)
                                          : 0;
    let res = this.totalIncome - totalPresupuestadoExpenseCategory;
    //
    // this.showInsufficientIncome = res < 0;
    //
    let absRes = res < 0 ? 0 : res;
    let data = [...expenseCategoriesTotalesPresupuestados, absRes];
    return data;
  }

  getDataChartExpenses() {
    this.getExpenseCategoriesTotalPresupuestado();
  }

  getDataChartEjecutado() {
    let incomeCategoriesTotalesEjecutados = this.getIncomeCategoriesTotalEjecutado();
    let totalEjecutadoIncomeCategory = incomeCategoriesTotalesEjecutados.length
                                          ? incomeCategoriesTotalesEjecutados.reduce((a, b) => a + b)
                                          : 0;
    //
    let expenseCategoriesTotalesEjecutadoss = this.getExpenseCategoriesTotalEjecutado();
    let totalEjecutadoExpenseCategory = expenseCategoriesTotalesEjecutadoss.length
                                      ? expenseCategoriesTotalesEjecutadoss.reduce((a, b) => a + b)
                                      : 0;
    //
    let res = totalEjecutadoIncomeCategory - totalEjecutadoExpenseCategory;
    let absRes = res < 0 ? 0 : res;
    let data = [...expenseCategoriesTotalesEjecutadoss, absRes];
    return data;
  }

  setDummyData() {
  if (this.selectedChartSubType === ChartSubType.All) {
    return [
        {
          data: [2500, 5000],
          backgroundColor: ['#8AACCE','#8AACCE','#8AACCE','#8AACCE'], //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        },
        {
          data: [3000, 12000],
          backgroundColor: '#f2f2f2cc', //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
    ]
  }

  return [
        {
          data: [90, 10],
          backgroundColor: ['#8AACCE', '#CEE6FF'], //"#E4EAEE"],
          weight: 0.4,
          borderColor: "transparent",
        }
      ]
  }
}

enum ChartType {
    Gastos,
    Ingresos
}

enum ChartSubType {
    Consumed,
    Planned,
    All
}
