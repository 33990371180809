
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import LoadingButton from "@/components/LoadingButton.vue";
import SideBar from "@/components/layout/SideBar.vue";
import InnerFooter from "@/components/layout/InnerFooter.vue";
import TopBar from "@/components/layout/TopBar.vue";
import TopBarAssets from "@/components/layout/TopBarAssets.vue";
import BudgetCateogryFiller from "@/components/budget/BudgetCategoryFiller.vue";
import DistributionChart  from "@/components/budget/DistributionChart.vue";
import DistributionSummary  from "@/components/budget/DistributionSummary.vue";
import TransactionSummary from "@/components/budget/TransactionSummary.vue";
import GeneralSummary from "@/components/budget/GeneralSummary.vue";
import Shepherd from 'shepherd.js';
import UserBudget from "@/models/Budget/UserBudget";
import EnrollmentCategoryModal from "@/components/budget/EnrollmentCategoryModal.vue";
import NewBudgetModal from "@/components/budget/NewBudgetModal.vue";
import NewTransactionModal from "@/components/budget/NewTransactionModal.vue";
import TransactionTable from "@/components/dashboard/TransactionTable.vue";
import DesktopSideColumn from "@/components/budget/DesktopSideColumn.vue"
import BudgetMonthSelection from "@/components/budget/BudgetMonthSelection.vue";
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";
import AccountSummaryOption from "@/components/accountSummary/AccountSummaryOptions.vue"
import Swal from 'sweetalert2';

@Component({
  components: {
      LoadingButton,
      SideBar,
      TopBar,
      InnerFooter,
      BudgetCateogryFiller,
      DistributionChart,
      DistributionSummary,
      TransactionSummary,
      GeneralSummary,
      EnrollmentCategoryModal,
      NewBudgetModal,
      TopBarAssets,
      NewTransactionModal,
      TransactionTable,
      DesktopSideColumn,
      BudgetMonthSelection,
      ConfirmTooltip,
      AccountSummaryOption
  }
})
export default class AccountSummary extends Vue {
    mounted() {
      this.$store.dispatch("Asset/assetCatalogLoaded");
      this.$store.dispatch("Budget/loadUserBudgets");
    }

    options = [
        {
            title: 'Planes de Caudall',
            description: '<p class="subtitle">Cambia de plan cuando quieras</p>',
            icon: 'account-summary-pricing'
        },
        {
            title: 'Historial de pago',
            description: '<p class="subtitle">Aquí podrás ver tus pagos a lo largo del tiempo.</p>',
            icon: 'account-summary-history'
        },
        {
            title: 'Mis métodos de pago',
            description: '<p class="subtitle">Administra tus métodos de pago.</p>',
            icon: 'account-summary-card'
        },
        {
            title: 'Preferencias de mi cuenta',
            description: '<p class="subtitle">Aquí podrás modificar <strong>tu nombre, contraseña y otros datos.</strong></p>',
            icon: 'account-summary-card'
        }
    ]
}
