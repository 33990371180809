
import LoanCard from "@/components/assetsLiabilities/loan/LoanCard.vue"

import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Getter } from "vuex-class";
import UserModel from "@/models/IdentityManagement/UserModel";
import { Loan as LoanUserCatalog } from "@/models/UserCatalog/UserCatalog";
import CarouselArrows from '@/components/helpers/carousel-arrows.vue';
import EmptyState from '@/components/helpers/emptyState.vue'
import { ContainerComponent } from '@/components/assetsLiabilities/mixins/index';
import UIkit from 'uikit';
import { Currency, Bank } from "@/models";
import { isBigNumber } from "@/utils/HelperMethods";

@Component({
  components: {
    LoanCard,
    CarouselArrows,
    EmptyState
  }
})
export default class LoanMain extends Mixins(ContainerComponent) {
  @Prop() isDesktopViewport!: boolean;
  @Getter("Asset/getUserLoans") userLoans!: LoanUserCatalog[]
  @Getter("Asset/getTotalLoanCreditDOP") totalLoanCreditDOP!: number
  @Getter("Asset/getTotalLoanCreditUSD") totalLoanCreditUSD!: number
  @Getter("Asset/getTotalLoanBalanceDOP") totalBalanceDOP!: number
  @Getter("Asset/getTotalLoanBalanceUSD") totalBalanceUSD!: number
  @Getter('Asset/getSelectedAccount') selectedCard! : String;
  @Getter('Asset/getDefaultCurrency') defaultCurrency! : Currency;
  @Getter("IdentityManagement/getUser") user!: UserModel;
  @Getter('Asset/getBanks') banks! : Bank[];
  
  loadedLoans = false;
  selectedType = 'all'
  get loansListCardData() {
    return [
				{
					title: 'Total en crédito Pesos',
					formattedAmt: `DOP ${this.formatNumber(this.totalBalanceDOP)}`,
					amt: this.totalBalanceDOP,
					denom: 'Pendiente de pago',
          currency: 'dop'
				},
				{
					title: 'Total en crédito Dólares',
					formattedAmt: `USD ${this.formatNumber(this.totalBalanceUSD)}`,
					amt: this.totalBalanceUSD,
					denom: 'Pendiente de pago',
          currency: 'usd'
				}
			]
  }

  isBigNumber = isBigNumber

  formatNumber(n: number) {
    return n.toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  mounted() {
    if (this.userLoans) {
      this.loadedLoans = true;
    }
  }

  @Watch("userLoans")
  changeCardComponent() {
    this.loadedLoans = true
  }

  getBankName(bankId: string, property: keyof Bank = 'short_name') {
    if (this.banks) {
      let bank = this.banks.find(bank => bank.uuid === bankId);
      if (bank) return bank[property]
    }
    return ''
  }

  selectCard(idCuenta: string) {
    const loan = this.userLoans.find((b: any) => b.uuid == idCuenta);
    this.loadTransactions(idCuenta, 'loans');
    this.selectedAccountCurrency((loan as any).currency.code);
  }

  get filteredLoans() {
      if (this.selectedType == 'all') {
        return this.userLoans;
      }
      // return this.userLoans.filter(loan => loan.currency.code.toLowerCase() == this.selectedType);
      return this.userLoans;
    }

  get getFilteredCardSummary() {
    return this.loansListCardData;
  }

  createNewLoan() {
    this.$root.$emit('clearLoan');
    UIkit.modal(document.getElementById('loan-modal')).show();
  }

  deleteLoan(id: string) {
    this.$store.dispatch("Asset/deleteLoan", { userId: this.user.id, loanId: id })
  }
  
  changeSelectedType(newType:string) {
      this.selectedType = newType;
  }

  loansListTypeLength(type: string) {
    if (type == 'all') {
      return this.userLoans.length;
    }
    // return this.userLoans.filter(item => item.currency.code.toLowerCase() == type).length;
    return this.userLoans;
  }

  scrollSummaryCard(direction: string, className='loan-summary-container') {
    var cardSumEl = document.getElementsByClassName(className)[0];

    if (direction == 'left') {
      cardSumEl.scrollBy(-60, 0);
    } else {
      cardSumEl.scrollBy(60, 0);
    }
  }

  openEditLoanModal(editingId: string) {
    let editingLoan = this.userLoans.filter(loan => loan.uuid == editingId)[0];
    this.$root.$emit('editLoan', editingLoan);
    UIkit.modal(document.getElementById('loan-modal')).show();
  }

  hideEditLoanModal() {
    UIkit.modal('loan-modal').hide()
  }
}
