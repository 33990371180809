
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class Plan extends Vue {
    @Prop() id!: string;
    @Prop() name!: string;
    @Prop() annualDiscount!: number;
    @Prop() typeComment!: string;
    @Prop() price!: number;
    @Prop() ctaMessage!: string;
    @Prop() selectedPlan!: boolean;
    @Prop() isAnnual!: boolean;

    get annualPricing() {
        if (this.name.toLowerCase() == 'gratis') return 'Descubre Caudall'
        let pricing = (this.pricing * 12).toFixed(2);
        return `Un pago de $${pricing} al año`
    }

    get isPreferredPlan() {
        return this.name.toLowerCase() == 'premium'
    }

    get getTypeComment() {
        if (this.name.toLowerCase() == 'gratis') return 'Básico'
        if (this.name.toLowerCase() == 'premium') return 'Más utilizado'
        return 'Desbloquea todo el potencial'
    }

    get typeSubtext() {
        if (this.name.toLowerCase() == 'gratis') return 'Descubre Caudall'
        if (this.name.toLowerCase() == 'premium') return '+400 Usuarios lo recomiendan'
        return ''
    }

    get numericDiscount() {
        return typeof this.annualDiscount == 'string' ? parseFloat(this.annualDiscount) : this.annualDiscount;
    }

    changePlan() {
        this.$emit('change-plan', this.id);
    }

    get pricing() {
        let value = this.price;
        if (this.isAnnual) {
            value = parseFloat((this.price - (this.price * (this.numericDiscount / 100))).toFixed(2))
        }
        return value
    }

    get priceToShow() {
        return '$' + this.pricing
    }
    
    get callToActionMessage() {
        return this.selectedPlan ? 'Actualmente' : 'Seleccionar plan'
    }

    get containerClass() {
        if (this.name.toLowerCase() === 'premium') return 'card-container card-container-preferred'
        return 'card-container'
    }
}
