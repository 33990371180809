
import { Component, Vue, Watch } from "vue-property-decorator";
import MobileDashboardSummaryCard from "./MobileDashboardSummaryCard.vue";
import { BankAccount, CreditCard, Loan } from '@/models/UserCatalog/UserCatalog'
import PatrimonyCard from "./PatrimonyCard.vue";
import { Getter } from "vuex-class";
import { Currency } from '@/models/Common/Common'

@Component({
  components: {
    PatrimonyCard
  }
})
export default class Patrimony extends Vue {
  assetClassModal = 'activo'
  @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
  @Getter("Asset/getUserBankAccounts") userBanks!: BankAccount[];
  @Getter("Asset/getUserCreditCards") userCreditCards!: CreditCard[];
  @Getter("Asset/getUserLoans") userLoans!: Loan[];
  @Getter("Dashboard/getAssets") assets!: number;
  @Getter("Dashboard/getLiabilities") liabilities!: number;
  @Getter("Dashboard/getPatrimonyTotal") patrimony!: number;

  showModal() {
    (this.$refs['PatrimonyModal'] as any).show();
  }

  hideModal() {
    (this.$refs['PatrimonyModal'] as any).hide();
  }

  showAssetClassModal(assetClass: string) {
    this.assetClassModal = assetClass;
    (this.$refs['assetClassModal'] as any).show();
  }

  hideAssetClassModal(assetClass: string) {
    (this.$refs['assetClassModal'] as any).hide();
  }

  get iconWidth() {
    if (this.assetClassModal.toLowerCase() === 'activo') return '7rem'
    else return '3rem'
  }
  
  get assetClassTitle() {
    if (this.assetClassModal.toLowerCase() === 'activo') return '¿Qué es un activo?'
    else return '¿Qué es un pasivo?'
  }

  get assetClassBodyText() {
    if (this.assetClassModal.toLowerCase() === 'activo') return 'Son los bienes, muebles y/o inmuebles que posee una persona. Ejemplos típicos de activos son efectivo en banco, propiedades inmobiliarias, carros, inversiones, otras. Actualmente este indicador solo incluye el saldo de las cuentas bancarias.'
    else return 'Representa lo que una persona debe a terceros y tiene que pagar o devolver, habitualmente en cuotas y/o pagos únicos y períodos establecidos.'
  }

  get getImgUrl() {
    if (this.assetClassModal.toLowerCase() === 'activo') return require('@/assets/img/svg/asset-explained.svg');
    else return require('@/assets/img/svg/liability-explained.svg');
  }
  
  get balanceSummaryData() {
    return [
    {
      title: 'Balance Total',
      subtitle: 'En cuentas de banco',
      titleColor: 'blue',
      text: 'Este mes',
      amount: this.patrimony,
      currencyCode: this.currencyCode,
      iconBkgColor: '#D9E7F4',
      footerText: 'Lo que he ganado'
    },
    {
      title: 'Activo',
      subtitle: 'Lo que tengo',
      titleColor: 'red',
      text: 'En base a ' + this.assetsQty + ' elemento(s)',
      amount: this.assets,
      quantity: this.assetsQty,
      currencyCode: this.currencyCode,
      iconBkgColor: '#D9E7F4',
      footerText: 'Lo que he consumido'
    },
    {
      title: 'Pasivo',
      subtitle: 'Lo que debo',
      titleColor: 'red',
      text: 'En base a ' + this.liabilityQty + ' elemento(s)',
      quantity: this.liabilityQty,
      amount: this.liabilities,
      currencyCode: this.currencyCode,
      iconBkgColor: '#FEF3E2',
      footerText: 'Lo que he consumido'
    }
  ]
  }

  get currencyCode() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.code
    }
    return 'DOP'
  }

  get currencyName() {
    if (this.defaultCurrency) {
      return this.defaultCurrency.name
    }
    return ''
  }

  get assetsQty() {
    if (this.userBanks) {
      return this.userBanks.length;
    }
    return 0
  }

  get liabilityQty() {
    if (this.userCreditCards && this.userLoans) {
      return this.userCreditCards.length + this.userLoans.length;
    }
    return 0
  }
}
