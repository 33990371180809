<template>
  <span>
    <span :class="budget ? 'budget-arrow' : ''" class="mr-1">
      <img @click="scrollSummaryCard('left', elementToMove)" :style="getArrowSize()" style="transform: scaleX(-1);" src="@/assets/img/svg/chevron-right.svg" alt="">
    </span>
    <span :class="budget ? 'budget-arrow' : ''">
      <img @click="scrollSummaryCard('right', elementToMove)" :style="getArrowSize()" src="@/assets/img/svg/chevron-right.svg" alt="">
    </span>
  </span>
</template>

<script>
export default {
  props: ['element-to-move', 'arrow-size', 'budget'],
  methods: {
    getArrowSize() {
      return { 'width': this.arrowSize + 'px' }
    },
    scrollSummaryCard(direction, className) {
      var cardSumEl = document.getElementsByClassName(className)[0];

      if (direction == 'left') {
        cardSumEl.scrollBy(-60, 0);
      } else {
        cardSumEl.scrollBy(60, 0);
      }
    }
  }
}
</script>

<style scoped>
.budget-arrow {
  background-color: rgb(206, 230, 255);
  padding: 7px 3px;
  border-radius: 8px;
}
</style>