<template>
  <div class="service-card card-shadow card-border-radius py-3 px-3 mb-3 mx-lg-4">
    <img v-if="isEditing" style="position: absolute;" class="service-edit-bttn edit-button" @click="editCurrentService" src="@/assets/img/svg/edit.svg" alt="">
    <ConfirmTooltip
        v-if="!isEditing"
        :message="'¿Estás seguro que deseas borrar este servicio?'"
        @onConfirmTrue="deleteCurrentService">
        <span aria-hidden="true">
          <img id="delete-bttn" class="service-edit-bttn edit-button service-delete-button" src="@/assets/img/trash.png" alt="">
        </span>
    </ConfirmTooltip>
    <div class="d-flex">
      <div class="mr-2">
        <div class="img-border-radius p-2 py-lg-1 px-lg-2" :style="{'backgroundColor': color}">
          <img class="service-card-img" :src="icon" alt=""/>
        </div>
      </div>
      <div>
        <p class="card-title fs-9 font-gray-caudall"><strong>{{ formattedServiceName  }}</strong></p>
        <p class="card-subtitle fs-8 font-gray-caudall">Categoría</p>
      </div>
    </div>

    <hr class="mt-lg-2 mb-lg-1">

    <div>
      <p class="service-alias fs-12 font-dark-blue-caudall">
        <strong>
          {{ nombre }}
        </strong>
      </p>
      <p class="fs-6 font-gray-caudall">
        Razón del servicio
      </p>
    </div>

    <div class="mt-3 mt-lg-2 d-flex justify-content-between align-items-center">
      <div>
        <p class="amount-text fs-10 font-dark-blue-caudall"><strong>{{ valor }}</strong></p>
        <p class="amount-subtext fs-7 font-dark-blue-caudall"><strong>Monto</strong></p>
      </div>
      <div>
        <div class="bg-light-blue-caudall py-2 px-3 py-lg-1 px-lg-2 img-border-radius align-center">
          <p class="font-dark-blue-caudall fs-7">Día</p>
          <p class="day-text font-dark-blue-caudall fs-11"><strong>{{ diaPago.split('-')[2] }}</strong></p>
        </div>
      </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmTooltip from "@/components/tooltips/ConfirmTooltip.vue";

export default {
  components: {
    ConfirmTooltip
  },
  data() {
    return {
      colors: {
        Luz: '#F49600',
        Agua: '#409BFA',
        Ayuntamiento: '#00C73B',
        Telecomunicaciones: '#409BFA',
        Colegio: '#13477E',
        Otros: '#13477E'
      },
      icons: {
        Luz: 'thunder-2',
        Agua: 'water',
        Ayuntamiento: 'truck',
        Telecomunicaciones: 'phone',
        Colegio: 'school',
        Otros: 'other-service'
      }
    }
  },
  props: ['id', 'nombre', 'valor', 'diaPago', 'recordarDiasAntes', 'currencyCode','serviceName', 'is-editing', 'icon', 'color'],
  methods: {
    editCurrentService() {
      this.$emit('edit-service', this.id)
    },
    deleteCurrentService() {
      this.$emit('delete-service', this.id)
    },
    getBackgroundColor() {
      return this.colors[this.serviceName]
    },
    getIconClass() {
      return this.icons[this.serviceName]
    },
    getImgUrl() {
      let icon = this.icons[this.serviceName]
      return require('@/assets/img/svg/' + icon + '.svg');
    },
    formatNumber(num) {
      return parseInt(num).toLocaleString('en-US', {minimumFractionDigits: 2})
    }
  },
  computed: {
    paymentDay() {
      if (this.diaPago) {
        return this.diaPago.split('-').pop()
      }
      return ''
    },
    formattedServiceName() {
      return this.serviceName.toLowerCase() === 'telecomunicaciones' ? 'Telec.' : this.serviceName;
    }
  }
}
</script>

<style scoped>
.service-alias {
  word-wrap: break-word;    
}
.service-card {
  position: relative;
}
.service-card-img {
  width: 27px;
}
.card-shadow {
  box-shadow: 0px 3px 6px #00000029;
}
.img-border-radius {
  border-radius: 5px;
}
.card-border-radius {
  border-radius: 12px;
}
.card-border-radius >>> p {
  margin: 0
}
.service-container {
  position:relative;
  border-radius: 10px; 
  box-shadow: 0px 3px 6px #00000029;
  max-width: 350px;
  margin: 0 auto;
}



.service-edit-bttn.edit-button.service-delete-button {
  width: 32px !important;
  height: 32px !important;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  padding: 5px;
  top: -10px !important;
  left: calc(94%) !important;

}

.edit-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    font-size: 21.6px;
    border: none;
    color: white;
    background: transparent;
    position: absolute;
    margin: auto;
    top: -20px;
    right: 0;
    left: calc(92%);
}

.service-edit-bttn.edit-button  {
    top: -18px !important;
    left: calc(92%) !important;
    width: 42px !important;
  }

  .image-container {
  width: 40px;
  height: 40px;
}
.card-image {
  background-color: red;
}
.edit-bttn-service {
  left: calc(77%);
}

@media screen and (min-width:1200px) {
  .service-edit-bttn.edit-button  {
    top: -15px !important;
    left: calc(85%) !important;
  }
  #delete-bttn {
      width: 32px !important;
      height: 32px !important;
      background-color: white;
      box-shadow: 0px 3px 6px #00000029;
      padding: 5px;
      top: -10px !important;
      left: calc(90%) !important;
}
  .upper-container {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }
}

@media screen and (min-width:992px) {
  .service-card {
    width: 174px;
    /* height: 150px; */
  }

  .service-card-img {
    width: 17px;
  }
  .card-title {
    font-size: 0.6rem !important;
  }
  .card-subtitle {
    font-size: 0.5rem !important;
  }
  .service-alias {
    font-size: 0.75rem !important;
  }
  .amount-text {
    font-size: 0.7rem !important;
  }
  .amount-subtext {
    font-size: 0.6rem !important;
  }
  .day-text {
    font-size: 0.8rem !important;
  }
  .service-edit-bttn.edit-button {
    left: calc(85%) !important;
  }
  .service-edit-bttn.edit-button.service-delete-button {
    left: calc(87%) !important;
  }
}

@media screen and (min-width:1240px) {
  .service-card {
    width: 191px;
    /* height: 165px; */
  }

  .service-card-img {
    width: 22px;
  }
  .card-title {
    font-size: 0.8rem !important;
  }
  .card-subtitle {
    font-size: 0.7rem !important;
  }
  .service-alias {
    font-size: 0.9rem !important;
  }
  .amount-text {
    font-size: 0.8rem !important;
  }
  .amount-subtext {
    font-size: 0.6rem !important;
  }
  .day-text {
    font-size: 0.8rem !important;
  }
}
</style>