
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ExportButton from "@/components/budget/export/ExportButton.vue";
import { Currency } from '@/models/Common/Common'
import BudgetCateogryFiller from "@/components/budget/BudgetCategoryFiller.vue";
import DistributionChart from "./DistributionChart.vue";
import TransactionTable from "@/components/dashboard/TransactionTable.vue";
import { BudgetCategory } from "@/models/Budget/BudgetCategory";
import CarouselArrows from '@/components/helpers/carousel-arrows.vue'

@Component({
    components:{ ExportButton, BudgetCateogryFiller, DistributionChart, TransactionTable, CarouselArrows }
})
export default class GeneralSummary extends Vue {
    @Getter("Budget/getBudgetExecutedIncome") executedIncome!: number;
    @Getter("Budget/getBudgetExecutedExpense") executedExpense!: number;
    @Getter("Budget/getBudgetTotalIncomePresupuestado") plannedIncome!: number;
    @Getter("Budget/getBudgetTotalExpensePresupuestado") plannedExpense!: number;
    @Getter("Layout/isMobileView") isMobile!: boolean;
    @Getter("Asset/getDefaultCurrency") defaultCurrency!: Currency;
    @Getter("Budget/getBudgetTypeTab") budgetType! : number;
    @Getter("Budget/getCategoriesByBudgetType") budgetCategories!: BudgetCategory[];
    @Getter("Budget/budgetIsLoading") budgetIsLoading!: boolean;
    @Getter("Budget/getCategoriesByBudgetType") filteredCategories!: boolean;
    @Getter("Asset/getDefaultCurrencyCode") defaultCurrencyCode!: string;
    @Getter("Budget/getLastVisitedSubTab") activeBudgetTab! : number;
    @Getter("IdentityManagement/getCanMakeTransactions") canMakeTransactions!: boolean;
    @Getter("IdentityManagement/getCanAddCategories") canAddCategory!: boolean;
    // activeBudgetTab = 0;


    changeBudgetActiveTab(tabNumber: number) {
        // this.activeBudgetTab = tabNumber;
        this.$store.commit('Budget/SET_LAST_VISITED_SUBTAB', tabNumber);
        this.$root.$emit('storeLastVisitedSubTab', tabNumber);
    }

    getImgUrl(icon: string) {
      return require('@/assets/img/svg/' + icon + '.svg');
    }

    changeOrder(catId: string) {
      let copyCatList = this.budgetCategories.slice();
      const selectedCatId = copyCatList.findIndex(c => c.uuid == catId);
      const selectedCatObj = copyCatList.splice(selectedCatId, 1)[0];
      copyCatList.unshift(selectedCatObj);
      this.scrollCategoryContainerToTop();
      this.$store.dispatch("Budget/changeBudgetCategoriesOrder", copyCatList)
    }

    scrollCategoryContainerToTop() {
      const container = document.getElementsByClassName('categories-container container-four-column-table')[1]​
      const innerContainer = container.children[0]
      innerContainer.scrollTop = 0;
    }

    get categoriesCounter() {
      if (this.budgetCategories) return this.budgetCategories.length
      return 0
    }

    openTransactionModal() {
      this.$store.dispatch("Budget/toggleTransactionModelResetState", true);
      ($('#transactionModal') as any).modal();

      const activeTab = this.budgetType == 1 ? 'profile-tab' : 'home-tab';
      const el = document.getElementById(activeTab);
      el?.click();
    }

    openModal() {      
        this.$store.dispatch("Budget/showNewCategoryModal");
    }

    get desktopBudgetSummary() {
      return [
        {
          name: 'Presupuestado',
          amount: `${this.defaultCurrencyCode.toUpperCase()} ${this.activeBudgetTypeTotalPlannedFormatted}`,
          perc: this.activeBudgetTypeTotalPendingPerc,
          subText: 'Lo que planificaste',
          icon: this.budgetType == 0 ? 'minus-blue' : 'minus-green'
        },
        {
          name:  this.budgetType == 0 ? 'Consumido' : 'Ingresado',
          amount: `${this.defaultCurrencyCode.toUpperCase()} ${this.activeBudgetTypeTotalExecutedFormatted}`,
          perc: this.activeBudgetTypeTotalExecutedPerc,
          subText: this.budgetType == 0 ? 'Lo que te has gastado' : 'Lo que te ha ingresado',
          icon: this.budgetType == 0 ? 'minus-red' : 'dashboard-income-green'
        },
        {
          name: this.budgetType == 0 ? 'Disponible' : 'Pendiente',
          amount: `${this.defaultCurrencyCode.toUpperCase()} ${this.activeBudgetTypeTotalRemainingFormatted}`,
          subText: 'Lo que te queda',
          perc: this.activeBudgetTypeTotalPendingPerc,
          icon: 'minus-money'
        }
      ]
    }

    get activeBudgetTypeTotalAvailablePerc() {
      return this.activeBudgetTypeTotalPendingPerc - this.activeBudgetTypeTotalExecutedPerc;
    }

    get transactionTypeText() {
      if (this.budgetType == 0) return 'gasto'
      return 'ingreso'
    }

    get getDefaultCurrency() {
      if (this.defaultCurrency && this.defaultCurrency.code) {
        return this.defaultCurrency.code.toUpperCase();
      }

      return '';
    }

    get transactionTableType() {
      if (this.budgetType == 0) {
        return 'E'
      }
      return 'I'
    }

    get activeBudgetTypeTotalPlanned() {
      if (this.budgetType == 0) {
        return this.plannedExpense
      }
      return this.plannedIncome
    }

    get activeBudgetTypeTotalExecuted() {
      if (this.budgetType == 0) {
        return this.executedExpense
      }
      return this.executedIncome
    }

    get activeBudgetTypeTotalExecutedPerc() {
      const result = this.activeBudgetTypeTotalPlanned ? (this.activeBudgetTypeTotalExecuted / this.activeBudgetTypeTotalPlanned) * 100 : 0;
      const perc = Math.floor(result);
      const limitPerc = perc > 100 ? 100 : perc;
      return isNaN(perc) ? 0 : limitPerc;
    }

    get activeBudgetTypeTotalPendingPerc() {
      const result = (this.activeBudgetTypeTotalExecuted / this.activeBudgetTypeTotalPlanned) * 100;
      const perc = Math.floor(result);

      return isNaN(perc) ? 0 : 100 - this.activeBudgetTypeTotalExecutedPerc;
    }

    get activeBudgetTypeTotalPlannedFormatted() {
      return this.activeBudgetTypeTotalPlanned.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get activeBudgetTypeTotalExecutedFormatted() {
      return this.activeBudgetTypeTotalExecuted.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
    
    get activeBudgetTypeTotalRemainingFormatted() {
      const result = this.activeBudgetTypeTotalPlanned - this.activeBudgetTypeTotalExecuted;
      return result.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get totalIncome() {
        return this.executedIncome.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get totalExpense() {
        return this.executedExpense.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    get incomeIsGreaterThanExpense() {
        return this.executedIncome >= this.executedExpense;
    }

    get remainingText() {
        return this.incomeIsGreaterThanExpense ? "Te sobra" : "Te falta";
    }

    get remaining() {
        var total = 0;

        // if (this.incomeIsGreaterThanExpense)
            total = this.executedIncome - this.executedExpense;
        // else
        //     total = this.executedExpense - this.executedIncome;
        return total.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
}
